import { useStatusFilter } from 'feature/assets/hooks/use-status-filter';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import TagCheckbox from 'core/components/TagCheckbox';
import FilterCard from '../FilterCard';
import * as S from './styled';

const ServiceContractStatusFilter = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets]);
  const { serviceContractStatusList, activeServiceContractStatusFilters, serviceContractStatusTagClickHandler } = useStatusFilter();
  return (
    <FilterCard title={t('ASSETS:labels.servicesByHusqvarna')}>
      <S.TagCheckboxWrapper>
        {serviceContractStatusList.map((status) => (
          <TagCheckbox
            key={status}
            name="service-contract-status"
            id={status}
            value={status}
            checked={activeServiceContractStatusFilters === status}
            onChange={serviceContractStatusTagClickHandler}
          >
            {t(`ASSETS:details.serviceContract.filter.${status}`)}
          </TagCheckbox>
        ))}
      </S.TagCheckboxWrapper>
    </FilterCard>
  );
};

export default ServiceContractStatusFilter;
