import { paddings } from 'core/styles';
import { device, typographyDesktop, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $isSelected?: boolean;
  $link?: boolean;
};

export const AccordionContextSection = styled.section<Props>`
  background-color: ${({ theme }) => theme.colors.surface};
  display: grid;
  grid-template-columns: 98%;
  border-radius: ${({ $isSelected }) => ($isSelected ? '0 0 16px 16px' : '16px')};
  padding: 0 24px ${paddings.largePadding} 24px; 
  width: 100%;
`;

export const AccordionContextSpan = styled.span<Props>`
  font-size: ${typographyMobile.size.medium};
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.onSurface};
  padding-bottom: ${({ $link }) => ($link ? paddings.largePadding : '0')};
  @media ${device.md}{
    font-size: ${typographyDesktop.size.default};
  }
`;
export const FleetAnchor = styled.a`
  color: ${({ theme }) => theme.colors.onSurface};
  cursor: pointer;
  font-size: ${typographyMobile.size.large};
  line-height: 1.4;
  @media ${device.md}{
    font-size: ${typographyDesktop.size.large};
  }
`;
