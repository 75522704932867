import { paddings } from 'core/styles';
import { typographyDesktop } from 'core/styles/variables';
import styled from 'styled-components';

export const NoWidgetTitleDiv = styled.h5`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`;

export const NoWidgetSubTitleDiv = styled.span`
  display: flex;
  justify-content: center;
  font-weight: ${typographyDesktop.weight.regular};
  line-height: normal;
  padding: ${paddings.mediumSmallPadding};
`;
