import { setUserId, setUserProperties } from 'firebase/analytics';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useEffect, useMemo } from 'react';
import { RouterProvider } from 'react-router-dom';
import { getBrowserRouter } from 'core/routes';
import { maintenanceScreenEnabled, configApp, displayCookiePolicy } from 'core/config';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import MaintenanceScreen from 'feature/maintenance/components/MaintenanceScreen';
import PageLoader from 'core/components/PageLoader';
import ThemeWrapper from 'core/styles/ThemeWrapper';
import Clarity from '@microsoft/clarity';
import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';
import CookieBanner from 'core/layout/CookieBanner';
import { useAnalytics } from 'core/hooks/use-analytics';

const App = () => {
  Clarity.init(configApp.clarity);
  const isRemoteConfigReady = useRemoteConfig();
  const isDesktop = useMediaQuery(device.lg);

  // We need the isRemoteConfig to be ready before we can create the router.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const router = useMemo(() => getBrowserRouter(isDesktop), [isRemoteConfigReady, isDesktop]);

  const routes = maintenanceScreenEnabled ? <MaintenanceScreen /> : <RouterProvider router={router} />;
  const user = useAppSelector((state) => state.auth.user);
  const showCookieBanner = useAppSelector((state) => state.layout.showCookieBanner);
  let userType: string | undefined;
  if (user?.type === 'dealer') {
    userType = 'dealer';
  } else if (user?.type === 'Employee') {
    userType = 'employee';
  } else {
    userType = undefined;
  }
  const { analytics } = useAnalytics();

  useEffect(() => {
    if (user && analytics) {
      setUserId(analytics, user.id);
      if (user.salesCompanyCodeAndCustomerNumber) {
        setUserProperties(analytics, {
          dealer_customer: user.salesCompanyCodeAndCustomerNumber,
        });
      }
      if (userType) {
        setUserProperties(analytics, {
          user_type: userType,
        });
        Clarity.setTag('role', userType);
      }
    }
  }, [analytics, user, userType]);
  return (
    <ThemeWrapper>
      {isRemoteConfigReady ? (
        <div>
          {routes}
          {displayCookiePolicy && showCookieBanner && <CookieBanner />}
        </div>
      ) : (
        <PageLoader />
      )}
    </ThemeWrapper>
  );
};

export default App;
