/* eslint-disable import/no-mutable-exports */
import { fetchAndActivate, getValue, RemoteConfig } from 'firebase/remote-config';
import { remoteConfig } from './firebase';
import defaultConfig from './default-remote-config.json'; // downloaded from firebase
import { configFirebase } from '../environment-variables';

type RemoteConfigFlags = keyof typeof defaultConfig;

const getRemoteConfigFlag = (key: RemoteConfigFlags, rc: RemoteConfig): boolean => getValue(rc, key).asBoolean();
const getRemoteConfigNumber = (key: RemoteConfigFlags, rc: RemoteConfig): number => getValue(rc, key).asNumber();
const getRemoteConfigString = (key: RemoteConfigFlags, rc: RemoteConfig): string => getValue(rc, key).asString();

export let maintenanceScreenEnabled = defaultConfig.maintenance_screen_enabled === 'true';
export let displayGoToFleetButton = defaultConfig.display_go_to_fleet_button === 'true';
export let hideFeedbackButton = defaultConfig.hide_feedback_button === 'true';
export let accessModalEnabled = defaultConfig.access_modal_enabled === 'true';
export let displayWarranty = defaultConfig.display_warranty === 'true';
export let accessRefinedStatus = defaultConfig.access_refined_status;
export let statusWidgetPollingInterval: number;
export let getMarkersPollingInterval: number;
export let numberOfCustomerWidgetsAllowed: number;
export let noAccessModalTimer: number;
export let dashboardWidgetsPollingInterval: number;
export let feedbackUrl: string;
export let serviceContractsEnabled = defaultConfig.service_contracts_enabled === 'true';
export let displayCookiePolicy = defaultConfig.display_cookie_policy === 'true';

export const initRemoteConfig = async () => {
  const rc = await remoteConfig();

  if (rc) {
    if (configFirebase.remoteConfigFetchInterval) {
      rc.settings.minimumFetchIntervalMillis = Number(configFirebase.remoteConfigFetchInterval);
    }
    rc.defaultConfig = defaultConfig;
    await fetchAndActivate(rc);
    maintenanceScreenEnabled = getRemoteConfigFlag('maintenance_screen_enabled', rc);
    statusWidgetPollingInterval = getRemoteConfigNumber('status_widget_polling_interval', rc);
    getMarkersPollingInterval = getRemoteConfigNumber('get_markers_polling_interval', rc);
    displayGoToFleetButton = getRemoteConfigFlag('display_go_to_fleet_button', rc);
    numberOfCustomerWidgetsAllowed = getRemoteConfigNumber('no_of_customer_widgets_allowed', rc);
    hideFeedbackButton = getRemoteConfigFlag('hide_feedback_button', rc);
    noAccessModalTimer = getRemoteConfigNumber('no_access_modal_timer', rc);
    accessModalEnabled = getRemoteConfigFlag('access_modal_enabled', rc);
    accessRefinedStatus = getRemoteConfigString('access_refined_status', rc);
    displayWarranty = getRemoteConfigFlag('display_warranty', rc);
    feedbackUrl = getRemoteConfigString('feedback_url', rc);
    dashboardWidgetsPollingInterval = getRemoteConfigNumber('dashboard_widgets_polling_interval', rc);
    serviceContractsEnabled = getRemoteConfigFlag('service_contracts_enabled', rc);
    displayCookiePolicy = getRemoteConfigFlag('display_cookie_policy', rc);
  }
};
