import { useAppSelector } from 'core/hooks/use-app-selector';
import { Navigate, Outlet, ScrollRestoration } from 'react-router-dom';
import { RoutePaths } from 'core/models';
import { device } from 'core/styles/variables';
import useMediaQuery from 'core/hooks/use-media-query';
import AccessModal from 'core/components/AccessModal';
import Feedback from 'feature/feedback/components/Feedback/Feedback';
import Header from '../Header';
import useRestoreScroll from './hooks/use-restore-scroll';
import MobileNavMenu from '../MobileNavMenu';

const RootLayout = () => {
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const isLoggingOut = useAppSelector((state) => state.auth.isLoggingOut);
  // Determine if scroll should be restored based on base path changes
  const restoreScroll = useRestoreScroll();
  const isDesktop = useMediaQuery(device.lg);

  if (!isAuthenticated && !isLoggingOut) {
    return <Navigate to={RoutePaths.UserLogin} replace />;
  }

  return (
    <>
      <Header />
      <AccessModal />
      <Feedback />
      <Outlet />
      {
        <ScrollRestoration
          getKey={(location) =>
            // Scroll to top if changing to a different base path
            (restoreScroll ? location.key : 'manual')}
          storageKey="scroll-position"
        />
      }
      {!isDesktop && <MobileNavMenu />}
    </>
  );
};

export default RootLayout;
