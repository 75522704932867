import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';

const CookiesSection = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.CookiePolicy);

  const cookiesKeys = ['persistentCookie', 'sessionCookie'];
  return (
    <div className="cookies-policy">
      <p>{t('COOKIE_POLICY:subCookies.textPart1')}</p>
      <p>{t('COOKIE_POLICY:subCookies.textPart2')}</p>
      {cookiesKeys.map((key) => (
        <p key={key}>
          <strong>
            {t(`COOKIE_POLICY:subCookies.${key}.title`)}
          </strong>
          {t(`COOKIE_POLICY:subCookies.${key}.text`)}
        </p>
      ))}
    </div>
  );
};

export default CookiesSection;
