import { FunctionalColor, getFunctionalColor, getFunctionalLightColor, paddings, typographyDesktop } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

type Props = {
  $color?: FunctionalColor;
};

export const AssetListStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${paddings.smallPadding};
`;

export const StatusCircle = styled.div<Props>`
  border-radius: 50%;
  height: 10px;
  width: 10px;
  background-color: red;
  background-color: cover;
  border: 1px solid;
  border-color: ${({ theme, $color }) => getFunctionalColor(theme, $color)};
  background-color: ${({ theme, $color }) => getFunctionalLightColor(theme, $color)};
`;

export const StatusTextSpan = styled.span`
  font-size: ${typographyDesktop.size.tiny};
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray200 : theme.colors.gray600)};
`;
