import { useCallback } from 'react';

type UseAddressFromGoogleApiProps = {
  setAssetDetailsAddressline: (address: string) => void;
  assetAddressline: string;
  placeId?: string;
};

const useAddressFromGoogleApi = ({
  placeId,
  setAssetDetailsAddressline,
  assetAddressline,
}: UseAddressFromGoogleApiProps) => {
  const getPlaceDetails = useCallback(async () => {
    if (!placeId) {
      setAssetDetailsAddressline(assetAddressline);
      return;
    }
    try {
      const { Place } = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
      const place = new Place({
        id: placeId,
        requestedLanguage: navigator.language || 'en',
      });
      await place.fetchFields({ fields: ['formattedAddress'] });
      setAssetDetailsAddressline(place.formattedAddress as string);
    } catch (error) {
      console.error('Error fetching place details:', error);
      setAssetDetailsAddressline(assetAddressline);
    }
  }, [assetAddressline, placeId, setAssetDetailsAddressline]);

  return { getPlaceDetails };
};

export default useAddressFromGoogleApi;
