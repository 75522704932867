import { AssetListItem } from '@uptime-bff/api-contract';
import { useMemo } from 'react';
import { shouldDisplayGreenDot, shouldDisplayStatusIcon } from '../utils';

type Props = {
  asset: AssetListItem;
};

const useDisplayOperationalIcons = ({ asset }:Props) => {
  const displayStatusIcon = useMemo(() => shouldDisplayStatusIcon(asset.status?.operationalStatus), [asset.status?.operationalStatus]);
  const displayGreenDot = useMemo(() => shouldDisplayGreenDot(asset.status?.operationalStatus), [asset.status?.operationalStatus]);

  return { displayStatusIcon, displayGreenDot };
};

export { useDisplayOperationalIcons };
