import { Card, Loader } from 'hudskit-framework-react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { Card as WidgetsCard } from '@uptime-bff/api-contract';
import ErrorCard from 'core/components/ErrorCard';
import { useCustomerWidgetCardData } from 'feature/dashboard/hooks/use-customer-widget-card-data';
import * as S from './styled';
import CustomerWidgetAssetList from '../CustomerWidgetAssetList';
import CustomerWidgetCardHeader from '../CustomerWidgetCardHeader';
import CustomerWidgetCardAssetsCount from '../CustomerWidgetCardAssetsCount';

export interface Props {
  doneEditing: boolean;
  cardData: WidgetsCard;
  isWidgetsListUpdating: boolean;
  isWidgetListError: boolean;
  widgetDeletionSuccess: boolean;
}

const CustomerWidgetCard = ({
  doneEditing,
  cardData,
  isWidgetsListUpdating,
  isWidgetListError,
  widgetDeletionSuccess,
}: Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Dashboard]);
  const {
    data,
    isSuccessFetchingAssetList,
    isErrorFetchingAssetList,
    isLoadingFetchingAssetList,
    getCustomerWidgetAssetList,
    goToAssets,
    goToAssetsWithoutErrors,
    goToAssetsFromAssetsList,
    goToAssetsWithWarningFromAssetsList,
    onAssetItemClick,
    selectedAsset,
    isDesktop,
  } = useCustomerWidgetCardData({
    cardData,
    doneEditing,
    isWidgetListError,
    widgetDeletionSuccess,
  });

  return (
    <Card>
      <S.WidgetContentDiv $noAssets={data && data?.elements?.length === 0}>
        <S.HeaderGridDiv>
          <CustomerWidgetCardHeader
            hideEllipsis={!!((data && data?.elements?.length === 0) || isErrorFetchingAssetList)}
            cardData={cardData}
            isWidgetsListUpdating={isWidgetsListUpdating}
            goToAssetsWithoutErrors={goToAssetsWithoutErrors}
            doneEditing={doneEditing}
          />
          {isSuccessFetchingAssetList && data && !isErrorFetchingAssetList && !isWidgetListError && (
            <CustomerWidgetCardAssetsCount
              data={data}
              isWidgetsListUpdating={isWidgetsListUpdating}
              goToAssetsWithoutErrors={goToAssetsWithoutErrors}
              goToAssetsFromAssetsList={goToAssetsFromAssetsList}
              goToAssetsWithWarningFromAssetsList={goToAssetsWithWarningFromAssetsList}
            />
          )}
        </S.HeaderGridDiv>
        {isSuccessFetchingAssetList && !data?.isCompanyActive && (
          <S.CompanyDeletedDiv>{t('DASHBOARD:dashboard.customerWidgets.errors.companyDeleted')}</S.CompanyDeletedDiv>
        )}
        {isLoadingFetchingAssetList && (
          <S.LoaderDiv>
            <Loader center size={isDesktop ? 'medium' : 'small'} />
          </S.LoaderDiv>
        )}
        {isErrorFetchingAssetList && (
          <S.ErrorTryAgainWrapperDiv>
            <ErrorCard onRetryClick={getCustomerWidgetAssetList}>
              <>{t('ASSETS:list.errorLoadingAssets')}</>
            </ErrorCard>
          </S.ErrorTryAgainWrapperDiv>
        )}
        {data?.isCompanyActive && data?.elements && (
          <>
            <S.CustomerWidgetAssetsListWrapper $noAssets={data.elements.length === 0}>
              <CustomerWidgetAssetList
                onAssetItemClickHandler={onAssetItemClick}
                assets={data.elements}
                selectedAssetId={selectedAsset?.id}
              />
            </S.CustomerWidgetAssetsListWrapper>

            {data?.elements.length > 0 && (
              <div>
                <S.AssetListDividerDiv />
                <S.ViewMoreWrapperDiv $isFetching={isWidgetsListUpdating}>
                  <S.ViewMoreTextSpan
                    title={t('DASHBOARD:dashboard.customerWidgets.viewMoreDescription')}
                    $isFetching={isWidgetsListUpdating}
                    onClick={() => goToAssets()}
                  >
                    {t('DASHBOARD:dashboard.customerWidgets.viewMore')}
                    {` (${(data?.critical ?? 0) + (data?.warning ?? 0)})`}
                  </S.ViewMoreTextSpan>
                </S.ViewMoreWrapperDiv>
              </div>
            )}
          </>
        )}
      </S.WidgetContentDiv>
    </Card>
  );
};

export default CustomerWidgetCard;
