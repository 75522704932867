import { AssetDetailsWarranty as Warranty } from '@uptime-bff/api-contract';
import { formatDate } from 'feature/assets/utils';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import AssetInfoCell from '../AssetInfoCell';
import * as S from './styled';

type Props = {
  warranty: Warranty;
};

const AssetDetailsWarranty = ({ warranty }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const { warrantyPurchaseDate, warrantyExpirationDate } = warranty;
  const purchaseDate = formatDate(warrantyPurchaseDate);
  const expirationDate = formatDate(warrantyExpirationDate);

  return (
    <S.WarrantyDiv>
      {purchaseDate && expirationDate && (
        <>
          <S.WarrantySpan>{t('ASSETS:details.warranty.label')}</S.WarrantySpan>
          <S.WarrantyContentDiv>
            <AssetInfoCell
              label={t('ASSETS:details.warranty.startDate')}
              value={purchaseDate}
            />
            <AssetInfoCell
              label={t('ASSETS:details.warranty.expiredDate')}
              value={expirationDate}
            />
          </S.WarrantyContentDiv>
        </>
      )}
    </S.WarrantyDiv>
  );
};

export default AssetDetailsWarranty;
