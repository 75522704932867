import { useEffect, useState } from 'react';
import * as S from './styled';
import { Size, StatusSeverityColor } from './styled';

export type Props = {
  size?: Size;
  color?: StatusSeverityColor;
  imageSrc: string;
  imageAlt: string;
  marker?: boolean;
};

const AssetThumbnail = ({
  imageSrc,
  imageAlt,
  size = 'sm',
  color = undefined,
  marker = undefined
}: Props) => {
  const [imgState, setImageState] = useState<string>(imageSrc);
  useEffect(() => {
    setImageState(imageSrc);
  }, [imageSrc]);
  return (
    <S.ImageContainer size={size} color={color}>
      {color && (
        <S.CircleBackground
          data-testid="assetThumbnailBackgroundDiv"
          className={marker && size === 'sm' ? 'marker' : undefined}
        />
      )}
      <S.AssetImage
        id="assetImage"
        src={imgState}
        alt={imageAlt}
        onError={() => {
          setImageState(
            'https://common-assets-054390323997-dev.s3-eu-west-1.amazonaws.com/images/120x100/icon_machine_robotic_lawn_mower_120x100.png'
          );
        }}
      />
    </S.ImageContainer>
  );
};

export default AssetThumbnail;
