import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import Modal from 'core/components/Modal/Modal';
import { Button, Loader } from 'hudskit-framework-react';
import { useCallback } from 'react';
import * as S from './styled';

interface Props {
  isOpen: boolean;
  close: () => void;
  deleteCompany?: () => void;
  isDeleteInProgress: boolean;
}

const ConfirmationModal = ({ isOpen, close, deleteCompany, isDeleteInProgress }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Dashboard);
  const onRemoveClickHandler = useCallback(() => {
    if (deleteCompany) {
      deleteCompany();
    }
  }, [deleteCompany]);

  return (
    <Modal title={t('DASHBOARD:dashboard.confirmationModal.modalTitle')} isOpen={isOpen} close={close}>
      <S.ContainerDiv>
        {isDeleteInProgress && (
          <S.LoaderDiv>
            <Loader size="medium" center />
          </S.LoaderDiv>
        )}
        {!isDeleteInProgress && (
          <div>
            <span>{t('DASHBOARD:dashboard.confirmationModal.modalContent')}</span>
          </div>
        )}
      </S.ContainerDiv>
      <S.FooterContentDiv>
        {!isDeleteInProgress && (
          <>
            <Button variant="secondary" onClick={close}>
              <>{t('DASHBOARD:dashboard.confirmationModal.cancelButtonText')}</>
            </Button>
            <Button
              variant="primary"
              onClick={onRemoveClickHandler}
            >
              <>{t('DASHBOARD:dashboard.confirmationModal.removeButtonText')}</>
            </Button>
          </>
        )}
      </S.FooterContentDiv>
    </Modal>
  );
};

export default ConfirmationModal;
