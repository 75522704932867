import { NavMenu as HudskitNavMenu } from 'hudskit-framework-react';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import { useRemoteConfig } from 'core/hooks/use-remote-config';
import { useMemo } from 'react';
import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';
import { getUptimeMenu } from './menu';

const NavMenu = () => {
  const isRemoteConfigReady = useRemoteConfig();
  const isDesktop = useMediaQuery(device.lg);
  const navMenu = useMemo(() => getUptimeMenu(isDesktop), [isDesktop]);

  const { activeMenuId, onClickHandler } = useUptimeNavigation(navMenu.menu);
  return isRemoteConfigReady && <HudskitNavMenu menu={navMenu.menu} onClick={onClickHandler} activeMenuItemId={activeMenuId} />;
};
export default NavMenu;
