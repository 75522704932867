import { useCallback, useEffect, useRef, useState } from 'react';
import type { AssetListItem as Asset } from '@uptime-bff/api-contract';

const useAssetListHideScrollObserver = (assets: Asset[]) => {
  const listItemsDivRef = useRef<HTMLDivElement | null>(null);
  const [hideScroll, setHideScroll] = useState(false);

  const checkScroll = useCallback(() => {
    if (listItemsDivRef.current) {
      const listHeight = listItemsDivRef.current.clientHeight;
      const viewportHeight = window.innerHeight;
      // 250 is for mobile view cases
      setHideScroll(listHeight + 250 < viewportHeight);
    }
  }, []);

  useEffect(() => {
    checkScroll();
    window.addEventListener('resize', checkScroll);
    window.addEventListener('scroll', checkScroll);
    return () => {
      window.removeEventListener('resize', checkScroll);
      window.removeEventListener('scroll', checkScroll);
    };
  }, [assets, checkScroll]);

  return { listItemsDivRef, hideScroll };
};

export default useAssetListHideScrollObserver;
