import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useEffect, useState } from 'react';
import { Loader } from 'hudskit-framework-react';
import AssetThumbnail from '../AssetThumbnail';
import * as S from './styled';

type Props = {
  asset: AssetDetailsResponse;
};

const AssetThumbnailInfo = ({ asset }: Props) => {
  const isLargeScreen = useMediaQuery(device.xl);
  const isTab = useMediaQuery(device.md);
  const isMobileOrTab = isTab ? 'md-lg' : 'md';
  const thumbnailSize = isLargeScreen ? 'lg' : isMobileOrTab;
  const isOpen = useAppSelector((state) => state.layout.isOpen);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);
  return (
    <S.AssetThumbnailInfoContainerSection $isOpen={isOpen}>
      {!loading && <AssetThumbnail imageAlt={asset.model} imageSrc={asset.productImageUrl} size={thumbnailSize} />}
      {loading && (
        <S.LoaderWrapperDiv>
          <Loader data-testid="loader" />
        </S.LoaderWrapperDiv>
      )}
      <S.ThumbnaildDetailsDiv>
        <S.AssetNameSpan $isOpen={isOpen} title={asset.assetName.length > 25 ? asset.assetName : undefined}>
          {asset.assetName}
        </S.AssetNameSpan>
        <S.AssetModelSpan>{asset.model}</S.AssetModelSpan>
        <S.AssetCompanyNameSpan>{asset.companyName}</S.AssetCompanyNameSpan>
      </S.ThumbnaildDetailsDiv>
    </S.AssetThumbnailInfoContainerSection>
  );
};

export default AssetThumbnailInfo;
