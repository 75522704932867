import { AggregatedStatusEnum, OperationalStatusEnum, SecondaryStatusRoboticMowerEnum } from '@uptime-bff/api-contract';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useMemo } from 'react';
import {
  setFilterByOperationalStatusOnActiveSearch,
  setFilterByServiceContractStatusOnActiveSearch,
  setFilterByStatusOnActiveSearch,
  setSelectedAsset
} from '../store';

const useStatusFilter = () => {
  const dispatch = useAppDispatch();

  const secondaryStatusList = useMemo(() =>
    Object.values(SecondaryStatusRoboticMowerEnum).filter((status) => status), []);
  const operationalStatusList = useMemo(() =>
    Object.values(OperationalStatusEnum).filter((status) => status !== 'OK_WITH_INFO'), []);
  const serviceContractStatusList = useMemo(() =>
    Object.values(AggregatedStatusEnum).filter((status) => status !== AggregatedStatusEnum.NO_ACTIVE_CONTRACTS), []);

  const activeSecondaryStatusFilters = useAppSelector((state) => state.assets.activeListFilters?.secondaryStatus);
  const activeOperationalStatusFilters = useAppSelector((state) => state.assets.activeListFilters?.operationalStatus);
  const activeServiceContractStatusFilters = useAppSelector((state) => state.assets.activeListFilters?.serviceContractStatus);

  const updateFilter = (filter: string[], status: string, checked: boolean) =>
    (checked ? [...filter, status] : filter.filter((s) => s !== status));

  const secondaryStatusTagClickHandler = (status: string, checked: boolean) => {
    dispatch(setSelectedAsset(undefined));
    dispatch(setFilterByStatusOnActiveSearch(updateFilter(activeSecondaryStatusFilters ?? [], status, checked)));
  };

  const operationalStatusTagClickHandler = (status: string, checked: boolean) => {
    dispatch(setFilterByOperationalStatusOnActiveSearch(updateFilter(activeOperationalStatusFilters ?? [], status, checked)));
  };
  const serviceContractStatusTagClickHandler = (status: string, checked: boolean) => {
    dispatch(setSelectedAsset(undefined));
    dispatch(setFilterByServiceContractStatusOnActiveSearch(
      (checked && status === activeServiceContractStatusFilters) || !checked ? undefined : status as AggregatedStatusEnum
    ));
  };

  return {
    secondaryStatusList,
    operationalStatusList,
    serviceContractStatusList,
    activeSecondaryStatusFilters,
    activeOperationalStatusFilters,
    activeServiceContractStatusFilters,
    secondaryStatusTagClickHandler,
    operationalStatusTagClickHandler,
    serviceContractStatusTagClickHandler
  };
};

export { useStatusFilter };
