import { device, paddings } from 'core/styles/variables';
import styled from 'styled-components';

export { ErrorSpan } from 'feature/auth/pages/Callback/styled';

type Props = {
  $isFetching: boolean;
};

export const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${paddings.mediumPadding} 0 0 0;
  max-width: 100px;
  text-overflow: ellipsis;
`;
export const HeaderGridDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${paddings.mediumSmallPadding} 0 0px;
  @media (max-width: ${device.xs} {
    gap: ${paddings.mediumSmallPadding};
  }
`;

export const NameWithEllipsisDiv = styled.div<{ $hideEllipsis: boolean }>`
  display: flex;
  justify-content: flex-start;
  width: ${({ $hideEllipsis }) => ($hideEllipsis ? 'fit-content' : '50%')};
  gap: ${paddings.mediumSmallPadding};
`;

export const HeadingDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  cursor: ${({ $isFetching }) => !$isFetching && 'pointer'};
  white-space: nowrap;
  overflow: hidden;
  gap: ${paddings.mediumSmallPadding};
`;

export const HeadingText = styled.h6<Props>`
  margin: 0 ${paddings.smallPadding};
  cursor: ${({ $isFetching }) => !$isFetching && 'pointer'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
