import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import i18next from 'i18next';
import * as S from './styled';

interface CookieData {
  cookie: string;
  domain: string;
  expires: string;
  category: string;
}

const StrictlyNeededCookiesTable = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.CookiePolicy);

  const cookieHeaders = [
    'cookie',
    'cookieDomain',
    'expires',
    'category',
    'purpose',
  ];

  const cookieData: CookieData[] = [
    {
      cookie: 'uptime-accessToken',
      domain: '.husqvarna.com',
      expires: 'COOKIE_POLICY:tableOne.firstTableWithData.instantly',
      category: 'COOKIE_POLICY:tableOne.firstTableWithData.strictlyNecessary',
    },
    {
      cookie: 'uptime-accessTokenExp',
      domain: '.husqvarna.com',
      expires: 'COOKIE_POLICY:tableOne.firstTableWithData.instantly',
      category: 'COOKIE_POLICY:tableOne.firstTableWithData.strictlyNecessary',
    },
    {
      cookie: 'uptime-idToken',
      domain: '.husqvarna.com',
      expires: 'COOKIE_POLICY:tableOne.firstTableWithData.days',
      category: 'COOKIE_POLICY:tableOne.firstTableWithData.strictlyNecessary',
    },
    {
      cookie: 'uptime-refreshToken',
      domain: '.husqvarna.com',
      expires: 'COOKIE_POLICY:tableOne.firstTableWithData.days',
      category: 'COOKIE_POLICY:tableOne.firstTableWithData.strictlyNecessary',
    },
  ];

  return (
    <S.CookiePolicyTable data-testid="tableOne">
      <S.CookiePolicyTableHead>
        <S.CookiePolicyTableHeadRow>
          {cookieHeaders.map((header) => (
            <S.CookiePolicyTableHeadCell key={header}>
              {t(`COOKIE_POLICY:tableOne.tableOneHeaders.${header}`)}
            </S.CookiePolicyTableHeadCell>
          ))}
        </S.CookiePolicyTableHeadRow>
      </S.CookiePolicyTableHead>
      <tbody>
        {cookieData.map((row, index) => (
          <tr key={row.cookie}>
            <S.CookiePolicyTableData>{row.cookie}</S.CookiePolicyTableData>
            <S.CookiePolicyTableData>{row.domain}</S.CookiePolicyTableData>
            <S.CookiePolicyTableData>
              {i18next.t(row.expires, { days: 7 })}
            </S.CookiePolicyTableData>
            <S.CookiePolicyTableData>{t(row.category)}</S.CookiePolicyTableData>
            {index === 0 && (
              <S.CookiePolicyTableData rowSpan={4}>
                {t('COOKIE_POLICY:tableOne.firstTableWithData.accessTokenPurpose')}
              </S.CookiePolicyTableData>
            )}
          </tr>
        ))}
      </tbody>
    </S.CookiePolicyTable>
  );
};

export default StrictlyNeededCookiesTable;
