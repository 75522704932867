/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const defaultState: DashboardState = {
  isOpen: true,
};

interface DashboardState {
  isOpen?: boolean;
  widgetList?: string[];
}

const slice = createSlice({
  name: 'dashboard',
  initialState: defaultState,
  reducers: {
    setIsOpenMessageModal: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setCustomerWidgetsToBeDeleted: (state, action: PayloadAction<string[]>) => {
      state.widgetList = action.payload;
    },
  },
});

export const { setIsOpenMessageModal, setCustomerWidgetsToBeDeleted } = slice.actions;
export default slice.reducer;
