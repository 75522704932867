import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { CompaniesDetailsResponse } from '@uptime-bff/api-contract';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearFilters, setFilterByCompaniesOnActiveSearch } from 'feature/assets/store';
import { RoutePaths } from 'core/models';
import { useCallback } from 'react';
import CompanyInfoCell from '../CompanyInfoCell';
import * as S from './styled';

type Props = {
  iamId: string;
  company: CompaniesDetailsResponse;
};

const CompaniesInfoAssetsCell = ({ company, iamId }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Companies);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterByCompanyAssets = useCallback(() => {
    dispatch(clearFilters());
    dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [iamId] }));
    navigate(`/${RoutePaths.Assets}`);
  }, [dispatch, iamId, navigate]);

  return (
    <S.CompanyInfoAssetsCell>
      <CompanyInfoCell
        id="totalAssetsCount"
        label={t('COMPANIES:companiesDetails.totalAssets')}
        value={company.totalAssets}
      />
      <CompanyInfoCell id="machinesCount" label={t('COMPANIES:companiesDetails.machines')} value={company.machines} />
      <S.RoboticMowersDiv onClick={filterByCompanyAssets}>
        <CompanyInfoCell
          id="mowersCount"
          label={t('COMPANIES:companiesDetails.roboticMowers')}
          value={company.mowers}
          clickable
        />
      </S.RoboticMowersDiv>
    </S.CompanyInfoAssetsCell>
  );
};

export default CompaniesInfoAssetsCell;
