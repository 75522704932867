import * as S from './styled';

type Props = {
  t: (key: string) => string,
  content: string | string[],
  itemId?: string,
};
const AssetInfoItemContent = ({ t, content, itemId }: Props) => (content instanceof Array ? (
  content.map((item) => {
    const isBold = item.startsWith(t('ASSETS:details.infoItems.cause')) || item.startsWith(t('ASSETS:details.infoItems.action'));
    return (
      <S.ContentDiv key={`${itemId}-${item}`}>
        <strong>{isBold && `${item.split(': ')[0]}: `}</strong>
        {item.split(': ')[1]}
      </S.ContentDiv>
    );
  })
) : (
  <S.ContentDiv>
    {content}
  </S.ContentDiv>
));

export default AssetInfoItemContent;
