import { zLayoutNavigation } from 'core/styles/z-index';
import styled from 'styled-components';

export const MobileBottomDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: ${zLayoutNavigation};
`;
