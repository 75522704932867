import CeoraStatusWidget from 'feature/dashboard/components/CeoraStatusWidget';
import CustomerWidgets from 'feature/dashboard/components/CustomerWidgets/CustomerWidgets';
import StatusWidget from 'feature/dashboard/components/StatusWidget/StatusWidget';
import * as S from './styled';

const Dashboard = () => (
  <S.DashboardContentDiv>
    <S.GridDiv>
      <S.StatusWidgetContainerDiv data-testid="status-widget">
        <StatusWidget />
      </S.StatusWidgetContainerDiv>
      <S.CeoraStatusWidgetContainerDiv data-testid="ceora-status-widget">
        <CeoraStatusWidget />
      </S.CeoraStatusWidgetContainerDiv>
    </S.GridDiv>
    <S.CustomerWidgetsContainerDiv data-testid="customer-widgets-container">
      <CustomerWidgets />
    </S.CustomerWidgetsContainerDiv>
  </S.DashboardContentDiv>
);

export default Dashboard;
