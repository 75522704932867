import {
  AssetDetailsContactInfo,
  AssetDetailsProductInfo,
  AssetDetailsResponse,
  AssetFiltersRequest,
  AssetFiltersResponse,
  AssetInfoItemsResponse,
  AssetListRequest,
  AssetListResponse,
  AssetMapResponse,
  AssetServiceContractResponse,
} from '@uptime-bff/api-contract';
import { uptimeApi } from 'core/api';

const assetsApi = uptimeApi.injectEndpoints({
  endpoints: (build) => ({
    searchAssets: build.query<AssetListResponse, AssetListRequest>({
      query: (body) => ({
        url: 'assets/list',
        method: 'POST',
        body,
      }),
      keepUnusedDataFor: 0
    }),
    getAssetsInfoItems: build.query<AssetInfoItemsResponse, string>({
      query: (assetId) => ({
        url: `assets/list/${assetId}/info-items`,
        method: 'GET',
      }),
    }),
    getSearchAvailableFilters: build.query<AssetFiltersResponse, AssetFiltersRequest>({
      query: (body) => ({
        url: 'assets/list/filters',
        method: 'POST',
        body,
      }),
    }),
    getAssetById: build.query<AssetDetailsResponse, string>({
      query: (assetId) => ({
        url: `assets/list/${assetId}`,
        method: 'GET',
      }),
    }),
    getAssetProductInfoById: build.query<AssetDetailsProductInfo, string>({
      query: (assetId) => ({
        url: `assets/list/${assetId}/product-info`,
        method: 'GET',
      }),
    }),
    getAssetContactById: build.query<AssetDetailsContactInfo, string>({
      query: (assetId) => ({
        url: `assets/list/${assetId}/contact-info`,
        method: 'GET',
      }),
    }),

    getAssetServiceContracts: build.query<AssetServiceContractResponse, string>({
      query: (assetId) => ({
        url: `assets/list/${assetId}/service-contracts`,
        method: 'GET',
      }),
    }),

    getAssetMarkers: build.query<AssetMapResponse, void>({
      query: () => ({
        url: 'assets/map',
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useLazySearchAssetsQuery,
  useGetAssetByIdQuery,
  useGetAssetsInfoItemsQuery,
  useGetAssetProductInfoByIdQuery,
  useGetAssetContactByIdQuery,
  useGetAssetServiceContractsQuery,
  useGetSearchAvailableFiltersQuery,
  useGetAssetMarkersQuery,
} = assetsApi;
