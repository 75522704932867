import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import TagCheckbox from 'core/components/TagCheckbox';
import { useState } from 'react';
import FilterCard from '../FilterCard';
import * as S from './styled';

type Props = {
  label: string;
  tagTitle: string;
  statusList: string[];
  activeStatus?: string[];
  statusTagClick: (status: string, checked: boolean) => void;
};

const StatusFilter = ({ label, tagTitle, statusList, activeStatus = [], statusTagClick }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const displayStatus = statusList?.length > 4;

  return (
    <FilterCard title={t(`ASSETS:filters.labels.${label}`)} status={displayStatus} isOpen={isOpen} setIsOpen={setIsOpen}>
      <S.StatusTagsDiv $isOpen={isOpen}>
        {statusList.map((statusItem) => (
          <TagCheckbox
            key={statusItem}
            checked={activeStatus?.includes(statusItem) ?? false}
            value={statusItem}
            onChange={statusTagClick}
            name="mower-status"
            id={statusItem}
          >
            {
              t(`statusTranslation:status.${tagTitle}.${statusItem}`)
            }
          </TagCheckbox>
        ))}
      </S.StatusTagsDiv>
    </FilterCard>
  );
};

export default StatusFilter;
