import { paddings } from 'core/styles';
import styled from 'styled-components';

export const StatusTagsDiv = styled.div<{ $isOpen: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: ${() => paddings.smallPadding} 0;
  height: ${({ $isOpen }) => ($isOpen ? '100%' : '58px')};
  overflow: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
`;
