import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import { zLayoutNavigation } from 'core/styles/z-index';
import styled from 'styled-components';

export const CookieBannerContainer = styled.div`
  z-index: ${zLayoutNavigation} ;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.surface};
  padding: ${paddings.mediumPadding} ${paddings.largePadding};
`;

export const CookieBannerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: ${paddings.mediumPadding};

  @media ${device.md} {
    flex-direction: row;
    align-items: center;
    gap: ${paddings.largePadding};
  }
`;

export const CookieBannerTextSpan = styled.span`
  @media ${device.md} {
    width: 250px;
  }

  @media ${device.lg} {
    width: auto;
  }
`;

export const CookieAnchor = styled.a`
  &:link {
    color: ${({ theme }) => theme.colors.brand};
  }

  &:visited { 
    color: ${({ theme }) => theme.colors.brand};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.brandDark};
  }

  &:active {
    color: ${({ theme }) => theme.colors.brandDark};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  row-gap: ${paddings.mediumPadding};

  @media ${device.md} {
    flex-direction: row;
    justify-content: start;
  }
`;
