import { Button } from 'hudskit-framework-react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import useUptimeCookies from 'core/hooks/use-uptime-cookies';
import { RoutePaths } from 'core/models';
import * as S from './styled';

const CookieBanner = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Core]);
  const { onAcceptAllHandler, onRejectAllHandler } = useUptimeCookies();
  return (
    <S.CookieBannerContainer>
      <S.CookieBannerDiv>
        <S.CookieBannerTextSpan>{t('CORE:cookieBanner.message')}</S.CookieBannerTextSpan>
        <S.CookieAnchor href={RoutePaths.CookiePolicy} target="_blank" rel="noreferrer">{t('CORE:cookieBanner.link')}</S.CookieAnchor>
        <S.ButtonsContainer>
          <Button size="medium" onClick={onAcceptAllHandler}>
            {t('CORE:buttons.acceptAllCookies')}
          </Button>
          <Button size="medium" onClick={onRejectAllHandler} variant="surface">
            {t('CORE:buttons.rejectAllCookies')}
          </Button>
        </S.ButtonsContainer>
      </S.CookieBannerDiv>
    </S.CookieBannerContainer>
  );
};

export default CookieBanner;
