import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import * as S from './styled';

const NoCustomerWidgets = () => {
  const { t } = useTranslation([InternationalizationNameSpace.Dashboard]);
  const memoizedContent = useMemo(() => (
    <>
      <S.NoWidgetTitleDiv data-testid="NoWidgetsTitle">
        {t('DASHBOARD:dashboard.customerWidgets.noCustomerWidgetsTitle')}
      </S.NoWidgetTitleDiv>
      <S.NoWidgetSubTitleDiv>
        {t('DASHBOARD:dashboard.customerWidgets.noCustomerWidgetsSubTitle')}
      </S.NoWidgetSubTitleDiv>
    </>
  ), [t]);

  return memoizedContent;
};

export default NoCustomerWidgets;
