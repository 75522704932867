import { darkTheme, Icon } from 'hudskit-framework-react';
import { isValidElement, ReactNode } from 'react';
import { Severity } from 'feature/assets/utils';
import { useTheme } from 'styled-components';
import * as S from './styled';
import { StyledOutlinedWarningIcon } from '../Icons/styled';

type Props = {
  title: ReactNode;
  index: number;
  selected: number | null;
  children: ReactNode;
  severity?: Severity;
  setSelected: (index: number | null) => void;
  isInfoItem?: boolean;
};

export const MapSeverityBadgeIcon = (severity?: Severity) => {
  const theme = useTheme();

  switch (severity) {
    case 50:
      return <Icon type="notification.error_outlined" size="medium" />;
    case 40:
    case 30:
      return <StyledOutlinedWarningIcon color={theme === darkTheme ? theme.colors.gray200 : theme.colors.gray600} />;
    case 20:
    case 10:
      return <Icon type="notification.info_outlined" size="medium" />;
    default:
      return 'none';
  }
};

const Accordion = ({
  title,
  index,
  selected,
  setSelected,
  children,
  severity = undefined,
  isInfoItem = undefined
}: Props) => {
  const hasChildren = isValidElement(children) && (children.props.children ?? children.props.content);
  const expandContentHandler = () => {
    if (isValidElement(children)) {
      if (children.props.children ?? children.props.content) {
        setSelected(selected === index ? null : index);
      }
    }
  };

  return (
    <S.AccordionContainer>
      <S.AccordionHeaderSection
        onClick={expandContentHandler}
        $isSelected={index === selected}
        $isInfoItem={isInfoItem}
        $hasChildren={hasChildren}
      >
        <S.AccordionHeaderDiv>
          <S.AccordionHeaderIcon $severity={severity}>
            {MapSeverityBadgeIcon(severity)}
          </S.AccordionHeaderIcon>
          {title}
        </S.AccordionHeaderDiv>
        <S.AccordionIcon $hasChildren={hasChildren}>
          <Icon
            type={selected === index ? 'action.arrow_up' : 'action.arrow_down'}
            size="medium"
            color="onSurface"
          />
        </S.AccordionIcon>
      </S.AccordionHeaderSection>
      {selected === index && children}
    </S.AccordionContainer>
  );
};

export default Accordion;
