import { borderRadius, paddings } from 'core/styles';
import { FunctionalColor, getFunctionalLightColor, typographyDesktop } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

type Props = {
  $color?: FunctionalColor;
};

export const RefinedStatusWrapperDiv = styled.div<Props>`
  display : grid;
  grid-template-columns: 25px auto 5px 1fr;
  align-items: center;
  margin-bottom: ${paddings.mediumPadding};
  gap: ${paddings.smallPadding};
  background-color: ${({ $color, theme }) =>
    (theme === darkTheme ? getFunctionalLightColor(theme, $color) : getFunctionalLightColor(theme, $color, true, 30))};
  padding: ${paddings.smallPadding} ${paddings.mediumPadding};
  border-radius: ${borderRadius.default};
  `;

export const SecondaryStatusSpan = styled.span`
  font-weight: ${typographyDesktop.weight.bold};
`;
