import { useParams } from 'react-router-dom';
import { useGetAssetServiceContractsQuery } from 'feature/assets/api';
import { Loader } from 'hudskit-framework-react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import ErrorCard from 'core/components/ErrorCard';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setActiveServiceContracts } from 'feature/assets/store';
import { useEffect } from 'react';
import { getDate } from '../../utils';
import AssetInfoCell from '../AssetInfoCell';
import AssetDetailsServiceContractStatus from '../AssetDetailsServiceContractStatus';
import * as S from './styled';

const AssetDetailsServiceContract = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const { id } = useParams();
  const { data: contacts, isError, isFetching, isSuccess, isLoading, refetch } = useGetAssetServiceContractsQuery(id ?? '');

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setActiveServiceContracts(contacts));
  }, [contacts, dispatch]);

  return (
    <div>
      {isLoading && isFetching && <Loader center size="small" />}
      {isError && <ErrorCard onRetryClick={refetch} />}
      {isSuccess && !isError && !isLoading && contacts && (
        <S.ServiceContractDiv>
          {contacts?.map((contract) => (
            <S.ServiceContractSection key={contract.contractId}>
              <S.TitleH5>{contract.marketNameTranslations.value}</S.TitleH5>
              <S.ServiceContractWrapperDiv>
                <AssetInfoCell
                  label={t('ASSETS:details.serviceContract.label.contractId')}
                  value={contract.contractId ?? ''}
                  copyIcon="action.copy"
                  serviceContract
                />
                <AssetInfoCell
                  label={t('ASSETS:details.serviceContract.label.contractPnc')}
                  value={contract.articleNumber}
                  serviceContract
                />
                <AssetInfoCell
                  label={t('ASSETS:details.serviceContract.label.type')}
                  value={contract.hfsServiceContract}
                  serviceContract
                />
                <AssetInfoCell
                  label={t('ASSETS:details.serviceContract.label.startDate')}
                  value={getDate(contract.startOn)}
                  serviceContract
                />
                <AssetInfoCell
                  label={t('ASSETS:details.serviceContract.label.endDate')}
                  value={getDate(contract.endOn)}
                  serviceContract
                />
                <S.ServiceContractStatusDiv>
                  <S.StatusSpan>{t('ASSETS:details.serviceContract.label.status')}</S.StatusSpan>
                  <AssetDetailsServiceContractStatus status={contract.status} />
                </S.ServiceContractStatusDiv>
              </S.ServiceContractWrapperDiv>
            </S.ServiceContractSection>
          ))}
        </S.ServiceContractDiv>
      )}
    </div>
  );
};

export default AssetDetailsServiceContract;
