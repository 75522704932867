import { paddings } from 'core/styles';
import { borderRadius, device } from 'core/styles/variables';
import styled from 'styled-components';

interface ListItemOptions {
  $active?: boolean;
}

export const ThumbnailDiv = styled.div`
  grid-area: image;
  display: flex;
  justify-content: center;
  img {
    width: 60px;
  }
`;

export const ItemBodyDiv = styled.div`
  grid-area: description;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const MowerDetailsDiv = styled.div`
  flex-direction: column;
  display: flex;
  padding: ${paddings.mediumSmallPadding} 0;
  gap: ${paddings.mediumSmallPadding};
`;

export const ItemTitleSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 300px;
  flex-grow: 1;
  font-size: medium;
`;

export const ItemTitleContainer = styled.span`
  font-weight: bold;
  display: flex;
  color: ${({ theme }) => theme.colors.onSurface};
  max-height: 2em;
  line-height: 1em;
`;

export const ItemStatusDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ListItem = styled.article<ListItemOptions>`
  grid-template-areas: 'image description status';
  grid-template-columns: 70px minmax(155px, 1fr);
  display: grid;
  justify-content: center;
  align-items: center;
  border-radius: ${({ $active }) => ($active ? `${borderRadius.default}` : 'none')};
  border-left: 1px solid ${({ theme }) => theme.colors.surface};
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    border-radius: ${borderRadius.default};
  }
  @media ${() => device.xl} {
    ${ItemStatusDiv} {
      padding-right: ${() => paddings.defaultPadding};
    }
    ${ItemBodyDiv} {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: ${() => paddings.tinyPadding};
    }
  }
`;

export const ItemStatusSpan = styled.span`
  padding: ${() => paddings.tinyPadding};
  justify-self: start;
`;

export const StatusText = styled.div`
  justify-content: flex-start;
  overflow: hidden;
  width: 92%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StatusIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${paddings.mediumSmallPadding};
   @media ${device.xs} {
    gap: ${paddings.tinyPadding};
  }
`;
