import { FunctionalColor, getFunctionalColor, paddings, typographyDesktop } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $statusColor?: FunctionalColor;
};

export const StatusSpan = styled.span<Props>`
  background-color: ${({ $statusColor, theme }) => getFunctionalColor(theme, $statusColor)};
  padding: ${paddings.tinyPadding} ${paddings.mediumSmallPadding};
  border-radius: ${paddings.mediumSmallPadding};
  font-weight: ${typographyDesktop.weight.bold};
  color: ${({ theme }) => theme.colors.white};
`;
