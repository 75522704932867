import React from 'react';
import { darkTheme, Icon } from 'hudskit-framework-react';
import { StyledOutlinedWarningIcon } from 'core/components/Icons/styled';
import { WidgetAssetListResponse } from '@uptime-bff/api-contract';
import { useTheme } from 'styled-components';
import * as S from './styled';

export interface Props {
  goToAssetsWithoutErrors: () => void;
  goToAssetsFromAssetsList: () => void;
  goToAssetsWithWarningFromAssetsList: () => void;
  data: WidgetAssetListResponse;
  isWidgetsListUpdating: boolean;
}

const CustomerWidgetCardAssetsCount = ({
  data,
  isWidgetsListUpdating,
  goToAssetsWithoutErrors,
  goToAssetsFromAssetsList,
  goToAssetsWithWarningFromAssetsList,
}: Props) => {
  const theme = useTheme();
  return (
    <S.CountsGridDiv $isFetching={isWidgetsListUpdating}>
      {data?.isCompanyActive && Boolean(data?.totalAssets) && (
        <S.MachinesCountHeaderDiv>
          <S.CountsDiv $isFetching={isWidgetsListUpdating} onClick={goToAssetsWithoutErrors}>
            <Icon type="product.automower_top" />
            <S.HeadingText $isFetching={isWidgetsListUpdating}>{data.totalAssets}</S.HeadingText>
          </S.CountsDiv>
        </S.MachinesCountHeaderDiv>
      )}
      {data?.isCompanyActive && data.critical > 0 && (
        <S.MachinesCountHeaderDiv>
          <S.CountsDiv $isFetching={isWidgetsListUpdating} onClick={goToAssetsFromAssetsList}>
            <Icon type="notification.error_outlined" />
            <S.HeadingText $isFetching={isWidgetsListUpdating}>{data.critical}</S.HeadingText>
          </S.CountsDiv>
        </S.MachinesCountHeaderDiv>
      )}
      {data?.isCompanyActive && data.warning > 0 && (
        <S.MachinesCountHeaderDiv>
          <S.CountsDiv $isFetching={isWidgetsListUpdating} onClick={goToAssetsWithWarningFromAssetsList}>
            <StyledOutlinedWarningIcon color={theme === darkTheme ? theme.colors.gray200 : theme.colors.gray600} />
            <S.HeadingText $isFetching={isWidgetsListUpdating}>{data.warning}</S.HeadingText>
          </S.CountsDiv>
        </S.MachinesCountHeaderDiv>
      )}
    </S.CountsGridDiv>
  );
};

export default CustomerWidgetCardAssetsCount;
