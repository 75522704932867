import { paddings } from 'core/styles';
import { device, headerHeight } from 'core/styles/variables';
import { zDrawers, zFloatingPanels } from 'core/styles/z-index';
import styled from 'styled-components';

type Props = {
  $open?: boolean;
  $absolute?: boolean;
};

export const DrawerDiv = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  width: calc(100% - 4.5rem);
  height: 100%;
  padding: ${() => paddings.defaultPadding};
  padding-top: ${paddings.largePadding};
  gap: ${() => paddings.smallPadding};
  overflow-y: auto;
  flex-direction: column;
`;

export const DrawerButtonDiv = styled.div<Props>`
  display: block;
  padding-bottom: ${paddings.largePadding};
`;

export const FixedMenuContainerAside = styled.aside<Props>`
  margin-top: ${paddings.defaultPadding};
  z-index: ${({ $open }) => ($open ? zDrawers : zFloatingPanels)};
  width: 4.5rem;
  display: flex;
  position: fixed;
  right: ${({ $open }) => ($open ? 0 : 'calc(100vw - 4.5rem)')};
  bottom: ${headerHeight};
  flex-direction: column;
  transition: right 500ms;
`;
export const MenuContainerAside = styled.aside`
  margin-top: ${paddings.defaultPadding};
  width: 4.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: space-between;
`;
export const DrawerNav = styled.nav<Props>`
  z-index: ${zDrawers};
  position: ${({ $absolute }) => ($absolute ? 'fixed' : 'inherit')};
  width: ${({ $open }) => ($open ? '100%' : '0')};
  transition: all 500ms;
  height: 100%;
  display: flex;
  align-items: flex-end;
  align-content: flex-start;
  ${MenuContainerAside} {
    margin-bottom: ${({ $absolute }) => $absolute && headerHeight};
  }
  @media ${device.md} {
    align-items: flex-start;
    width: ${({ $open }) => ($open ? '20rem' : '4.5rem')};
  }
  ${DrawerDiv} {
    display: ${({ $open }) => ($open ? 'flex' : 'none')};
  }
`;
