import { paddings, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $icon?: boolean;
  $serviceContract?: boolean;
};

export const IconContainerDiv = styled.div<Props>`
  display: ${({ $icon }) => ($icon ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const ContentDiv = styled.div<Props>`
  display: flex;
  flex-direction: ${({ $serviceContract }) => ($serviceContract ? 'column' : '')};
  align-items: ${({ $serviceContract }) => ($serviceContract ? 'start' : 'end')};
  justify-content: ${({ $serviceContract }) => ($serviceContract ? 'start' : 'end')};
  overflow: auto;
  gap: ${paddings.smallPadding};
`;

export const LabelSpan = styled.span`
  font-weight: bold;
  min-width: fit-content;
`;

export const ValueSpan = styled.span`
  font-size: ${typographyMobile.size.default};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: ${typographyMobile.weight.regular};
  color: ${({ theme }) => theme.colors.onSurface};

`;

export const IconCopyAbleDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: ${({ $serviceContract }) => ($serviceContract ? '2px' : '')}; ;
`;
