import { typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $status?: boolean;
};

export const Header = styled.h6`
  font-size: ${() => typographyMobile.size.h6};
`;

export const HeaderContainer = styled.div<Props>`
  display: flex;
  justify-content: space-between;
  cursor: ${({ $status }) => ($status ? 'pointer' : 'default')};
`;
