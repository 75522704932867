import { RoutePaths } from 'core/models';
import { MenuItem, NavBottomListItem } from 'hudskit-framework-react';
import { useCallback, useMemo } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

export const useUptimeNavigation = (navMenu: MenuItem[] | NavBottomListItem[], onNavClick?: () => void) => {
  const location = useLocation();
  const navigate = useNavigate();

  const getActiveId = useCallback(
    (basePath: string, index: number) => {
      const matchedMenu = matchRoutes(
        Object.values(RoutePaths).map((path) => ({ path })),
        basePath
      );
      const menu = navMenu.find((navMenuItem) => navMenuItem.link === matchedMenu?.[index].route.path);
      return menu?.id ?? 0;
    },
    [navMenu]
  );

  const activeMenuId = useMemo(() => {
    const path = `/${location.pathname.split('/')[1]}`;
    return getActiveId(path, 0);
  }, [location.pathname, getActiveId]);

  const activeTabId = useMemo(() => {
    const path = `/${location.pathname.split('/')[3]}`;
    return getActiveId(path, 0);
  }, [location.pathname, getActiveId]);

  const activeDropdownMenu = useMemo(() => {
    const path = `${location.pathname.split('/')[1]}`;
    const menu = navMenu.find((navMenuItem) => navMenuItem.link === path);
    return menu?.id ?? 0;
  }, [location.pathname, navMenu]);

  const onClickHandler = useCallback(
    (menuItem: MenuItem) => {
      onNavClick?.();
      if (menuItem.link) {
        if (menuItem.link.startsWith('http')) {
          window.open(menuItem.link, '_blank');
          return;
        }
        navigate(menuItem.link);
      }
    },
    [navigate, onNavClick]
  );

  return { activeMenuId, activeTabId, activeDropdownMenu, onClickHandler };
};
