import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { ChartData } from 'feature/dashboard/hooks/use-status.chart-data';
import { useCallback } from 'react';
import { RoutePaths } from 'core/models';
import { clearFilters, setFilterByStatusOnActiveSearch, setFilterByModelsOnActiveSearch } from 'feature/assets/store';
import { t } from 'i18next';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useNavigate } from 'react-router-dom';

interface Props {
  model?: string[];
  total: number | undefined;
  chartData: ChartData[];
  goToAssetList: () => void;
}

const DoughtnutChart = ({
  model = [],
  total,
  chartData,
  goToAssetList
}: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onPieChartClickHandler = useCallback((pieData: { payload: { key: string } }) => {
    if (pieData?.payload?.key) {
      dispatch(clearFilters());
      dispatch(setFilterByStatusOnActiveSearch([pieData?.payload?.key]));
      dispatch(setFilterByModelsOnActiveSearch(model ?? []));
      navigate(RoutePaths.Assets);
    }
  }, [model, dispatch, navigate]);

  const getTotalNumberOfItemsComponent = useCallback(() => (
    <g onClick={goToAssetList}>
      <text id="totalNoOfItems" x="50%" y="50%" className="hoverable-label" fontSize={32}>
        <title>{t('DASHBOARD:dashboard.redirectMessage')}</title>
        {total}
      </text>
    </g>
  ), [goToAssetList, total]);

  return (
    <ResponsiveContainer height="100%" width="100%" minHeight={250}>
      <PieChart width={250} height={150} style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <Pie
          dataKey="value"
          innerRadius={65}
          outerRadius={80}
          data={chartData}
          onClick={onPieChartClickHandler}
          cursor="pointer"
          startAngle={90}
          endAngle={-270}
          style={{ outline: 'none', stroke: 'none' }}
        >
          {chartData.map((entry) => (
            <Cell fill={entry.color} key={entry.key} />
          ))}
        </Pie>

        {getTotalNumberOfItemsComponent()}
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default DoughtnutChart;
