import React, { useCallback } from 'react';
import { Checkbox } from 'hudskit-framework-react';
import { Card } from '@uptime-bff/api-contract';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { setCustomerWidgetsToBeDeleted } from 'feature/dashboard/store';
import { useAppSelector } from 'core/hooks/use-app-selector';
import * as S from './styled';

export interface Props {
  doneEditing: boolean;
  goToAssetsWithoutErrors: () => void;
  cardData: Card;
  isWidgetsListUpdating: boolean;
  hideEllipsis: boolean;
}

const CustomerWidgetCardHeader = ({
  cardData,
  isWidgetsListUpdating,
  goToAssetsWithoutErrors,
  hideEllipsis,
  doneEditing,
}: Props) => {
  const dispatch = useAppDispatch();
  const dashboardWidgetsListToBeDeleted = useAppSelector((state) => state.dashboard.widgetList);
  const handleCheckboxClickFn = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        if (!dashboardWidgetsListToBeDeleted?.includes(cardData.companyId)) {
          dispatch(setCustomerWidgetsToBeDeleted([...(dashboardWidgetsListToBeDeleted || []), cardData.companyId]));
        }
      } else if (dashboardWidgetsListToBeDeleted?.includes(cardData.companyId)) {
        const updatedList = dashboardWidgetsListToBeDeleted.filter((id) => id !== cardData.companyId);
        dispatch(setCustomerWidgetsToBeDeleted(updatedList));
      }
    },
    [cardData.companyId, dashboardWidgetsListToBeDeleted, dispatch]
  );
  return (
    <S.NameWithEllipsisDiv $hideEllipsis={hideEllipsis}>
      <S.HeadingDiv $isFetching={isWidgetsListUpdating} data-testid="companyName">
        {doneEditing && <Checkbox id="delete-widget-checkbox" disabled={isWidgetsListUpdating} onChange={handleCheckboxClickFn} />}
        <S.HeadingText
          onClick={goToAssetsWithoutErrors}
          $isFetching={isWidgetsListUpdating}
          title={!hideEllipsis && cardData.companyName.trim().length > 22 ? cardData.companyName : undefined}
        >
          {cardData.companyName}
        </S.HeadingText>
      </S.HeadingDiv>
    </S.NameWithEllipsisDiv>
  );
};

export default CustomerWidgetCardHeader;
