/* eslint-disable max-len */

type Props = {
  height?: string;
  width?: string;
  color?: string;
};

export const SuccessIcon = ({ height = '24px', width = '24px', color = '#31B900' }: Props) => (
  <svg data-testid="success-icon-svg" width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      data-testid="success-icon-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 12C1 5.928 5.928 1 12 1C18.072 1 23 5.928 23 12C23 18.072 18.072 23 12 23C5.928 23 1 18.072 1 12ZM17.1673 7.88909C17.3625 7.69383 17.6791 7.69382 17.8744 7.88909L18.5815 8.59619C18.7767 8.79146 18.7767 9.10804 18.5815 9.3033L11.5104 16.3744L10.8033 17.0815C10.608 17.2767 10.2915 17.2767 10.0962 17.0815L9.38909 16.3744L5.85355 12.8388C5.65829 12.6436 5.65829 12.327 5.85355 12.1317L6.56066 11.4246C6.75592 11.2294 7.0725 11.2294 7.26777 11.4246L10.4497 14.6066L17.1673 7.88909Z"
      fill={color}
    />
    <rect x="6.91406" y="11.0711" width="7" height="2" rx="0.5" transform="rotate(45 6.91406 11.0711)" fill="white" />
    <rect x="17.521" y="7.53552" width="2" height="12" rx="0.5" transform="rotate(45 17.521 7.53552)" fill="white" />
  </svg>

);

export const WarningIcon = ({ height = '24px', width = '24px', color = '#FFC800' }: Props) => (
  <svg data-testid="warning-icon-svg" width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      data-testid="warning-icon-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.731043 11.6973C0.327282 12.101 0.327282 12.7557 0.731043 13.1594L11.6971 24.1255C12.1009 24.5293 12.7555 24.5293 13.1593 24.1255L24.1254 13.1594C24.5292 12.7557 24.5292 12.101 24.1254 11.6973L13.1593 0.731165C12.7555 0.327405 12.1009 0.327404 11.6971 0.731165L0.731043 11.6973ZM11.3728 7.75424C11.3728 7.46874 11.6043 7.23729 11.8898 7.23729H12.9236C13.2092 7.23729 13.4406 7.46874 13.4406 7.75424V12.9237C13.4406 13.2092 13.2092 13.4407 12.9236 13.4407H11.8898C11.6043 13.4407 11.3728 13.2092 11.3728 12.9237V7.75424ZM11.8898 14.4746C11.6043 14.4746 11.3728 14.706 11.3728 14.9915V16.0254C11.3728 16.3109 11.6043 16.5423 11.8898 16.5423H12.9236C13.2092 16.5423 13.4406 16.3109 13.4406 16.0254V14.9915C13.4406 14.706 13.2092 14.4746 12.9236 14.4746H11.8898Z"
      fill={color}
    />
    <rect x="11.3726" y="7.23734" width="2.06779" height="6.20337" rx="0.516947" fill="#3D3D3C" />
    <rect x="11.3726" y="14.4746" width="2.06779" height="2.06779" rx="0.516947" fill="#3D3D3C" />
  </svg>
);

export const ErrorIcon = ({ height = '24px', width = '24px', color = '#DC2832' }: Props) => (
  <svg data-testid="error-icon-svg" width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      data-testid="error-icon-path"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5713 0.714585C11.7655 0.390915 12.2345 0.390914 12.4287 0.714584L23.5457 19.2428C23.7456 19.576 23.5056 20 23.1169 20H0.883096C0.494449 20 0.254393 19.576 0.45435 19.2428L11.5713 0.714585ZM11 7.50001C11 7.22386 11.2239 7.00001 11.5 7.00001H12.5C12.7761 7.00001 13 7.22386 13 7.50001V12.5C13 12.7761 12.7761 13 12.5 13H11.5C11.2239 13 11 12.7761 11 12.5V7.50001ZM11.5 15C11.2239 15 11 15.2239 11 15.5V16.5C11 16.7761 11.2239 17 11.5 17H12.5C12.7761 17 13 16.7761 13 16.5V15.5C13 15.2239 12.7761 15 12.5 15H11.5Z"
      fill={color}
    />
    <rect x="11" y="7" width="2" height="6" rx="0.5" fill="white" />
    <rect x="11" y="15" width="2" height="2" rx="0.5" fill="white" />
  </svg>
);

export const OutlinedWarningIcon = ({ height = '24px', width = '24px', color = '#000' }: Props) => (
  <svg data-testid="outlined_warning_icon" width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_405_68)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.82843 12.0209L12.0208 21.2133L21.2132 12.0209L12.0208 2.82852L2.82843 12.0209ZM0.707108 11.3138C0.316584 11.7043 0.316584 12.3375 0.707108 12.728L11.3137 23.3346C11.7042 23.7251 12.3374 23.7251 12.7279 23.3346L23.3345 12.728C23.725 12.3375 23.725 11.7043 23.3345 11.3138L12.7279 0.7072C12.3374 0.316676 11.7042 0.316676 11.3137 0.7072L0.707108 11.3138ZM11 7.50003C11 7.22389 11.2239 7.00003 11.5 7.00003H12.5C12.7761 7.00003 13 7.22389 13 7.50003V12.5C13 12.7762 12.7761 13 12.5 13H11.5C11.2239 13 11 12.7762 11 12.5V7.50003ZM11.5 14C11.2239 14 11 14.2239 11 14.5V15.5C11 15.7762 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7762 13 15.5V14.5C13 14.2239 12.7761 14 12.5 14H11.5Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_405_68">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
