import { useParams } from 'react-router-dom';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { useGetAssetContactByIdQuery } from 'feature/assets/api';
import { Loader } from 'hudskit-framework-react';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import ErrorCard from 'core/components/ErrorCard';
import AssetInfoCell from '../AssetInfoCell';

const AssetDetailsContact = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const { id } = useParams();
  const { data: assetContactResponse, isError, isFetching, isLoading, isSuccess, refetch } = useGetAssetContactByIdQuery(id ?? '');
  return (
    <>
      {isLoading && isFetching && <Loader center size="small" />}
      {isError && <ErrorCard onRetryClick={refetch} />}
      {isSuccess && !isError && !isLoading && assetContactResponse && (
        <>
          {assetContactResponse?.companyCountry && (
            <AssetInfoCell
              label={t('ASSETS:details.labels.country')}
              value={assetContactResponse.companyCountry}
              icon="other.location"
            />
          )}
          {assetContactResponse?.companyName && (
          <AssetInfoCell
            label={t('ASSETS:details.labels.companyName')}
            value={assetContactResponse.companyName}
            icon="action.home_outlined"
          />
          )}
          {assetContactResponse?.customerContactInformation && (
          <AssetInfoCell
            label={t('ASSETS:details.labels.contactInformation')}
            value={assetContactResponse.customerContactInformation}
            icon="action.call"
            link={`tel:${assetContactResponse.customerContactInformation}`}
          />
          )}
        </>
      )}
    </>
  );
};

export default AssetDetailsContact;
