import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { AssetListItem as Asset, OperationalStatusEnum } from '@uptime-bff/api-contract';
import { useMemo } from 'react';
import i18next from 'i18next';
import { useDisplayOperationalIcons } from 'feature/assets/hooks/use-display-operational-icons';
import { useTheme } from 'styled-components';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { mapInfoItemTitle } from 'feature/assets/utils';
import { Icon } from 'hudskit-framework-react';
import * as S from './styled';
import AssetThumbnail from '../AssetThumbnail';
import AssetListStatus from '../AssetListStatus';
import AssetListOperationalStatusIcon from '../AssetListOperationalStatusIcon';

type Props = {
  asset: Asset;
  active: boolean;
  onClick?: (asset: Asset) => void;
};
const AssetListItem = ({ asset, active, onClick = undefined }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const theme = useTheme();
  const { displayStatusIcon, displayGreenDot } = useDisplayOperationalIcons({ asset });
  const { operationalStatus, listInfoItems, actions } = asset.status ?? {};
  const isOpen = useAppSelector((state) => state.layout.isOpen);
  const isSelected = useAppSelector((state) => state.assets.selectedAsset?.id === asset.id);
  const country = useMemo(
    () =>
      (asset.country?.code
        ? new Intl.DisplayNames([i18next.language ?? 'en'], { type: 'region' }).of(asset.country?.code)
        : ''),
    [asset.country?.code]
  );
  const shouldDisplayErrorCode = (operationalStatus === OperationalStatusEnum.CRITICAL)
    || (operationalStatus === OperationalStatusEnum.WARNING);

  return (
    <S.ListItem id="assetDetailsList" $isOpen={isOpen} $active={active} key={asset.id} onClick={() => onClick?.(asset)}>
      <S.ThumbnailDiv>
        <AssetThumbnail
          imageSrc={asset.thumbnail}
          key={asset.id}
          imageAlt={asset.model}
        />
      </S.ThumbnailDiv>
      <S.ItemBodyDiv>
        <S.MowerDetailsDiv>
          <S.ItemTitleContainer>
            <S.ItemTitleSpan id="assetName">{asset.name}</S.ItemTitleSpan>
            {asset.activeServiceContract && (
              <S.TechSpecIconSpan data-testid="serviceContract">
                <Icon type="product.tech_spec" size="medium" color={theme.isDarkMode ? 'gray400' : 'gray500'} />
              </S.TechSpecIconSpan>
            )}
          </S.ItemTitleContainer>
          {asset.status?.secondaryStatus
            && (displayGreenDot || displayStatusIcon)
            && <AssetListStatus status={asset.status} displaySuccess={displayGreenDot} />}
          <S.ItemSubtextSmallSpan id="serialNumber">
            {asset.serialNumber && `${t('ASSETS:fieldLabels.serialNumber')}: ${asset.serialNumber}`}
          </S.ItemSubtextSmallSpan>
        </S.MowerDetailsDiv>
        <S.CompanyDetailsDiv>
          <S.ItemSubtextSpan id="companyName">{asset.company}</S.ItemSubtextSpan>
          {asset.country && (
            <S.ItemSubtextSmallSpan id="country">
              {country}
            </S.ItemSubtextSmallSpan>
          )}
        </S.CompanyDetailsDiv>
      </S.ItemBodyDiv>
      <S.ItemStatusDiv
        $errorCode={!!listInfoItems?.errorCode && operationalStatus !== OperationalStatusEnum.UNKNOWN}
        $isOpen={isOpen}
        $isSelected={isSelected}
      >
        {shouldDisplayErrorCode
          && <span>{mapInfoItemTitle(actions, listInfoItems)}</span>}
        {displayStatusIcon && operationalStatus && <AssetListOperationalStatusIcon operationalStatus={operationalStatus} />}
      </S.ItemStatusDiv>
    </S.ListItem>
  );
};

export default AssetListItem;
