import PageLoader from 'core/components/PageLoader';
import RootLayout from 'core/layout/RootLayout';
import { RoutePaths } from 'core/models';
import NotFound from 'core/pages/NotFound';
import Callback from 'feature/auth/pages/Callback';
import Login from 'feature/auth/pages/Login';
import TokenInfo from 'feature/auth/pages/TokenInfo';
import { ComponentType, ReactNode, Suspense } from 'react';
import { Loader } from 'hudskit-framework-react';
import { createBrowserRouter, RouteObject } from 'react-router-dom';
import Assets from 'feature/assets/pages/Assets/Assets';
import AssetDetails from 'feature/assets/components/AssetDetails/AssetDetails';
import AssetDetailsProductInfo from 'feature/assets/components/AssetDetailsProductInfo/AssetDetailsProductInfo';
import AssetDetailsContact from 'feature/assets/components/AssetDetailsContact/AssetDetailsContact';
import AssetDetailsServiceContract from 'feature/assets/components/AssetDetailsServiceContract/AssetDetailsServiceContract';
import AssetsMap from 'feature/assets/pages/AssetsMap/AssetsMap';
import Dashboard from 'feature/dashboard/pages/Dashboard/Dashboard';
import Companies from 'feature/companies/pages/Companies/Companies';
import CompaniesDetails from 'feature/companies/components/CompaniesDetails/CompaniesDetails';
import Faq from 'feature/faq/pages/Faq/Faq';
import CookiePolicy from 'feature/cookiepolicy/pages/CookiePolicy';
import Settings from 'feature/settings/pages/Settings';
import ErrorBoundary from './components/ErrorBoundary';
import * as S from '../feature/dashboard/pages/Dashboard/styled';
import { displayCookiePolicy } from './config';

export enum UserRoutes {
  UserLoginCallback = 'callback',
}

const componentWithSuspenseAndErrorBoundary = (Component: ComponentType, fallback: ReactNode = (
  <S.LoaderDiv>
    {Component !== Dashboard && <Loader size="medium" center />}
  </S.LoaderDiv>
)) => (
  <Suspense fallback={fallback}>
    <ErrorBoundary>
      <Component />
    </ErrorBoundary>
  </Suspense>
);

const getRoutes = (isDesktop: boolean) : RouteObject[] => [
  {
    path: '/',
    element: <RootLayout />,
    id: 'root',
    children: [
      {
        path: RoutePaths.Dashboard,
        element: componentWithSuspenseAndErrorBoundary(Dashboard),
      },
      {
        path: RoutePaths.Assets,
        element: componentWithSuspenseAndErrorBoundary(Assets),
        children: [
          {
            path: RoutePaths.Details,
            element: componentWithSuspenseAndErrorBoundary(AssetDetails),
            children: [
              {
                path: RoutePaths.AssetDetailProductInfomation,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsProductInfo),
              },
              {
                path: RoutePaths.AssetDetailContacts,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsContact),
              },
              {
                path: RoutePaths.AssetDetailServiceContract,
                element: componentWithSuspenseAndErrorBoundary(AssetDetailsServiceContract),
              }
            ]
          },
        ]
      },
      {
        path: RoutePaths.Companies,
        element: componentWithSuspenseAndErrorBoundary(Companies),
        children: [
          {
            path: RoutePaths.Details,
            element: componentWithSuspenseAndErrorBoundary(CompaniesDetails),
          }
        ]
      },
      {
        path: RoutePaths.AssetsMap,
        element: componentWithSuspenseAndErrorBoundary(AssetsMap),
      },
      {
        path: RoutePaths.UserInfo,
        element: componentWithSuspenseAndErrorBoundary(TokenInfo),
      },
      {
        path: RoutePaths.More,
        element: isDesktop ? componentWithSuspenseAndErrorBoundary(TokenInfo) : componentWithSuspenseAndErrorBoundary(Settings),
      },
      {
        path: RoutePaths.Faq,
        element: componentWithSuspenseAndErrorBoundary(Faq),
      },
    ],
  },
  ...(displayCookiePolicy ? [{
    path: RoutePaths.CookiePolicy,
    element: componentWithSuspenseAndErrorBoundary(CookiePolicy),
  }] : []),
  {
    path: RoutePaths.UserLogin,
    element: componentWithSuspenseAndErrorBoundary(Login, <PageLoader />),
    children: [
      {
        path: UserRoutes.UserLoginCallback,
        element: <Callback />,
      },
    ],
  },
  {
    path: RoutePaths.NotFound,
    element: <NotFound />,
  },
];

const getBrowserRouter = (isDesktop: boolean) => createBrowserRouter(getRoutes(isDesktop));

export { getBrowserRouter, getRoutes };
