import { borderRadius, paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import { zFloatingPanels } from 'core/styles/z-index';
import styled from 'styled-components';

export const ActiveAssetInfoDiv = styled.div`
  position: fixed;
  z-index: ${() => zFloatingPanels};
  right: calc(50px + ${() => paddings.defaultPadding});
  bottom: 115px;
  background-color: ${({ theme }) => theme.colors.surface};
  border-radius: ${() => borderRadius.default};
  padding: ${() => paddings.defaultPadding};
  font-weight: bold;

  @media ${device.lg} {
    bottom: ${() => paddings.defaultPadding};
  }
`;
