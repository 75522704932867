/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LayoutProps = {
  isOpen: boolean,
  showCookieBanner: boolean
  cookiesAccepted: boolean
};

export const defaultState: LayoutProps = {
  isOpen: false,
  showCookieBanner: true,
  cookiesAccepted: false
};

const slice = createSlice({
  name: 'layout',
  initialState: defaultState,
  reducers: {
    setIsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setCookiesAccepted: (state, action: PayloadAction<boolean>) => {
      state.cookiesAccepted = action.payload;
    },
    setShowCookieBanner: (state, action: PayloadAction<boolean>) => {
      state.showCookieBanner = action.payload;
    }
  },
});

export const {
  setIsOpen,
  setCookiesAccepted,
  setShowCookieBanner
} = slice.actions;
export default slice.reducer;
