import { AssetsSortOrder, AssetsSorting, AssetsSortingProps } from '@uptime-bff/api-contract';
import DropdownDrawer from 'core/components/DropdownDrawer';
import DropdownMenu, { DropdownMenuItemProps } from 'core/components/DropdownMenu/DropdownMenu';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { Button, darkTheme } from 'hudskit-framework-react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { setSortingOnActiveSearch } from 'feature/assets/store';
import { useTheme } from 'styled-components';
import * as S from './styled';

type SortIconButtonProps = {
  onClick?: () => void;
  label?: string;
};

const SortIconButton = ({ onClick = undefined, label }: SortIconButtonProps) => {
  const theme = useTheme();
  return (
    <S.ButtonWrapperDiv>
      <S.SortingDiv id="sortButton">
        {label && <S.LabelSpan>{label}</S.LabelSpan>}
        <Button
          customStyle={{
            backgroundColor: `${theme === darkTheme ? 'gray600' : 'gray200'}`,
            color: `${theme === darkTheme ? 'white' : 'gray700'}`,
          }}
          onClick={onClick}
          icon={{
            position: 'left',
            type: 'action.swap',
          }}
        />
      </S.SortingDiv>
    </S.ButtonWrapperDiv>
  );
};

type SortMenuItem = {
  sortValue: AssetsSorting;
  label: string;
};

const AssetSortMenu = () => {
  const dispatch = useAppDispatch();
  const activeSort = useAppSelector((state) => state.assets.assetListSorting);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);

  const sortingProps: SortMenuItem[] = useMemo(
    () => [
      {
        sortValue: {
          field: AssetsSortingProps.AssetName,
          order: AssetsSortOrder.Ascending,
        },
        label: t('ASSETS:fieldLabels.assetName'),
      },
      {
        sortValue: {
          field: AssetsSortingProps.CompanyCountry,
          order: AssetsSortOrder.Ascending,
        },
        label: t('ASSETS:fieldLabels.companyCountry'),
      },
      {
        sortValue: {
          field: AssetsSortingProps.Company,
          order: AssetsSortOrder.Ascending,
        },
        label: t('ASSETS:fieldLabels.companyName'),
      },
      {
        sortValue: {
          field: AssetsSortingProps.Model,
          order: AssetsSortOrder.Ascending,
        },
        label: t('ASSETS:fieldLabels.modelName'),
      },
    ],
    [t]
  );

  const menuItems = useMemo(
    () =>
      sortingProps.map((sortItem) => ({
        key: `${sortItem.sortValue.field}${sortItem.sortValue.order}`,
        label: sortItem.label,
        value: sortItem.sortValue,
      })),
    [sortingProps]
  );

  const [selected, setSelected] = useState<DropdownMenuItemProps<AssetsSorting> | undefined>(
    menuItems.find((item) => item.value.field === activeSort?.field)
  );
  const onSelectedHandler = useCallback(
    (selectedItem: DropdownMenuItemProps<AssetsSorting>) => {
      if (selectedItem.key === selected?.key) {
        setSelected(undefined);
        dispatch(setSortingOnActiveSearch(undefined));
        return;
      }
      setSelected(selectedItem);
      dispatch(setSortingOnActiveSearch(selectedItem.value));
    },
    [dispatch, selected?.key]
  );
  return (
    <DropdownDrawer menu={<SortIconButton label={selected?.label} />}>
      <DropdownMenu
        title={t('ASSETS:labels.sortBy')}
        items={menuItems}
        onSelected={onSelectedHandler}
        selected={selected}
      />
    </DropdownDrawer>
  );
};

export default AssetSortMenu;
