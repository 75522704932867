import { Button, darkTheme } from 'hudskit-framework-react';
import { useCallback } from 'react';
import { useTheme } from 'styled-components';
import * as S from './styled';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const CompanyFiltersButton = ({ isOpen, setIsOpen }: Props) => {
  const onHideClickHandler = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  const theme = useTheme();
  return (
    <S.FiltersButtonDiv $isOpen={isOpen}>
      <Button
        onClick={onHideClickHandler}
        customStyle={{
          backgroundColor: `${theme === darkTheme ? 'gray600' : 'gray200'}`,
          color: `${theme === darkTheme ? 'white' : 'gray700'}`,
        }}
        icon={{
          position: 'right',
          type: 'action.filter',
        }}
      />
    </S.FiltersButtonDiv>
  );
};

export default CompanyFiltersButton;
