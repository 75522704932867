import { Button, Icon, Loader, Tooltip } from 'hudskit-framework-react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { useTranslation } from 'react-i18next';
import { RoutePaths } from 'core/models/routes.model';
import ErrorCard from 'core/components/ErrorCard';
import { numberOfCustomerWidgetsAllowed } from 'core/config';
import ErrorBanner from 'core/components/ErrorBanner';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useNavigate } from 'react-router-dom';
import * as S from './styled';
import CustomerWidgetCard from '../CustomerWidgetCard';
import useCustomerWidgets from '../../hooks/use-customer-widgets';
import NoCustomerWidgets from '../NoCustomerWidgets/NoCustomerWidgets';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

const CustomerWidgets = () => {
  const { t } = useTranslation([InternationalizationNameSpace.Dashboard]);
  const navigate = useNavigate();

  const {
    isSuccess,
    data,
    isError,
    isFetching,
    isLoading,
    showErrorBanner,
    setShowErrorBanner,
    onSortEnd,
    handleRefreshClick,
    doneEditing,
    handleEditButtonClick,
    toolTipDescription,
    isRetryClicked,
    showDeleteConfirmation,
    setShowDeleteConfirmation,
    widgetDeletionInProgress,
    deleteCustomerWidgetFn,
    getIconColor,
    widgetDeletionSuccess,
    handleDelIconClickFn
  } = useCustomerWidgets();

  const AddCustomerWidgetBtnWrapper = (
    <S.AddPillDiv data-tooltip-id="addCompanyId">
      <Button
        variant="surface"
        icon={{
          type: 'action.add',
          position: 'right',
        }}
        disabled={(data?.widgets?.length ?? 0) >= numberOfCustomerWidgetsAllowed}
        onClick={() => navigate(RoutePaths.Companies)}
      />
    </S.AddPillDiv>
  );

  return (
    <>
      <S.HeaderRowDiv>
        <S.LeftContentDiv>
          <S.HeaderTitle data-testid="headerTitle">
            {t('DASHBOARD:dashboard.customerWidgets.headerTitle')}
          </S.HeaderTitle>
        </S.LeftContentDiv>
        <S.RightContentDiv>
          {isSuccess && (
            <>
              <Tooltip
                id="companiesAllowedTooltipId"
                data-testid="countTooltip"
                content={toolTipDescription}
                position="top"
              >
                {t('DASHBOARD:dashboard.customerWidgets.customerWidgetsSelectedOutOfAllowedWidgets', {
                  customerWidgetsSelected: `${data?.widgets?.length}`,
                  customerWidgetsAllowed: `${numberOfCustomerWidgetsAllowed}`,
                  defaultValue: 'Unexpected error',
                })}
              </Tooltip>
              {data && data?.widgets?.length <= numberOfCustomerWidgetsAllowed && AddCustomerWidgetBtnWrapper}
              {data && data?.widgets?.length > numberOfCustomerWidgetsAllowed && (
                <Tooltip
                  id="addCompanyId"
                  data-testid="addButtonTooltip"
                  position="top"
                  content={t('DASHBOARD:dashboard.customerWidgets.disableAddButtonTooltip')}
                >
                  {AddCustomerWidgetBtnWrapper}
                </Tooltip>
              )}
              {doneEditing && (
                <S.DelIconWrapper
                  data-test-id="deleteIcon"
                  $isFetching={isFetching}
                  onClick={handleDelIconClickFn}
                >
                  <Icon type="action.delete_outlined" color={getIconColor()} />
                </S.DelIconWrapper>
              )}
              {data && data?.widgets?.length > 0 && (
                <S.EditPillDiv data-testid="editBtn">
                  <Button
                    variant="surface"
                    icon={
                      !doneEditing
                        ? {
                          type: 'action.edit',
                          position: 'right',
                        }
                        : undefined
                    }
                    onClick={handleEditButtonClick}
                  >
                    {doneEditing && <>{t('DASHBOARD:dashboard.customerWidgets.doneEditingButtonText')}</>}
                  </Button>
                </S.EditPillDiv>
              )}
            </>
          )}
        </S.RightContentDiv>
      </S.HeaderRowDiv>
      {isError && (
        <S.ErrorWrapperDiv>
          <ErrorCard onRetryClick={handleRefreshClick} />
        </S.ErrorWrapperDiv>
      )}
      {(isRetryClicked || isLoading) && <Loader size="small" center padding />}
      {showErrorBanner && !isError && (
        <S.ErrorWrapperDiv>
          <ErrorBanner
            messageText={t('DASHBOARD:dashboard.customerWidgets.errors.deleteCompanyWidgets')}
            dismissText="Dismiss"
            iconType="notification.error"
            iconColor="error500"
            borderColor="error500"
            dismissClickHandler={() => setShowErrorBanner(false)}
          />
        </S.ErrorWrapperDiv>
      )}
      {!isRetryClicked && isSuccess && data && data?.widgets?.length > 0 && (
        <S.CardsContainerDiv>
          <SortableList onSortEnd={onSortEnd} allowDrag className="grid">
            {data?.widgets.map((item) => (
              <SortableItem key={item.companyId}>
                <CustomerWidgetCard
                  isWidgetsListUpdating={isFetching || widgetDeletionInProgress}
                  cardData={item}
                  key={item.companyId}
                  doneEditing={doneEditing}
                  isWidgetListError={isError}
                  widgetDeletionSuccess={widgetDeletionSuccess}
                />
              </SortableItem>
            ))}
          </SortableList>
        </S.CardsContainerDiv>
      )}
      {isSuccess && !isFetching && data?.widgets?.length === 0 && <NoCustomerWidgets />}
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        close={() => setShowDeleteConfirmation(false)}
        deleteCompany={deleteCustomerWidgetFn}
        isDeleteInProgress={widgetDeletionInProgress}
      />
    </>
  );
};

export default CustomerWidgets;
