import { device, paddings, typographyDesktop, typographyMobile } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const AssetThumbnailInfoContainerSection = styled.div<{ $isOpen: boolean }>`
  display: flex;
  align-items: center;
  margin: -${() => paddings.mediumPadding};
  gap: 10px;

  @media ${() => device.md} {
    justify-content: flex-start;
    margin-top: -2vw;
    margin-bottom: -2vw;
    ${({ $isOpen }) =>
    $isOpen
      && `
               @media (min-width: 48.25rem) and (max-width: 82rem){
                    display: flex;
                    margin-top: -2vw;
                    margin-bottom: 2vw;
                    flex-direction: column;
                    align-items: flex-start;
               }
            `}
  }
`;
export const ThumbnaildDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;

  @media ${() => device.md} {
    grid-area: details;
    padding-left: ${() => paddings.mediumPadding};
    margin-top: -2.5rem;
  }
`;

export const AssetNameSpan = styled.span<{ $isOpen: boolean }>`
  font-size: ${() => typographyMobile.size.medium};
  font-weight: ${() => typographyMobile.weight.bold};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  font-variant-ligatures: none;

  @media ${() => device.md} {
    font-size: ${() => typographyDesktop.size.h5};
    ${({ $isOpen }) =>
    $isOpen
      && `
                 @media (min-width: 48.25rem) and (max-width: 82rem){
                      max-width: 14ch;
                 }
              `}
  }
`;

export const AssetCompanyNameSpan = styled.span`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray400 : theme.colors.gray600)};

  @media ${() => device.md} {
    font-size: ${() => typographyDesktop.size.small};
  }
`;

export const AssetModelSpan = styled.span`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray400 : theme.colors.gray600)};

  @media ${() => device.md} {
    font-size: ${() => typographyDesktop.size.small};
    padding-top: ${() => paddings.smallPadding};
  }
`;

export const LoaderWrapperDiv = styled.div`
  width: 15rem;
  height: 15rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
`;
