/* eslint-disable max-len */
import Accordion from 'core/components/Accordion';
import ErrorCard from 'core/components/ErrorCard';
import { useState } from 'react';
import { useGetAssetsInfoItemsQuery } from 'feature/assets/api';
import { Loader } from 'hudskit-framework-react';
import { getInfoItemContent, mapInfoItemTitle } from 'feature/assets/utils';
import { useUpdateAssetListItem } from 'feature/assets/hooks/use-update-asset-list-item';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import AssetInfoItemContent from '../AssetInfoItemContent';
import * as S from './styled';

type Props = {
  assetdetails?: AssetDetailsResponse;
};

const AssetInfoItem = ({ assetdetails }: Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets]);
  const [selected, setSelected] = useState<number | null>(null);
  const { data, isError, isLoading, isSuccess, refetch } = useGetAssetsInfoItemsQuery(assetdetails?.iprId ?? '');
  useUpdateAssetListItem(assetdetails, data);

  return (
    <>
      {isError && <ErrorCard onRetryClick={refetch} />}
      {isLoading && <Loader center size="small" />}
      {!isError && !isLoading && isSuccess && data?.infoItems?.map((infoItem, index) => (
        <Accordion
          key={infoItem.infoItemId}
          title={<S.AccordionSpan>{mapInfoItemTitle(data?.action, infoItem, data.roboticMowerWorkingArea)}</S.AccordionSpan>}
          isInfoItem={!!infoItem.infoItemId}
          index={index}
          selected={selected}
          setSelected={setSelected}
          severity={infoItem.severity}
        >
          <S.InfoItemDescription>
            {getInfoItemContent(infoItem, assetdetails?.pnc, data?.action)
              ? (
                <AssetInfoItemContent
                  t={t}
                  content={getInfoItemContent(infoItem, assetdetails?.pnc, data?.action) ?? ''}
                  itemId={infoItem.infoItemId}
                />
              ) : null }
          </S.InfoItemDescription>
        </Accordion>
      ))}
    </>
  );
};

export default AssetInfoItem;
