import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import {
  clearFilters,
  setFilterByCompaniesOnActiveSearch,
  setFilterByOperationalStatusOnActiveSearch,
  setSelectedAsset,
} from 'feature/assets/store';
import { OperationalStatusEnum, AssetListItem, Card } from '@uptime-bff/api-contract';
import { useGetWidgetAssetsListQuery } from 'feature/dashboard/api';
import { RoutePaths } from 'core/models';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { dashboardWidgetsPollingInterval } from 'core/config';
import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';

export interface Props {
  doneEditing: boolean;
  cardData: Card;
  isWidgetListError: boolean;
  widgetDeletionSuccess: boolean;
}

export const useCustomerWidgetCardData = ({ cardData, isWidgetListError, widgetDeletionSuccess }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedCompanyIds = useAppSelector((state) => state.assets.activeListFilters?.diamGroupId);
  const isDesktop = useMediaQuery(device.lg);
  const {
    data,
    isSuccess: isSuccessFetchingAssetList,
    isError: isErrorFetchingAssetList,
    isLoading: isLoadingFetchingAssetList,
    refetch: getCustomerWidgetAssetList,
  } = useGetWidgetAssetsListQuery(
    { companyId: cardData.companyId },
    {
      pollingInterval: dashboardWidgetsPollingInterval,
    }
  );
  const selectedAsset = useAppSelector((state) => state.assets.selectedAsset);

  const goToAssets = useCallback(() => {
    dispatch(clearFilters());
    dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [cardData.companyId] }));
    dispatch(
      setFilterByOperationalStatusOnActiveSearch([OperationalStatusEnum.WARNING, OperationalStatusEnum.CRITICAL])
    );
    navigate(RoutePaths.Assets);
  }, [cardData.companyId, dispatch, navigate]);

  const goToAssetsWithoutErrors = useCallback(() => {
    dispatch(clearFilters());
    dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [cardData.companyId] }));
    navigate(RoutePaths.Assets);
  }, [cardData.companyId, dispatch, navigate]);

  useEffect(() => {
    if (isWidgetListError && isSuccessFetchingAssetList) {
      getCustomerWidgetAssetList();
    }
  }, [getCustomerWidgetAssetList, isSuccessFetchingAssetList, isWidgetListError]);

  useEffect(() => {
    if (widgetDeletionSuccess) {
      const updatedCompanyIds = selectedCompanyIds?.filter((id) => id !== cardData.companyId) || [];
      if (JSON.stringify(updatedCompanyIds) !== JSON.stringify(selectedCompanyIds)) {
        dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: updatedCompanyIds }));
      }
    }
  }, [dispatch, selectedCompanyIds, cardData.companyId, widgetDeletionSuccess]);

  const goToAssetsFromAssetsList = useCallback(() => {
    dispatch(clearFilters());
    dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [cardData.companyId] }));
    dispatch(setFilterByOperationalStatusOnActiveSearch([OperationalStatusEnum.CRITICAL]));
    navigate(RoutePaths.Assets);
  }, [cardData.companyId, dispatch, navigate]);

  const goToAssetsWithWarningFromAssetsList = useCallback(() => {
    dispatch(clearFilters());
    dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [cardData.companyId] }));
    dispatch(setFilterByOperationalStatusOnActiveSearch([OperationalStatusEnum.WARNING]));
    navigate(RoutePaths.Assets);
  }, [cardData.companyId, dispatch, navigate]);

  const onAssetItemClickHandler = useCallback(
    (asset: AssetListItem) => {
      dispatch(setSelectedAsset(asset));
      navigate(`/assets/${asset.id}/product-information`);
    },
    [dispatch, navigate]
  );

  const onAssetItemClick = useCallback(
    (asset: AssetListItem) => {
      dispatch(clearFilters());
      dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [cardData.companyId] }));
      dispatch(
        setFilterByOperationalStatusOnActiveSearch([OperationalStatusEnum.WARNING, OperationalStatusEnum.CRITICAL])
      );
      navigate('/assets');
      onAssetItemClickHandler(asset);
    },
    [cardData.companyId, dispatch, navigate, onAssetItemClickHandler]
  );

  return {
    data,
    isSuccessFetchingAssetList,
    isErrorFetchingAssetList,
    isLoadingFetchingAssetList,
    getCustomerWidgetAssetList,
    goToAssets,
    goToAssetsWithoutErrors,
    goToAssetsFromAssetsList,
    goToAssetsWithWarningFromAssetsList,
    onAssetItemClick,
    selectedAsset,
    widgetDeletionSuccess,
    isDesktop
  };
};
