import Accordion from 'core/components/Accordion';
import AccordionContent from 'feature/faq/components/AccordionContext/AccordionContent';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useFaqData } from 'feature/faq/hooks/use-faq-data';
import { useState } from 'react';
import * as S from './styled';

const Faq = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Faq);
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
  const faqData = useFaqData();
  return (
    <S.FaqContainer>
      <S.TitleH2>{t('FAQ:title.faq')}</S.TitleH2>
      {faqData.map(({ question }, index) => (
        <Accordion
          key={question}
          title={<S.AccordionH6>{question}</S.AccordionH6>}
          index={index}
          selected={selectedAnswer}
          setSelected={setSelectedAnswer}
        >
          <AccordionContent
            selected={selectedAnswer}
            index={index}
            link={faqData[index].link}
            content={faqData[index].answer}
          />
        </Accordion>
      ))}
    </S.FaqContainer>
  );
};

export default Faq;
