export enum RoutePaths {
  // Dashboard Route
  Dashboard = '/',

  // Assets Routes
  Assets = 'assets',
  AssetDetailProductInfomation = 'product-information',
  AssetDetailContacts = 'contacts',
  AssetDetailServiceContract = 'services',

  // Companies Routes
  Companies = 'companies',

  // Uptime Routes
  AssetsMap = 'uptime',

  // Auth Routes
  UserLogin = 'auth',
  UserInfo = '/user',

  // Cookie Policy
  CookiePolicy = '/cookie-policy',

  // Generic Routes
  NotFound = '*',
  Details = ':id',
  Faq = '/faq',
  More = '/more',
  // external
  GiveFeedback = '#',
}
