import styled from 'styled-components';
import { darkTheme } from 'hudskit-framework-react';
import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';

type ListItemOptions = {
  $title: boolean;
};

export const CompanyListDiv = styled.div`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray100 : theme.colors.gray800)};
  display: none;
  border-bottom: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
  font-weight: bold;
  @media ${device.sm}{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
  }
  @media ${device.lg}{
    grid-template-columns: repeat(7, 1fr);
  }
  `;

export const CompanyDiv = styled.div<ListItemOptions>`
  padding: ${paddings.smallPadding};
  font-size: 18px;
  order: 1;

`;

export const CompaniesWidgetToolTipInformationDiv = styled.div`
  padding: ${paddings.smallPadding}  0 0 0;
  cursor: help;
  position: relative;
  z-index: 0;
`;

export const TitleContainer = styled.div<ListItemOptions>`
  display: flex;
  justify-content: start;
  min-width: max-content;
  align-items: center;

  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7){
    justify-self: end;
    text-align: end;
  }

  @media ${device.sm}{
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6){
      display: none;
    } 
  }

  @media ${device.lg}{
    justify-content: start;
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7){
      display: flex;
    }
  }
`;
