import { paddings } from 'core/styles';
import { device, headerHeight } from 'core/styles/variables';
import { zFloatingPanels, zLayoutNavigation } from 'core/styles/z-index';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

type ActiveAssetProps = {
  $active?: boolean;
  $location?: boolean;
};

export const ListHeaderDiv = styled.div<ActiveAssetProps>`
  position: sticky;
  grid-template-columns: repeat(1, 1fr);
  z-index: ${zLayoutNavigation};
  border-radius: 12px;
  top: 0;
  gap: ${paddings.tinyPadding};
  background-color: ${({ theme }) => theme.colors.surface};
  padding: ${paddings.smallPadding};
  z-index: ${zFloatingPanels};
  
  @media ${device.lg} {
    top: ${headerHeight};
  }
`;

export const DetailsArticle = styled.article<ActiveAssetProps>`
  position: sticky;
  z-index: ${zFloatingPanels};
  top: 0;
  min-width: 0;
  max-height: calc(100vh - ${headerHeight} - ${paddings.smallPadding});
  background: ${({ theme }) => theme.colors.surface};
  color: ${({ theme }) => theme.colors.onSurface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => (theme === darkTheme ? '#3D3D3D' : '#F0F0F0')}; 

  @media ${device.lg} {
    top: ${headerHeight};
  }
  `;

export const DetailsHeaderDiv = styled.div<ActiveAssetProps>`
  display: flex;
  justify-content: end;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: ${zFloatingPanels};
  background-color: ${({ theme }) => (theme === darkTheme ? theme.colors.gray700 : theme.colors.surface)};
  padding: ${paddings.tinyPadding};
  border-radius: 10px;
`;

export const FiltersDiv = styled.div<ActiveAssetProps>`
  background: ${({ theme }) => theme.colors.background};
  display: none;
  flex-direction: column;
  position: sticky;
  z-index: ${zFloatingPanels};
  top: 0;
  
  @media ${device.lg} {
    top: ${headerHeight}
  }
`;

export const ListDiv = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.surface};
  border-radius: 12px;
  border: 1px solid ${({ theme }) => (theme === darkTheme ? '#3D3D3D' : '#F0F0F0')};
  color: ${({ theme }) => theme.colors.onBackground};
  display: flex;
  flex-direction: column;
  container-type: inline-size;

  @media ${device.lg} {
    max-height: 100%;
  }
`;

export const GridDiv = styled.main<ActiveAssetProps>`
  padding: 0 ${paddings.smallPadding};
  background-color: ${({ theme }) => theme.colors.background};
  gap: 6px;
  display: grid;
  grid-template-areas: ${({ $active, $location }) => ($active && !$location ? "'filters details'" : "'filters list'")};
  grid-template-columns: min-content 1fr;
  ${DetailsArticle} {
    display: ${({ $active, $location }) => ($active && !$location ? 'block' : 'none')};
  }
  ${ListDiv} {
    display: ${({ $active, $location }) => ($active && !$location ? 'none' : 'flex')};
  }
  ${FiltersDiv} {
    grid-area: filters;
    display: flex;
    height: calc(100vh - ${headerHeight} - ${paddings.smallPadding});
  }
  @media ${device.md} {
    min-width: 0;
    min-height: 0;
    grid-template-areas: ${({ $active, $location }) => ($active && !$location ? "'filters list details'" : "'filters list'")};
    grid-template-columns: ${({ $active, $location }) => ($active && !$location ? 'min-content 1fr 1fr' : 'min-content 1fr')};

    ${ListDiv} {
      grid-area: list;
      display: flex;
    }
    ${DetailsArticle} {
      grid-area: details;
      display: ${({ $active, $location }) => ($active && !$location ? 'flex' : 'none')};
      flex-direction: column;
      align-items: center;
      // height: calc(100vh - ${headerHeight} - ${paddings.smallPadding});
      ${DetailsHeaderDiv} {
        display: block;
        margin-left: auto;
      }
    }
  }
  @media ${device.xl} {
    grid-template-columns: ${({ $active, $location }) => ($active && !$location ? 'min-content 1fr 1fr' : 'min-content 1fr')};
  }
`;
