import { Button, IconLarge } from 'hudskit-framework-react';
import { useCallback } from 'react';
import { AssetListItem as Asset } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import FiltersDrawer from 'feature/assets/components/FiltersDrawer/FiltersDrawer';
import AssetList from 'feature/assets/components/AssetList';
import AssetSearch from 'feature/assets/components/AssetSearch';
import { useDispatch } from 'react-redux/es/hooks/useDispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { setSelectedAsset } from 'feature/assets/store';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import * as S from './styled';

const Assets = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets, InternationalizationNameSpace.Core]);
  const selectedAsset = useAppSelector((state) => state.assets.selectedAsset);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onAssetItemClickHandler = useCallback(
    (assetData: Asset) => {
      dispatch(setSelectedAsset(assetData));
      navigate(`/assets/${assetData.id}/product-information`);
    },
    [dispatch, navigate]
  );

  const onDetailsCloseHandlerWithNavigation = useCallback(() => {
    navigate('/assets');
    dispatch(setSelectedAsset(undefined));
  }, [dispatch, navigate]);

  const onDetailsCloseHandler = useCallback(() => {
    onDetailsCloseHandlerWithNavigation();
  }, [onDetailsCloseHandlerWithNavigation]);

  return (
    <S.GridDiv $active={!!selectedAsset} $location={location.pathname === '/assets'}>
      <S.FiltersDiv>
        <FiltersDrawer />
      </S.FiltersDiv>
      <S.ListDiv>
        <S.ListHeaderDiv>
          <AssetSearch />
        </S.ListHeaderDiv>
        <AssetList onAssetItemClickHandler={onAssetItemClickHandler} selectedAssetId={selectedAsset?.id} />
      </S.ListDiv>
      <S.DetailsArticle>
        <S.DetailsHeaderDiv>
          <Button
            type="button"
            size="small"
            variant="transparent"
            icon={{ type: 'action.close', position: 'left' }}
            onClick={onDetailsCloseHandler}
          >
            {t('CORE:buttons.close')}
          </Button>
        </S.DetailsHeaderDiv>
        {selectedAsset?.id && <Outlet />}
        {!selectedAsset && (
          <>
            <IconLarge type="productCategory.robotics" size="extraLarge" />
            <div>Select one item from the list</div>
          </>
        )}
      </S.DetailsArticle>
    </S.GridDiv>
  );
};

export default Assets;
