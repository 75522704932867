import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'core/models';
import MenuWithDropdown from 'core/layout/MenuWithDropdown';
import { useEffect } from 'react';
import * as S from './styled';

const Settings = () => {
  const isDesktop = useMediaQuery(device.lg);
  const navigate = useNavigate();

  useEffect(() => {
    if (isDesktop) {
      navigate(RoutePaths.UserInfo);
    } else {
      navigate(RoutePaths.More);
    }
  }, [isDesktop, navigate]);

  return (
    <S.SettingsWrapper>
      <MenuWithDropdown isOpen />
    </S.SettingsWrapper>
  );
};

export default Settings;
