import { FunctionalColor, getFunctionalColor, getFunctionalLightColor } from 'core/styles/variables';
import styled from 'styled-components';

type StatusProps = {
  $color?: FunctionalColor;
};
export const StatusCircle = styled.span<StatusProps>`
  display: block;
  min-height: 14px;
  min-width: 14px;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  border: 2px solid ${({ $color, theme }) => getFunctionalColor(theme, $color)};
  background-color: ${({ theme, $color }) => getFunctionalLightColor(theme, $color, false)};
  &::after {
    position: absolute;
    margin: auto;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -10px;
    right: 0;
    left: 0;
    border: 4px solid transparent;
    border-top: 8px solid ${({ theme, $color }) => getFunctionalColor(theme, $color)};
  }
`;
