import { NavMenuDropdown } from 'hudskit-framework-react';
import { useMemo } from 'react';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import useMediaQuery from 'core/hooks/use-media-query';
import { device } from 'core/styles/variables';
import { getDropdownMenu } from '../NavMenu/menu';

type Props = {
  isOpen?: boolean;
  onClick?: () => void;
};

const MenuWithDropdown = ({ isOpen, onClick }: Props) => {
  const isDesktop = useMediaQuery(device.lg);
  const dropdownMenu = useMemo(() => getDropdownMenu(isDesktop), [isDesktop]);
  const { activeDropdownMenu, onClickHandler } = useUptimeNavigation(dropdownMenu.menu, onClick);

  return (
    <div>
      {isOpen && (
        <NavMenuDropdown
          data-testid="menu-with-dropdown"
          activeMenuItemId={activeDropdownMenu}
          onClick={onClickHandler}
          menu={dropdownMenu.menu}
        />
      )}
    </div>
  );
};

export default MenuWithDropdown;
