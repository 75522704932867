import { device, typographyDesktop, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

export const ListItemsDiv = styled.div<{ $hasNoErrors: boolean }>`
  flex-grow: 1;
  height: 326px;
  overflow: hidden;
  display: ${({ $hasNoErrors }) => $hasNoErrors && 'flex'};
  justify-content: ${({ $hasNoErrors }) => $hasNoErrors && 'center'};
  @media ${device.xs} {
    height: ${({ $hasNoErrors }) => ($hasNoErrors ? '85px' : 'fit-content')};
    margin: 0;
  }
`;

export const ListCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const NoErrorsTextDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.onSurface};
  font-size: ${typographyDesktop.size.default};
  @media ${device.xs} {
    font-size: ${typographyMobile.size.small};
  }
`;
