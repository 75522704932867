import styled from 'styled-components';

export const ContentDiv = styled.div`
  display: block;

  &::after {
    content: ' ';
    white-space: pre;
  }
`;
