import { device, paddings } from 'core/styles/variables';
import styled from 'styled-components';

export { ErrorSpan } from 'feature/auth/pages/Callback/styled';

type Props = {
  $isFetching: boolean;
};

export const CountsDiv = styled.div<Props>`
  display: flex;
  align-items: center;
  cursor: ${({ $isFetching }) => !$isFetching && 'pointer'};
`;
export const CountsGridDiv = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  cursor: ${({ $isFetching }) => !$isFetching && 'pointer'};
  gap: ${paddings.defaultPadding};
  @media ${device.xs} {
    gap: ${paddings.mediumSmallPadding};
  }
`;

export const MachinesCountHeaderDiv = styled.div`
  display: flex;
  max-width: 65px;
`;

export const HeadingText = styled.h6<Props>`
  margin: 0 0 0 ${paddings.smallPadding};
  cursor: ${({ $isFetching }) => !$isFetching && 'pointer'};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
