import { Card, Icon, Loader } from 'hudskit-framework-react';
import { statusWidgetPollingInterval } from 'core/config/firebase/remote-config';
import { useTheme } from 'styled-components';
import { useCallback } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { clearFilters } from 'feature/assets/store';
import { RoutePaths } from 'core/models/routes.model';
import { useNavigate } from 'react-router-dom';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import ErrorCard from 'core/components/ErrorCard';
import { useGetAssetsCountQuery } from '../../api';
import * as S from './styled';
import { useStatusChartData } from '../../hooks/use-status.chart-data';
import LegendItem from '../LegendItem';
import DoughnutChart from '../DoughnutChart';

const StatusWidget = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Dashboard]);
  const {
    isSuccess,
    data,
    isLoading,
    isError,
    refetch: getAssetsCount,
  } = useGetAssetsCountQuery(undefined, {
    pollingInterval: statusWidgetPollingInterval,
  });
  const theme = useTheme();
  const { chartData, total } = useStatusChartData({ data, theme });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const goToAssets = useCallback(() => {
    dispatch(clearFilters());
    navigate(RoutePaths.Assets);
  }, [dispatch, navigate]);

  return (
    <Card>
      <S.WidgetContentDiv id="dbCardRoboticMowers">
        <S.HeaderDiv
          title={t('DASHBOARD:dashboard.redirectMessage')}
          data-testid="headerTitle"
          onClick={goToAssets}
          id="dashboardCardTitle"
        >
          <S.HeadingDiv>
            <Icon type="product.automower_top" />
            <S.HeadingText>{t('DASHBOARD:dashboard.roboticMowers')}</S.HeadingText>
          </S.HeadingDiv>
          <Icon type="action.arrow_right" />
        </S.HeaderDiv>
        {isError && <ErrorCard onRetryClick={getAssetsCount} />}
        {isLoading && <Loader size="medium" center padding />}
        {isSuccess && chartData && (
          <S.PieChartWithLegendDiv>
            <S.PieChartWithLegendColumnDiv>
              <DoughnutChart total={total} chartData={chartData} goToAssetList={goToAssets} />
            </S.PieChartWithLegendColumnDiv>

            <S.PieChartWithLegendColumnDiv>
              {chartData.map((value) => (
                <LegendItem
                  key={value.name}
                  keyName={value.key}
                  text={value.name}
                  num={value.value}
                  color={value.color}
                />
              ))}
            </S.PieChartWithLegendColumnDiv>
          </S.PieChartWithLegendDiv>
        )}
      </S.WidgetContentDiv>
    </Card>
  );
};
export default StatusWidget;
