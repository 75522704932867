import { IconTypeKey } from 'hudskit-framework-react';
import CompanyInfoCell from '../CompanyInfoCell';
import * as S from './styled';

type Props = {
  id?: string;
  label: string;
  value: string | number | undefined;
  icon?: IconTypeKey;
};

const CompaniesInfoContentCell = ({
  id = '',
  icon = 'none',
  label,
  value,
}: Props) => (
  <S.CompanyCellDiv>
    <CompanyInfoCell id={id} label={label} value={value} icon={icon} />
  </S.CompanyCellDiv>
);

export default CompaniesInfoContentCell;
