import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useSearchCompaniesByIdQuery } from 'feature/companies/api';
import { convertLanguageCodeToLanguage } from 'feature/companies/utils';
import { Loader } from 'hudskit-framework-react';
import { useParams } from 'react-router-dom';
import ErrorCard from 'core/components/ErrorCard';
import i18next from 'i18next';
import * as S from './styled';
import CompaniesInfoAssetsCell from '../CompaniesInfoAssetsCell';
import CompaniesInfoContentCell from '../CompaniesInfoContentCell';

export const convertCountryCodeToCountryName = (countryCodes?: string): string | undefined => {
  if (!countryCodes) {
    return undefined;
  }
  const countryName = new Intl.DisplayNames([i18next.language ?? 'en'], { type: 'region' });
  return countryName.of(countryCodes);
};

const CompaniesDetails = () => {
  const { id } = useParams();
  const {
    isError,
    isFetching,
    isSuccess,
    data: company
  } = useSearchCompaniesByIdQuery(id ?? '', {
    skip: !id,
  });

  const { t } = useTranslationWrapper(InternationalizationNameSpace.Companies);
  const contact = company?.contactInfo ? company.contactInfo : t('COMPANIES:companiesTableRow.contactInfoEmptyField');
  return (
    <>
      {isFetching && (
        <div>
          <Loader size="large" center />
        </div>
      )}
      {isError && <ErrorCard />}
      {!isFetching && isSuccess && !isError && company && id && (
        <S.infoDiv>
          <S.CompanyTitleH2 id="companyName">{company.name}</S.CompanyTitleH2>
          <CompaniesInfoAssetsCell company={company} iamId={id} />
          <S.CompanyContentDiv>
            <CompaniesInfoContentCell
              id="custNoCard"
              label={t('COMPANIES:companiesDetails.customerNumber')}
              value={company.customerNumber}
              icon="notification.info_outlined"
            />
            <CompaniesInfoContentCell
              id="contactInfoCard"
              label={t('COMPANIES:companiesDetails.contactInfo')}
              value={contact}
              icon="action.call"
            />
            <CompaniesInfoContentCell
              id="countryCard"
              label={t('COMPANIES:companiesDetails.country')}
              value={convertCountryCodeToCountryName(company?.countryCode)}
              icon="action.map"
            />
            <CompaniesInfoContentCell
              id="languageCard"
              label={t('COMPANIES:companiesDetails.language')}
              value={convertLanguageCodeToLanguage(company.language)}
              icon="action.web"
            />
            <CompaniesInfoContentCell
              id="timeZoneCard"
              label={t('COMPANIES:companiesDetails.timeZone')}
              value={company.timeZone}
              icon="notification.time_outlined"
            />
          </S.CompanyContentDiv>
        </S.infoDiv>
      )}
    </>

  );
};

export default CompaniesDetails;
