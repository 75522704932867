import { useCallback } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'core/models';
import { clearFilters, setFilterByModelsOnActiveSearch, setFilterByStatusOnActiveSearch } from 'feature/assets/store';
import * as S from './styled';

export interface Props {
  keyName: string;
  text: string;
  num: number | string;
  model?: string[];
  color?: string;
}

const LegendItem = ({ keyName, text, num, model = [], color = undefined }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onClickHandler = useCallback(() => {
    if (keyName) {
      dispatch(clearFilters());
      dispatch(setFilterByStatusOnActiveSearch([keyName]));
      dispatch(setFilterByModelsOnActiveSearch(model ?? []));
      navigate(RoutePaths.Assets);
    }
  }, [dispatch, keyName, model, navigate]);

  return (
    <S.LegendDiv data-testid="legend" onClick={onClickHandler}>
      <S.LegendLeftDiv data-testid={keyName}>
        <S.LegendPillDiv data-testid="legend-pill" $color={color} />
        <S.LegendNameDiv data-testid="legend-name">
          <span>{text}</span>
        </S.LegendNameDiv>
      </S.LegendLeftDiv>
      <S.LegendRightDiv data-testid="legend-right">
        <S.LegendNumDiv data-testid="legend-num">{num}</S.LegendNumDiv>
      </S.LegendRightDiv>
    </S.LegendDiv>
  );
};

export default LegendItem;
