import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import i18next from 'i18next';
import * as S from '../StrictlyNeededCookiesTable/styled';

const PerformanceCookiesTable = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.CookiePolicy);

  const performanceCookiesHeaders = [
    { key: 'cookie', translationKey: 'COOKIE_POLICY:tableOne.tableOneHeaders.cookie' },
    { key: 'cookieDomain', translationKey: 'COOKIE_POLICY:tableOne.tableOneHeaders.cookieDomain' },
    { key: 'lifespan', translationKey: 'COOKIE_POLICY:tableTwo.tableTwoHeaders.lifespan' },
    { key: 'category', translationKey: 'COOKIE_POLICY:tableOne.tableOneHeaders.category' },
    { key: 'purpose', translationKey: 'COOKIE_POLICY:tableOne.tableOneHeaders.purpose' },
  ];

  const tableData = [
    {
      rows: [
        {
          cookie: '_ga',
          domain: '.husqvarna.com',
          lifespan: 'COOKIE_POLICY:tableOne.firstTableWithData.days',
          lifespanValue: 400,
          category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
          purpose: 'COOKIE_POLICY:tableOne.firstTableWithData.gaPurpose',
        },
        {
          cookie: '_ga_<XXXXXXXXXXX>',
          domain: '.husqvarna.com',
          lifespan: 'COOKIE_POLICY:tableOne.firstTableWithData.days',
          lifespanValue: 400,
          category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
        },
      ],
    },
    {
      rows: [
        {
          cookie: 'CLID',
          domain: '.clarity.ms',
          lifespan: 'COOKIE_POLICY:tableOne.firstTableWithData.year',
          lifespanValue: 1,
          category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
          purpose: 'COOKIE_POLICY:tableOne.firstTableWithData.clidPurpose',
        },
        {
          cookie: 'MUID',
          domain: '.clarity.ms',
          lifespan: 'COOKIE_POLICY:tableOne.firstTableWithData.days',
          lifespanValue: 390,
          category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
        },
        {
          cookie: '_clck',
          domain: '.husqvarna.com',
          lifespan: 'COOKIE_POLICY:tableOne.firstTableWithData.year',
          lifespanValue: 1,
          category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
        },
        {
          cookie: '_clsk',
          domain: '.husqvarna.com',
          lifespan: 'COOKIE_POLICY:tableOne.firstTableWithData.day',
          lifespanValue: 1,
          category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
        },
      ],
    },
  ];

  return (
    <S.CookiePolicyTable>
      <S.CookiePolicyTableHead>
        <S.CookiePolicyTableHeadRow>
          {performanceCookiesHeaders.map(({ key, translationKey }) => (
            <S.CookiePolicyTableHeadCell key={key}>
              {t(translationKey)}
            </S.CookiePolicyTableHeadCell>
          ))}
        </S.CookiePolicyTableHeadRow>
      </S.CookiePolicyTableHead>
      <tbody>
        {tableData.map((group) =>
          group.rows.map((row, index) => (
            <S.CookiePolicyTableHeadRow key={row.cookie}>
              <S.CookiePolicyTableData>{row.cookie}</S.CookiePolicyTableData>
              <S.CookiePolicyTableData>{row.domain}</S.CookiePolicyTableData>
              <S.CookiePolicyTableData>
                {i18next.t(row.lifespan, { day: row.lifespanValue, days: row.lifespanValue, year: row.lifespanValue })}
              </S.CookiePolicyTableData>
              <S.CookiePolicyTableData>{t(row.category)}</S.CookiePolicyTableData>
              {index === 0 && row.purpose && (
                <S.CookiePolicyTableData rowSpan={group.rows.length}>
                  {t(row.purpose)}
                </S.CookiePolicyTableData>
              )}
            </S.CookiePolicyTableHeadRow>
          )))}
      </tbody>
    </S.CookiePolicyTable>
  );
};

export default PerformanceCookiesTable;
