import { Card, Icon } from 'hudskit-framework-react';
import { ReactNode, useCallback } from 'react';
import * as S from './styled';

type Props = {
  title: string;
  children: ReactNode;
  status?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};
const FilterCard = ({ title, children, status, isOpen, setIsOpen }: Props) => {
  const handleIsOpen = useCallback(() => {
    if (setIsOpen) {
      setIsOpen(!isOpen);
    }
  }, [setIsOpen, isOpen]);

  return (
    <Card>
      <S.HeaderContainer onClick={handleIsOpen} $status={status}>
        <S.Header>{title}</S.Header>
        {status ? <Icon type={status && isOpen ? 'action.arrow_up' : 'action.arrow_down'} size="small" /> : null }
      </S.HeaderContainer>
      {children}
    </Card>
  );
};

export default FilterCard;
