import { OperationalStatusEnum } from '@uptime-bff/api-contract';
import { darkTheme, Icon } from 'hudskit-framework-react';
import { StyledErrorIcon, StyledSuccessIcon, StyledWarningIcon } from 'core/components/Icons/styled';
import { useTheme } from 'styled-components';
import { DefaultTheme } from 'styled-components/dist/types';
import * as S from './styled';

export const mapStatusBadgeIcon = (status: OperationalStatusEnum, theme?: DefaultTheme) => {
  switch (status) {
    case OperationalStatusEnum.OK:
      return (
        <StyledSuccessIcon />
      );
    case OperationalStatusEnum.WARNING:
      return (
        <StyledWarningIcon />
      );
    case OperationalStatusEnum.CRITICAL:
      return (
        <StyledErrorIcon />
      );
    case OperationalStatusEnum.UNKNOWN:
      return (
        <Icon type="notification.help" color={theme === darkTheme ? 'gray500' : 'gray400'} size="medium" />
      );
    default:
      return null;
  }
};

type Props = {
  status: OperationalStatusEnum;
};

const StatusBadge = ({ status }: Props) => {
  const theme = useTheme();
  return (
    <S.RefinedStatusBadgeIcon data-testid="status-badge">
      {mapStatusBadgeIcon(status, theme)}
    </S.RefinedStatusBadgeIcon>
  );
};

export default StatusBadge;
