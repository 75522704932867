import { AssetDetailsResponse } from '@uptime-bff/api-contract';
import { Icon } from 'hudskit-framework-react';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useAddressLine } from 'feature/assets/hooks/use-address-line';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import CopyToClipboard from 'react-copy-to-clipboard';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import useMapUrl from 'feature/assets/hooks/use-map-url';
import useAddressFromGoogleApi from 'feature/assets/hooks/use-address-from-google-api';
import * as S from './styled';
import { getShareableGoogleMapsURL } from './utils';

type Props = {
  assetPosition: AssetDetailsResponse['assetPosition'];
  setHasAddress: (hasAddress: boolean) => void;
};

const AssetDetailsAddressline = ({ assetPosition, setHasAddress }: Props) => {
  const [copied, setCopied] = useState(false);
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const [assetDetailsAddressline, setAssetDetailsAddressline] = useState<string | null | undefined>(undefined);
  const assetAddressline = useAddressLine(assetPosition?.address, assetPosition?.addressLine);
  const hasAddress = assetDetailsAddressline !== t('ASSETS:addressline.missingAddress');

  const assetAddressURL = assetPosition?.location
    ? getShareableGoogleMapsURL(assetPosition?.location?.lat, assetPosition?.location?.lng)
    : '';
  const mapUrl = useMapUrl(assetPosition?.location, assetAddressURL);

  const onCopyHandler = useCallback(() => {
    const timer = setTimeout(() => {
      setCopied(false);
    }, 2000);
    setCopied(true);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setHasAddress(Boolean(assetDetailsAddressline));
  }, [assetDetailsAddressline, setHasAddress]);

  const { getPlaceDetails } = useAddressFromGoogleApi({
    placeId: assetPosition?.placeId,
    setAssetDetailsAddressline,
    assetAddressline,
  });

  useEffect(() => {
    getPlaceDetails();
  }, [getPlaceDetails]);

  return (
    <>
      <CopyToClipboard text={hasAddress ? assetAddressURL : ''}>
        <S.AddressLineGridWrapperDiv>
          <S.InnerLeftContentDiv
            $isCopied={copied}
            $hasAddress={hasAddress}
            onClick={hasAddress ? onCopyHandler : undefined}
          >
            {assetDetailsAddressline && (
              <S.MapIconDiv>
                <Icon type="action.map" color="gray400" size="small" />
              </S.MapIconDiv>
            )}
            <S.AssetAddressSpan data-tooltip-id="addressLine">{assetDetailsAddressline}</S.AssetAddressSpan>
            {hasAddress && assetDetailsAddressline && (
              <Icon
                type={copied ? 'action.success' : 'action.copy'}
                color={copied ? 'success500' : 'gray400'}
                size="small"
              />
            )}
          </S.InnerLeftContentDiv>
          <S.InnerRightContentDiv $disabled={!hasAddress}>
            <S.MapsAnchor data-testid="NavigateTo" $disabled={!hasAddress} href={hasAddress ? mapUrl : undefined} target="_blank">
              <Icon type="action.open_in_new_window" color={hasAddress ? 'brand110' : 'gray400'} size="small" />
              <S.TextWrapperDiv $disabled={!hasAddress}>{t('ASSETS:map.navigateTo')}</S.TextWrapperDiv>
            </S.MapsAnchor>
          </S.InnerRightContentDiv>
        </S.AddressLineGridWrapperDiv>
      </CopyToClipboard>
      <S.Tooltip>
        {!copied && hasAddress && (
          <Tooltip
            id="addressLine"
            content={t('ASSETS:details.labels.copyToClipboard')}
            className="tooltip"
            place="left"
            offset={20}
          />
        )}
      </S.Tooltip>
    </>
  );
};

export default AssetDetailsAddressline;
