/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  AssetDetailsResponse,
  AssetInfoItemsResponse,
  AssetListItem,
  AssetListRequest,
  AssetServiceContractResponse,
  AssetsSearchProps,
  AggregatedStatusEnum
} from '@uptime-bff/api-contract';

type ListFiltersType = AssetListRequest['filters'];
type ListSortingType = AssetListRequest['sorting'];
type ListFilterSearchType = {
  query: string;
  fields: AssetsSearchProps[];
};

export const defaultState: AssetListState = {
  activeServiceContracts: [],
  activeListFilters: undefined,
  assetList: {},
  assetListSorting: undefined,
  assetMapActiveAssetId: undefined,
  assetSearch: {
    query: '',
    fields: [AssetsSearchProps.AssetName],
  },
  selectedAsset: undefined,
};

interface AssetListState {
  activeServiceContracts: AssetServiceContractResponse;
  assetSearch?: ListFilterSearchType;
  activeListFilters: ListFiltersType;
  assetList: { [key: string]: AssetListItem };
  assetListSorting?: ListSortingType;
  assetMapActiveAssetId?: string;
  selectedAsset?: AssetListItem;
}

const assetListToMap = (list: AssetListItem[]): { [key: string]: AssetListItem } => {
  const payloadAssetsMap: { [key: string]: AssetListItem } = {};
  for (const asset of list) {
    payloadAssetsMap[asset.id] = asset;
  }
  return payloadAssetsMap;
};

const slice = createSlice({
  name: 'assets',
  initialState: defaultState,
  reducers: {
    setActiveServiceContracts: (state, action: PayloadAction<AssetServiceContractResponse | undefined>) => {
      state.activeServiceContracts = action.payload ?? [];
    },

    setSelectedAsset: (state, action: PayloadAction<AssetListItem | undefined>) => {
      state.selectedAsset = action.payload;
    },

    setSortingOnActiveSearch: (state, action: PayloadAction<ListSortingType>) => {
      state.assetListSorting = action.payload;
    },

    setFilterByIdOnActiveSearch: (state, action: PayloadAction<string>) => {
      state.activeListFilters = { ...state.activeListFilters, id: action.payload };
    },
    setFilterByModelsOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        models: action.payload.length > 0 ? action.payload : undefined,
      };
    },
    setFuzzySearchQuery: (state, action: PayloadAction<string>) => {
      state.assetSearch = {
        fields: state.assetSearch?.fields ?? [AssetsSearchProps.AssetName],
        query: action.payload,
      };
      slice.caseReducers.setSearchOnActiveSearch(state);
    },

    setFuzzySearchField: (
      state,
      action: PayloadAction<{ field: AssetsSearchProps; selected: boolean; value: string }>
    ) => {
      state.assetSearch = {
        fields: action.payload.selected ? [action.payload.field] : [AssetsSearchProps.AssetName],
        query: state.assetSearch?.query ?? '',
      };

      slice.caseReducers.setSearchOnActiveSearch(state);
    },

    setSearchOnActiveSearch: (state) => {
      if (
        state.assetSearch?.query.length
        && state.assetSearch?.query.length > 1
        && state.assetSearch?.fields.length
        && state.assetSearch?.fields.length > 0
      ) {
        state.activeListFilters = {
          ...state.activeListFilters,
          search: state.assetSearch,
        };
      } else {
        state.activeListFilters = {
          ...state.activeListFilters,
          search: undefined,
        };
      }
    },

    clearFuzzySearchOnActiveSearch: (state) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        search: undefined,
      };
      state.assetSearch = {
        fields: [AssetsSearchProps.AssetName],
        query: '',
      };
    },

    setFilterByCompaniesOnActiveSearch: (state, action: PayloadAction<{ diamGroupIds: string[] }>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        diamGroupId: action.payload.diamGroupIds.length > 0 ? action.payload.diamGroupIds : undefined,
      };
    },
    setFilterByCompanyCountryOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        companyCountries: action.payload.length > 0 ? action.payload : undefined,
      };
    },
    setFilterByCountriesOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        companyCountries: action.payload,
      };
    },
    setFilterByStatusOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        secondaryStatus: action.payload.length > 0 ? action.payload : undefined,
      };
    },

    setFilterByOperationalStatusOnActiveSearch: (state, action: PayloadAction<string[]>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        operationalStatus: action.payload.length > 0 ? action.payload : undefined,
      };
    },

    setFilterByServiceContractStatusOnActiveSearch: (state, action: PayloadAction<AggregatedStatusEnum | undefined>) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        serviceContractStatus: action.payload ?? undefined,
      };
    },
    appendAssetsToList: (state, action: PayloadAction<AssetListItem[]>) => {
      state.assetList = { ...state.assetList, ...assetListToMap(action.payload) };
    },

    updateAssetInfoItemInList: (state, action: PayloadAction<{ asset: AssetDetailsResponse, infoItem: AssetInfoItemsResponse }>) => {
      state.assetList = {
        ...state.assetList,
        [action.payload.asset.iprId]: {
          ...state.assetList[action.payload.asset.iprId],
          status: {
            ...state.assetList[action.payload.asset.iprId]?.status,
            listInfoItems: {
              errorCode: action.payload.infoItem.infoItems?.[0]?.errorCode,
              localTimestampOn: action.payload.infoItem.infoItems?.[0]?.localTimestampOn,
              infoItemId: action.payload.infoItem.infoItems?.[0]?.infoItemId,
              reminderDate: action.payload.infoItem.infoItems?.[0]?.reminderDate,
            },
          },
        },
      };
    },

    setAssetMapActiveAssetId: (state, action: PayloadAction<string | undefined>) => {
      state.assetMapActiveAssetId = action.payload;
    },
    setAssetList: (state, action: PayloadAction<AssetListItem[]>) => {
      state.assetList = assetListToMap(action.payload);
    },
    clearFilters: (state) => {
      state.activeListFilters = undefined;
      state.assetListSorting = undefined;
      state.assetSearch = {
        fields: [AssetsSearchProps.AssetName],
        query: '',
      };
    },
    clearCompanyNameFilters: (state) => {
      state.activeListFilters = {
        ...state.activeListFilters,
        diamGroupId: undefined,
      };
    },
  },
});

export const {
  setActiveServiceContracts,
  setSelectedAsset,
  setFilterByCompaniesOnActiveSearch,
  setFilterByCountriesOnActiveSearch,
  setFilterByIdOnActiveSearch,
  setFilterByStatusOnActiveSearch,
  setFilterByModelsOnActiveSearch,
  setFilterByOperationalStatusOnActiveSearch,
  setFilterByServiceContractStatusOnActiveSearch,
  appendAssetsToList,
  setAssetList,
  updateAssetInfoItemInList,
  setAssetMapActiveAssetId,
  clearFilters,
  clearCompanyNameFilters,
  setFilterByCompanyCountryOnActiveSearch,
  clearFuzzySearchOnActiveSearch,
  setSortingOnActiveSearch,
  setFuzzySearchField,
  setFuzzySearchQuery,
  setSearchOnActiveSearch,
} = slice.actions;
export default slice.reducer;
