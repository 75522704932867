import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { Loader } from 'hudskit-framework-react';
import { useGetAssetProductInfoByIdQuery } from 'feature/assets/api';
import { displayWarranty } from 'core/config';
import { useParams } from 'react-router-dom';
import ErrorCard from 'core/components/ErrorCard';
import { formatDate } from 'feature/assets/utils';
import AssetInfoCell from '../AssetInfoCell';
import * as S from './styled';
import AssetDetailsWarranty from '../AssetDetailsWarranty';

const AssetDetailsProductInfo = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const { id } = useParams();
  const { data: assetProductInfo, isError, isFetching, isSuccess, isLoading, refetch } = useGetAssetProductInfoByIdQuery(id ?? '');
  const purchaseDate = formatDate(assetProductInfo?.purchaseDate);
  return (
    <>
      {isLoading && isFetching && <Loader center size="small" />}
      {isError && <ErrorCard onRetryClick={refetch} />}
      {isSuccess && !isError && !isLoading && assetProductInfo && (
        <>
          <S.ProductInfoDiv>
            <div>
              {assetProductInfo?.serialNumber && (
                <AssetInfoCell
                  label={t('ASSETS:details.labels.serialNumber')}
                  value={assetProductInfo.serialNumber}
                  copyIcon="action.copy"
                />
              )}
              {assetProductInfo?.iprId && (
                <AssetInfoCell
                  label={t('ASSETS:details.labels.ipr')}
                  value={assetProductInfo.iprId}
                  copyIcon="action.copy"
                />
              )}
              {assetProductInfo?.pnc && (
                <AssetInfoCell
                  label={t('ASSETS:details.labels.pnc')}
                  value={assetProductInfo.pnc}
                  copyIcon="action.copy"
                />
              )}
            </div>
            <div>
              {purchaseDate && (
                <AssetInfoCell
                  label={t('ASSETS:details.labels.purchaseDate')}
                  value={purchaseDate}
                />
              )}
              {assetProductInfo?.fwVersion && (
                <AssetInfoCell
                  label={t('ASSETS:details.labels.firmwareVersion')}
                  value={assetProductInfo.fwVersion}
                />
              )}

            </div>
          </S.ProductInfoDiv>
          {displayWarranty && assetProductInfo.warranty && (
            <AssetDetailsWarranty warranty={assetProductInfo.warranty} />
          )}
        </>
      )}
    </>
  );
};

export default AssetDetailsProductInfo;
