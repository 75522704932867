import { AssetListItem as Asset } from '@uptime-bff/api-contract';
import { useDisplayOperationalIcons } from 'feature/assets/hooks/use-display-operational-icons';
import AssetListOperationalStatusIcon from 'feature/assets/components/AssetListOperationalStatusIcon';
import AssetThumbnail from 'feature/assets/components/AssetThumbnail';
import { Icon } from 'hudskit-framework-react';
import { mapInfoItemTitle } from 'feature/assets/utils';
import * as S from './styled';

type Props = {
  asset: Asset;
  active: boolean;
  onClick?: (asset: Asset) => void;
};
const CustomerWidgetAssetListItem = ({ asset, active, onClick = undefined }: Props) => {
  const { displayStatusIcon } = useDisplayOperationalIcons({ asset });
  const { operationalStatus, listInfoItems, actions } = asset.status ?? {};
  return (
    <S.ListItem id="customerWidgetAssetList" $active={active} key={asset.id} onClick={() => onClick?.(asset)}>
      <S.ThumbnailDiv>
        <AssetThumbnail imageSrc={asset.thumbnail} key={asset.id} imageAlt={asset.model} />
      </S.ThumbnailDiv>
      <S.ItemBodyDiv>
        <S.MowerDetailsDiv>
          <S.ItemTitleContainer>
            <S.ItemTitleSpan id="assetName">{asset.name}</S.ItemTitleSpan>
          </S.ItemTitleContainer>
          <S.ItemStatusDiv>
            <S.StatusText>{mapInfoItemTitle(actions, listInfoItems)}</S.StatusText>
          </S.ItemStatusDiv>
        </S.MowerDetailsDiv>
      </S.ItemBodyDiv>
      <S.StatusIcon>
        {displayStatusIcon && operationalStatus && (
          <AssetListOperationalStatusIcon operationalStatus={operationalStatus} />
        )}
        <Icon type="action.arrow_right" />
      </S.StatusIcon>
    </S.ListItem>
  );
};

export default CustomerWidgetAssetListItem;
