import { device, paddings, typographyMobile } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export { ErrorSpan } from 'feature/auth/pages/Callback/styled';

type Props = {
  $isFetching: boolean;
};
export const WidgetContentDiv = styled.div<{ $noAssets?: boolean }>`
  height: 400px;
  @media ${device.xs} {
    height: ${({ $noAssets }) => ($noAssets ? '140px' : 'fit-content')};
  }
`;

export const HeaderGridDiv = styled.div`
  display: flex;
  justify-content: space-between;
  height: 44px;
  @media ${device.xs} {
    gap: ${paddings.mediumSmallPadding};
  }
`;
export const CustomerWidgetAssetsListWrapper = styled.div<{ $noAssets: boolean }>`
  display: flex;
`;

export const LoaderDiv = styled(WidgetContentDiv)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 65%;
  @media ${device.xs} {
    height: 30%;
  }
`;

export const ErrorTryAgainWrapperDiv = styled(WidgetContentDiv)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.xs} {
    height: 30%;
  }
`;

export const CompanyDeletedDiv = styled(LoaderDiv)`
  height: 85%;
  @media ${device.xs} {
    font-size: ${typographyMobile.size.small};
    height: 85px;
  }
`;

export const AssetListDividerDiv = styled.div`
  border-bottom: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
`;

export const ViewMoreTextSpan = styled.div<Props>`
  font-size: ${() => typographyMobile.size.default};
  color: ${({ theme }) => theme.colors.brandDark};
  font-weight: ${typographyMobile.weight.bold};
  cursor: ${({ $isFetching }) => !$isFetching && 'pointer'};
  gap: ${paddings.tinyPadding};
`;

export const TooltipWrapperDiv = styled.div`
  cursor: inherit;
`;

export const ViewMoreWrapperDiv = styled.div<Props>`
  display: flex;
  justify-content: flex-end;
  padding-top: ${paddings.mediumSmallPadding};
  gap: ${paddings.tinyPadding};
`;
