import { RoutePaths } from 'core/models';
import { displayCookiePolicy, feedbackUrl } from 'core/config/firebase/remote-config';
import { MenuItem, NavBottomListItem } from 'hudskit-framework-react';
import NavMenuItem from '../NavMenuItem';

const getUptimeMenu = (isDesktop?: boolean) => {
  const uptimeMenuMap = new Map<RoutePaths, NavBottomListItem>([
    [
      RoutePaths.Dashboard,
      {
        id: 1,
        label: <NavMenuItem id="dashboard_menu" labelKey="CORE:menu.dashboard" path={RoutePaths.Dashboard} />,
        link: RoutePaths.Dashboard,
        icon: { type: 'action.statistics' },
      },
    ],
    [
      RoutePaths.Assets,
      {
        id: 2,
        label: <NavMenuItem id="asset_menu" labelKey="CORE:menu.assets" path={RoutePaths.Assets} />,
        link: RoutePaths.Assets,
        icon: { type: 'product.automower_top' },
      },
    ],
    [
      RoutePaths.Companies, {
        id: 3,
        label: <NavMenuItem id="company_menu" labelKey="CORE:menu.companies" path={RoutePaths.Companies} />,
        link: RoutePaths.Companies,
        icon: { type: 'action.group' },

      },
    ],
    [
      RoutePaths.AssetsMap,
      {
        id: 4,
        label: <NavMenuItem id="map_menu" labelKey="CORE:menu.map" path={RoutePaths.AssetsMap} />,
        link: RoutePaths.AssetsMap,
        icon: { type: 'action.map' },
      },
    ],
  ]);

  if (!isDesktop) {
    uptimeMenuMap.set(
      RoutePaths.More,
      {
        id: 5,
        label: <NavMenuItem
          id="settings_menu"
          labelKey="CORE:menu.more"
          path={RoutePaths.More}
        />,
        link: RoutePaths.More,
        icon: { type: 'action.context_menu_vertical' },
      }
    );
  }

  return { menuMap: uptimeMenuMap, menu: Array.from(uptimeMenuMap.values()).sort((a, b) => a.id - b.id) };
};

const getAssetTabMenu = (hasContract?: boolean) => {
  const assetTabMenuMap = new Map<RoutePaths, MenuItem>([
    [
      RoutePaths.AssetDetailProductInfomation,
      {
        id: 1,
        label: <NavMenuItem
          id="product_information_menu"
          labelKey="ASSETS:details.labels.productInfo"
          path={RoutePaths.AssetDetailProductInfomation}
        />,
        link: RoutePaths.AssetDetailProductInfomation,
      },
    ],
    [
      RoutePaths.AssetDetailContacts,
      {
        id: 2,
        label: <NavMenuItem id="conatact_menu" labelKey="ASSETS:details.labels.contact" path={RoutePaths.AssetDetailContacts} />,
        link: RoutePaths.AssetDetailContacts,
      },
    ],
  ]);

  if (hasContract) {
    assetTabMenuMap.set(RoutePaths.AssetDetailServiceContract, {
      id: 3,
      label: <NavMenuItem
        id="service_contract_menu"
        labelKey="ASSETS:details.labels.services"
        path={RoutePaths.AssetDetailServiceContract}
      />,
      link: RoutePaths.AssetDetailServiceContract,
    });
  }

  return { menuMap: assetTabMenuMap, menu: Array.from(assetTabMenuMap.values()) };
};

const getDropdownMenu = (isDesktop: boolean) => {
  const assetDropdownMenuMap = new Map<RoutePaths, MenuItem>();

  if (!isDesktop) {
    assetDropdownMenuMap.set(
      RoutePaths.UserInfo,
      {
        id: 1,
        label: <NavMenuItem
          id="settings_menu"
          labelKey="CORE:menu.dropdown.user"
          path={RoutePaths.UserInfo}
        />,
        iconLeft: 'action.avatar',
        link: RoutePaths.UserInfo,
      }
    );
  }
  assetDropdownMenuMap.set(
    RoutePaths.Faq,
    {
      id: 2,
      label: <NavMenuItem
        id="faq_menu"
        labelKey="CORE:menu.dropdown.faq"
        path={RoutePaths.Faq}
      />,
      iconLeft: 'action.customer_support',
      link: RoutePaths.Faq,
    },
  );
  if (displayCookiePolicy) {
    assetDropdownMenuMap.set(
      RoutePaths.CookiePolicy,
      {
        id: 3,
        label: <NavMenuItem
          id="cookie-policy-menu"
          labelKey="CORE:menu.dropdown.cookiePolicy"
          path={RoutePaths.CookiePolicy}
        />,
        iconLeft: 'other.cookie',
        link: RoutePaths.CookiePolicy,
      },
    );
  }
  if (feedbackUrl) {
    assetDropdownMenuMap.set(
      RoutePaths.GiveFeedback,
      {
        id: 4,
        label: <NavMenuItem
          id="feedback-menu"
          labelKey="CORE:menu.dropdown.feedback"
          path={RoutePaths.GiveFeedback}
        />,
        iconLeft: 'action.bubble',
        iconRight: 'action.external_link',
        link: feedbackUrl
      },
    );
  }

  return { menuMap: assetDropdownMenuMap, menu: Array.from(assetDropdownMenuMap.values()) };
};

export { getUptimeMenu, getAssetTabMenu, getDropdownMenu };
