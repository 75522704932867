import { device, typographyDesktop } from 'core/styles/variables';
import styled from 'styled-components';

export const FaqContainer = styled.div`
  width: 80%;
  margin: 45px auto;
`;

export const TitleH2 = styled.h2`
  font-size: ${typographyDesktop.size.h2};
  color: ${({ theme }) => theme.colors.onSurface};
  @media ${device.md}{
    font-size: ${typographyDesktop.size.h2};
  }
`;

export const AccordionH6 = styled.h6`
  white-space: break-spaces;
  margin: 0;
  cursor: pointer;
  font-weight: bold;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.onSurface};
`;
