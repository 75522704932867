import { device, FunctionalColor, getFunctionalColor, paddings, typographyDesktop, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

type Props = {
  $statusColor?: FunctionalColor;
};

export const ServiceContractDiv = styled.div`
  padding: ${paddings.mediumPadding};
`;

export const ServiceContractSection = styled.section`
  padding: ${paddings.tinyPadding} 0 ${paddings.largePadding} 0;
`;

export const ServiceContractWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  @media ${device.lg} {
    grid-template-columns: auto auto ;

  }
  @media ${device.xxl} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
  }
`;

export const TitleH5 = styled.h5`
  font-size: ${typographyMobile.size.h5};
  font-weight: ${typographyMobile.weight.bold};

  @media ${device.md} {
    font-size: ${typographyMobile.size.h4}
  }
`;

export const ServiceContractStatusDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-self: start;

  @media ${device.md} {
    align-items: center;
  }
`;

export const StatusSpan = styled.span<Props>`
  background-color: ${({ $statusColor, theme }) => getFunctionalColor(theme, $statusColor)};
  padding: 6px 12px;
  border-radius: 8px;
  font-weight: ${typographyDesktop.weight.bold};
  gap: 8px;
`;
