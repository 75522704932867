import { ReactNode } from 'react';
import { FunctionalColor } from 'core/styles/variables';
import * as S from './styled';

type Props = {
  children: ReactNode;
  color: FunctionalColor;
  isLoading?: boolean;
};
const PillTag = ({
  children,
  color,
  isLoading = false,
}: Props) => (
  <S.PillDiv $isLoading={isLoading} data-testid="pillDiv" color={color}>
    {children}
  </S.PillDiv>
);

export default PillTag;
