import * as S from './styled';
import AssetInfoCellContent, { ContentProps } from '../AssetInfoCellContent/AssetInfoCellContent';

type LinkProps = {
  link?: string;
  serviceContract?: boolean;
};

type Props = ContentProps & LinkProps;

const AssetInfoCell = ({ label, value, icon, link = undefined, copyIcon, serviceContract }: Props) => {
  if (!link) {
    return (
      <S.InfoCellDiv $icon={!!icon} $serviceContract={serviceContract}>
        <AssetInfoCellContent label={label} value={value} icon={icon} copyIcon={copyIcon} serviceContract={serviceContract} />
      </S.InfoCellDiv>
    );
  }
  return (
    <S.InfoCellAnchor target="_blank" href={link} $icon={!!icon}>
      <AssetInfoCellContent label={label} value={value} icon={icon} />
    </S.InfoCellAnchor>
  );
};

export default AssetInfoCell;
