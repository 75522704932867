import { type AssetListItem as Asset } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';

import * as S from './styled';
import CustomerWidgetAssetListItem from '../CustomerWidgetAssetListItem';

type Props = {
  onAssetItemClickHandler: (asset: Asset) => void;
  selectedAssetId?: string;
  assets: Asset[];
};

const CustomerWidgetAssetList = ({ onAssetItemClickHandler, selectedAssetId = undefined, assets }: Props) => {
  const { t } = useTranslationWrapper([
    InternationalizationNameSpace.Assets,
    InternationalizationNameSpace.Core,
    InternationalizationNameSpace.Dashboard,
  ]);
  return (
    <S.ListItemsDiv $hasNoErrors={assets.length === 0}>
      {assets.length === 0 && (
        <S.NoErrorsTextDiv>{t('DASHBOARD:dashboard.customerWidgets.errors.noErrorsText')}</S.NoErrorsTextDiv>
      )}

      {assets.length > 0 && (
        <>
          {assets.map((asset: Asset) => (
            <CustomerWidgetAssetListItem
              key={asset.id}
              active={!!(selectedAssetId && selectedAssetId === asset.id)}
              asset={asset}
              onClick={onAssetItemClickHandler}
            />
          ))}
        </>
      )}
    </S.ListItemsDiv>
  );
};

export default CustomerWidgetAssetList;
