import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import CookiesSection from 'feature/cookiepolicy/components/CookiesSection';
import CookiesCategorization from 'feature/cookiepolicy/components/CookiesCategorization';
import StrictlyNeededCookiesTable from 'feature/cookiepolicy/components/StrictlyNeededCookiesTable';
import PerformanceCookiesTable from 'feature/cookiepolicy/components/PerformanceCookiesTable';
import WebStorageCookiesTable from 'feature/cookiepolicy/components/WebStorageCookiesTable/WebStorageCookiesTable';
import * as S from './styled';

const CookiePolicy = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.CookiePolicy);

  return (
    <S.CookiePolicySection>
      <S.CookiePolicyTitleH1>{t('COOKIE_POLICY:titles.main')}</S.CookiePolicyTitleH1>
      <S.ContainerSection data-testid="cookiesSection">
        <S.CookiePolicyTitleH2>{t('COOKIE_POLICY:titles.subCookies')}</S.CookiePolicyTitleH2>
        <CookiesSection />
      </S.ContainerSection>
      <S.ContainerSection data-testid="cookiesCategorizationSection">
        <S.CookiePolicyTitleH2>{t('COOKIE_POLICY:titles.subCookiesCategorization')}</S.CookiePolicyTitleH2>
        <CookiesCategorization />
      </S.ContainerSection>
      <S.ContainerSection data-testid="ourCookiesSection">
        <S.CookiePolicyTitleH2>{t('COOKIE_POLICY:titles.subOurCookies')}</S.CookiePolicyTitleH2>
        <S.OurCookiesSection>
          <h6>{t('COOKIE_POLICY:subOurCookie.strictlyNessessaryCookie.title')}</h6>
          <p>
            {t('COOKIE_POLICY:subOurCookie.strictlyNessessaryCookie.text')}
          </p>
        </S.OurCookiesSection>
        <StrictlyNeededCookiesTable />
        <S.OurCookiesSection>
          <h6>{t('COOKIE_POLICY:subOurCookie.performanceCookies.title')}</h6>
          <p>
            {t('COOKIE_POLICY:subOurCookie.performanceCookies.text')}
          </p>
        </S.OurCookiesSection>
        <PerformanceCookiesTable />
      </S.ContainerSection>
      <S.ContainerSection data-testid="rejectCookiesSection">
        <S.CookiePolicyTitleH2>{t('COOKIE_POLICY:titles.rejectCookies')}</S.CookiePolicyTitleH2>
        <p>
          {t('COOKIE_POLICY:rejectCookies.text')}
        </p>
      </S.ContainerSection>
      <S.ContainerSection data-testid="webStorageSection">
        <S.CookiePolicyTitleH2>{t('COOKIE_POLICY:titles.webStorage')}</S.CookiePolicyTitleH2>
        <div>
          <p>
            {t('COOKIE_POLICY:webStorage.text')}
          </p>
          <p>
            {t('COOKIE_POLICY:webStorage.additionalText')}
            <S.CookieCatagorizationAnchor href="/#">{t('COOKIE_POLICY:webStorage.link')}</S.CookieCatagorizationAnchor>
          </p>
        </div>
        <WebStorageCookiesTable />
      </S.ContainerSection>
    </S.CookiePolicySection>
  );
};

export default CookiePolicy;
