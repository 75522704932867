import { Tag } from 'hudskit-framework-react';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import * as S from './styled';

type TagProps = {
  /**
   * Identifier for the checkbox
   */
  id: string;
  /**
   * A string representing the value of the checkbox
   */
  value: string;
  /**
   * Checkbox human readable value
   */
  children: string;
  /**
   * A boolean attribute indicating whether this checkbox is checked by default
   */
  defaultChecked?: boolean;
  /**
   * A boolean attribute indicating whether this checkbox is checked.
   */
  checked?: boolean;
  /**
   * A checkbox group is defined by giving each of checkbox in the group the same name
   */
  name: string;
  /**
   * Function to be called on change
   */
  onChange?: (value: string, checked: boolean) => void;
  /**
   * useForm register function
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register?: any;
};
const TagCheckbox = ({
  children,
  value,
  name,
  id,
  register = undefined,
  checked = undefined,
  defaultChecked = undefined,
  onChange = undefined,
}: TagProps) => {
  const [isTagActive, setIsTagActive] = useState(defaultChecked);
  const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(value, e.target.checked);
    setIsTagActive(e.target.checked);
  }, [onChange, value]);

  useEffect(() => {
    if (checked !== undefined) {
      setIsTagActive(checked);
    }
  }, [checked]);
  return (
    <label htmlFor={id}>
      <Tag outlined active={!isTagActive}>
        {children}
      </Tag>
      <S.Input
        id={id}
        type="checkbox"
        defaultChecked={defaultChecked}
        checked={checked}
        value={value}
        name={name}
        onChange={onChangeHandler}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register}
      />
    </label>
  );
};

export default TagCheckbox;
