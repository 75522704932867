import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';

const useFaqData = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Faq);

  return [
    { question: t('FAQ:questions.questionOne'), answer: t('FAQ:answers.answerOne') },
    { question: t('FAQ:questions.questionTwo'), answer: t('FAQ:answers.answerTwo') },
    { question: t('FAQ:questions.questionThree'), answer: t('FAQ:answers.answerThree') },
    { question: t('FAQ:questions.questionFour'), answer: t('FAQ:answers.answerFour') },
    { question: t('FAQ:questions.questionFive'), answer: t('FAQ:answers.answerFive') },
    { question: t('FAQ:questions.questionSix'), answer: t('FAQ:answers.answerSix') },
    { question: t('FAQ:questions.questionSeven'), answer: t('FAQ:answers.answerSeven') },
    { question: t('FAQ:questions.questionEight'), answer: t('FAQ:answers.answerEight') },
    { question: t('FAQ:questions.questionNine'), answer: t('FAQ:answers.answerNine') },
    { question: t('FAQ:questions.questionTen'),
      answer: `${t('FAQ:answers.answerTenPartOne')}${t('FAQ:answers.answerTenPartTwo')}`,
      link: 'https://fleetservices.husqvarna.com/dealer-access' },
  ];
};

export { useFaqData };
