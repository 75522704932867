import { Trans } from 'react-i18next';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from './styled';

type Props = {
  selected: number | null;
  index: number;
  link?: string;
  content: string;
};

const AccordionContent = ({
  selected,
  index,
  link = undefined,
  content
}: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Faq);
  return (
    <S.AccordionContextSection $isSelected={index === selected}>
      {!link && <S.AccordionContextSpan>{content}</S.AccordionContextSpan>}
      {link && (
        <Trans
          i18nKey={`${t('FAQ:answers.answerTenPartOne')}${t('FAQ:answers.answerTenPartTwo')}`}
          components={{ fleetLink: (
            <S.FleetAnchor href={link} target="_blank" />),
          style: <S.AccordionContextSpan $link={!!link} />
          }}
        />
      )}
    </S.AccordionContextSection>
  );
};

export default AccordionContent;
