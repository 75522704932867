import { RefinedStatusConcept } from '@uptime-bff/api-contract';
import { statusBadgeColor } from 'feature/assets/utils';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import StatusBadge from '../StatusBadge/StatusBadge';
import * as S from './styled';

type Props = {
  assetStatus: RefinedStatusConcept;
};

const AssetRefinedStatus = ({ assetStatus }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Status);
  return (
    <S.RefinedStatusWrapperDiv data-testid="error-status" $color={statusBadgeColor(assetStatus?.operationalStatus)}>
      {assetStatus.operationalStatus && <StatusBadge status={assetStatus?.operationalStatus} />}
      <S.SecondaryStatusSpan>
        {
          t(`statusTranslation:status.secondaryStatus.${assetStatus?.secondaryStatusTranslationKey}`)
        }
      </S.SecondaryStatusSpan>
      {assetStatus?.secondaryStatusSubStatusTranslationKey && (
        <>
          {'| '}
          <span>
            {
              t(`statusTranslation:status.secondaryStatus.additionalStatus.${assetStatus?.secondaryStatusSubStatusTranslationKey}`)
            }
          </span>
        </>
      )}
    </S.RefinedStatusWrapperDiv>
  );
};

export default AssetRefinedStatus;
