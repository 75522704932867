import { OperationalStatusEnum } from '@uptime-bff/api-contract';
import { mapStatusBadgeIcon } from '../StatusBadge/StatusBadge';
import * as S from './styled';

type Props = {
  operationalStatus: OperationalStatusEnum;
};

const AssetListOperationalStatusIcon = ({ operationalStatus }:Props) => (
  <S.StatusBadgeIcon>{mapStatusBadgeIcon(operationalStatus)}</S.StatusBadgeIcon>
);

export default AssetListOperationalStatusIcon;
