import { borderRadius, paddings } from 'core/styles';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

type Props = {
  $color?: string;
};

export const LegendPillDiv = styled.div<Props>`
  width: 16px;
  height: 8px;
  border-radius: ${borderRadius.default};
  margin-right: ${paddings.smallPadding};
  background-color: ${({ $color }) => $color};
  border: 1px solid ${({ $color }) => `1px solid ${$color}`};
`;

export const LegendNameDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: ${paddings.smallPadding};
  word-break: break-word;
`;

export const LegendLeftDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const LegendRightDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const LegendNumDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: ${paddings.smallPadding};
`;

export const LegendDiv = styled.div`
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 0 ${paddings.smallPadding};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray200)};
    border-radius: 12px;
    color: ${({ theme }) => (theme === darkTheme ? theme.colors.onSurface : 'inherit')};
  }
`;
