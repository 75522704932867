import { Icon, IconTypeKey } from 'hudskit-framework-react';
import * as S from './styled';

type Props = {
  id?: string,
  label: string,
  value: string | number | undefined,
  icon?: IconTypeKey;
  clickable?: boolean;
};

const CompanyInfoCell = ({
  id = '',
  icon = 'none',
  label,
  value,
  clickable = false,
}:Props) => (
  <S.CompanyInfoCellDiv id={id}>
    <S.IconDiv $number={typeof value === 'number'}>
      <Icon type={icon} size="medium" />
    </S.IconDiv>
    <S.InfoDiv>
      <S.ValueLabel $clickable={clickable}>{label}</S.ValueLabel>
      <S.ValueSpan $number={typeof value === 'number'}>{value}</S.ValueSpan>
    </S.InfoDiv>
  </S.CompanyInfoCellDiv>
);

export default CompanyInfoCell;
