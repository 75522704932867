import { paddings } from 'core/styles';
import {
  FunctionalColor,
  borderRadius,
  device,
  getFunctionalColor,
  getFunctionalLightColor,
  typographyMobile,
} from 'core/styles/variables';
import styled from 'styled-components';

interface ListItemOptions {
  $active?: boolean;
  $isOpen?: boolean;
  $errorCode?: boolean;
  $isSelected?: boolean;
}

interface ListItemStatusOptions {
  $status: FunctionalColor;
}

export const ThumbnailDiv = styled.div`
  display: none;
  z-index: 0;
  @media ${() => device.xl} {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 60px;
    }
  }
`;

export const ItemBodyDiv = styled.div`
  grid-area: description;
  justify-self: space-between;
  
`;

export const MowerDetailsDiv = styled.div`
  flex-direction: column;
  display: flex;
`;

export const CompanyDetailsDiv = styled.div`
  display: none;
  justify-content: center;

  @media ${() => device.xl} {
    flex-direction: column;
    display: flex;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
export const ItemTitleSpan = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  max-width: 180px;
`;

export const ItemTitleContainer = styled.span`
  font-weight: bold;
  display: flex;
  align-items: end;
  color: ${({ theme }) => theme.colors.onSurface};
  max-height: 2em;
  line-height: 1em;
  gap: ${paddings.mediumSmallPadding};
`;
export const ItemSubtextSpan = styled.span`
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.onSurface};
  max-height: 1em;
  line-height: 1em;
`;

export const TechSpecIconSpan = styled.span`
  position: relative;
  top: 3px;
  right: 1px;
`;

export const ItemSubtextSmallSpan = styled.span`
  display: block;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.onBackground};
  line-height: 1;
  font-size: ${typographyMobile.size.small};
`;

export const ItemStatusDiv = styled.div<ListItemOptions>`
  grid-area: status;
  gap: ${paddings.mediumPadding};
  display: grid;
  grid-template-columns: 1fr auto;

;
  @media ${device.xs} {
    justify-content: ${({ $errorCode }) => ($errorCode ? 'space-between' : 'end')};
  }
`;

export const ItemStatusSpan = styled.span`
  padding: ${() => paddings.tinyPadding};
  justify-self: start;
`;

export const ListItem = styled.article<ListItemOptions>`
  display: grid;
  grid-template-areas: 'description status';
  grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, $active }) => ($active ? theme.colors.secondary : 'inherit')};
  border-radius: ${({ $active }) => ($active ? `${borderRadius.default}` : 'none')};
  padding: ${paddings.mediumPadding};
  border-left: 1px solid ${({ theme }) => theme.colors.surface};
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
    cursor: pointer;
    border-radius: ${borderRadius.default};
  }
  // this is better but giving issues on unit test for now
  //@container (min-width: 490px) {
  @media ${() => device.xl} {
    grid-template-areas: 'image description status';
    grid-template-columns: 70px 1fr minmax(min-content, 250px);
    padding: ${paddings.smallPadding};

    ${ItemStatusDiv} {
      padding-right: ${() => paddings.defaultPadding};
    }
    ${ItemBodyDiv} {
      display: grid;
      gap: ${() => paddings.defaultPadding};
      grid-template-columns: 1fr 1fr;
    }
  }

  ${({ $isOpen }) =>
    $isOpen
    && `
       @media (min-width: 47.25rem) and (max-width: 58.125rem){
          display: flex;
          flex-flow: column;
          align-items: start;
          gap: ${paddings.mediumSmallPadding};
       }
    `}
`;

export const StatusCircle = styled.span<ListItemStatusOptions>`
  display: block;
  border-radius: 50%;
  border: 2px solid;
  background-size: cover;
  width: ${typographyMobile.size.small};
  height: ${typographyMobile.size.small};
  min-width: ${typographyMobile.size.small};
  min-height: ${typographyMobile.size.small};
  grid-column: 2/3;
  border-color: ${({ theme, $status }) => getFunctionalColor(theme, $status)};
  background-color: ${({ theme, $status }) => getFunctionalLightColor(theme, $status)};
`;
