import { device, paddings, typographyMobile } from 'core/styles/variables';
import styled from 'styled-components';

export const WarrantyDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.xl} {
    padding-top: ${paddings.smallPadding};
  }
`;

export const WarrantySpan = styled.span`
  font-weight: ${typographyMobile.weight.bold};
  font-size: ${typographyMobile.size.h5};
  padding-top: ${paddings.mediumPadding};

  @media ${device.xl} {
    padding-top: ${paddings.smallPadding};
  }
`;

export const WarrantyContentDiv = styled.div`
  @media ${device.xxl} {
    gap: ${paddings.mediumPadding};
  }
`;
