import { lightTheme } from 'hudskit-framework-react';
import styled, { DefaultTheme } from 'styled-components';

type Props = {
  disabled: boolean;
};

// Handle fill color
function handleFillColor(theme: DefaultTheme, disabled: boolean) {
  if (disabled) {
    return theme === lightTheme ? theme.colors.gray300 : theme.colors.gray500;
  }
  return theme.colors.brandDark;
}

// Handle stroke color
function handleStrokeColor(theme: DefaultTheme, disabled: boolean) {
  if (disabled) {
    return theme === lightTheme ? theme.colors.gray300 : theme.colors.gray500;
  }
  return theme === lightTheme ? theme.colors.gray500 : theme.colors.gray300;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const CheckBoxContainer = styled.div`
  position: relative;
  margin: 0;
  width: 20px;
  height: 20px;
`;

export const CheckBoxInput = styled.input<Props>`
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  appearance: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  margin: 0;
`;

export const Label = styled.label<Props>`
  color: ${({ theme, disabled }) => (disabled ? theme.colors.gray400 : theme.colors.onSurface)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
`;

export const CheckedRect = styled.rect<Props>`
  fill: ${({ theme, disabled }) => handleFillColor(theme, disabled)};
`;

export const TickPath = styled.path`
  stroke: ${({ theme }) => theme.colors.white};
`;

export const UncheckedRect = styled.rect<Props>`
  stroke: ${({ theme, disabled }) => handleStrokeColor(theme, disabled)};
`;
