import { borderRadius, paddings } from 'core/styles/variables';
import { Severity } from 'feature/assets/utils';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export type Size = 'sm' | 'md' | 'lg' | 'h6' | 'h5' | 'h4' | 'h3' | 'h2' | 'h1';

type Props = {
  $isSelected?: boolean;
  $link?: boolean;
  $size?: Size;
  $severity?: Severity;
  $isInfoItem?: boolean;
  $hasChildren?: boolean;
};

export const AccordionContainer = styled.div<Props>`
  margin-bottom: ${paddings.mediumPadding};
  border: 1px solid ${({ theme }) => (theme === darkTheme ? theme.colors.gray600 : theme.colors.gray300)};
  border-radius: ${borderRadius.default};
`;

export const AccordionHeaderSection = styled.section<Props>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ $isInfoItem }) => ($isInfoItem ? `${paddings.smallPadding} ${paddings.mediumPadding}` : paddings.largePadding)};
  border-radius: ${({ $isSelected }) => ($isSelected ? '16px 16px 0 0' : '16px')};
  background-color: ${({ theme }) => theme.colors.surface};
  cursor: ${({ $hasChildren }) => ($hasChildren ? 'pointer' : 'default')};
`;

export const AccordionHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  gap: ${paddings.mediumPadding};
`;

export const AccordionHeaderIcon = styled.div<Props>`
  display: ${({ $severity }) => ($severity ? 'flex' : 'none')};
`;

export const AccordionIcon = styled.div<Props>`
  display: ${({ $hasChildren }) => ($hasChildren ? 'flex' : 'none')};
`;
