import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import styled from 'styled-components';

export const ProductInfoDiv = styled.div`
  @media ${device.xl} {
    display: flex;
    gap: ${paddings.largePadding};
  }
`;
