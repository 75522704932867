import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteCustomerWidgetsMutation, useGetCustomerWidgetsQuery } from 'feature/dashboard/api';
import { arrayMoveImmutable } from 'feature/dashboard/utils';
import { dashboardWidgetsPollingInterval, numberOfCustomerWidgetsAllowed } from 'core/config';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useAppSelector } from 'core/hooks/use-app-selector';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { darkTheme } from 'hudskit-framework-react';
import { useTheme } from 'styled-components';
import { setCustomerWidgetsToBeDeleted } from '../store';

const useCustomerWidgets = () => {
  const { t } = useTranslation([InternationalizationNameSpace.Dashboard]);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const dashboardWidgetListToBeDeleted = useAppSelector((state) => state.dashboard.widgetList);
  const {
    isSuccess,
    data,
    isError,
    isFetching,
    refetch: getCustomerWidgets,
    isLoading,
  } = useGetCustomerWidgetsQuery(undefined, {
    pollingInterval: dashboardWidgetsPollingInterval,
    refetchOnMountOrArgChange: true,
  });
  const [
    deleteCustomerWidgets,
    { isError: isWidgetDeletionError, isLoading: widgetDeletionInProgress, isSuccess: widgetDeletionSuccess },
  ] = useDeleteCustomerWidgetsMutation();
  const [doneEditing, setDoneEditing] = useState(false);
  const [showErrorBanner, setShowErrorBanner] = useState(false);
  const [items, setItems] = useState(data?.widgets || []);
  const [isRetryClicked, setIsRetryClicked] = useState(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const toolTipDescription = t('DASHBOARD:dashboard.customerWidgets.companiesAllowedTooltip', {
    customerWidgetsAllowed: `${numberOfCustomerWidgetsAllowed}`,
    defaultValue: t('DASHBOARD:dashboard.error.unexpectedError'),
  });

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    setItems((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const handleDeleteAPIResp = useCallback((isAPIError: boolean) => {
    setShowErrorBanner(false);
    if (isAPIError) {
      setShowErrorBanner(true);
    }
  }, []);

  const handleRefreshClick = useCallback(() => {
    setIsRetryClicked(true);
    getCustomerWidgets();
    setShowErrorBanner(false);
  }, [getCustomerWidgets]);

  const handleEditButtonClick = useCallback(() => {
    setDoneEditing(!doneEditing);
    setShowErrorBanner(false);
  }, [doneEditing, setShowErrorBanner]);

  const getIconColor = () => {
    if (isFetching || (dashboardWidgetListToBeDeleted?.length ?? 0) === 0) {
      return darkTheme === theme ? 'gray600' : 'gray200';
    }
    return darkTheme === theme ? 'onSurface' : 'gray600';
  };

  const handleDelIconClickFn = useCallback(
    () => ((dashboardWidgetListToBeDeleted?.length ?? 0) > 0 ? setShowDeleteConfirmation(true) : undefined),
    [dashboardWidgetListToBeDeleted?.length]
  );

  useEffect(() => {
    if (isError || isSuccess) {
      setIsRetryClicked(false);
    }
  }, [data, isError, isRetryClicked, isSuccess]);

  useEffect(() => {
    if (doneEditing) {
      dispatch(setCustomerWidgetsToBeDeleted([]));
    }
  }, [dispatch, doneEditing]);

  const deleteCustomerWidgetFn = useCallback(() => {
    if (isFetching) return;
    deleteCustomerWidgets({ companyIds: dashboardWidgetListToBeDeleted ?? [] });
    setShowDeleteConfirmation(false);
  }, [dashboardWidgetListToBeDeleted, deleteCustomerWidgets, isFetching]);

  useEffect(() => {
    if (widgetDeletionSuccess) {
      setShowDeleteConfirmation(false);
      dispatch(setCustomerWidgetsToBeDeleted([]));
    }
  }, [dispatch, widgetDeletionSuccess]);

  useEffect(() => {
    if (isWidgetDeletionError) {
      setShowDeleteConfirmation(false);
      setShowErrorBanner(true);
    }
  }, [isWidgetDeletionError, setShowErrorBanner]);

  return {
    isSuccess,
    data,
    isError,
    isFetching,
    getCustomerWidgets,
    isLoading,
    showErrorBanner,
    setShowErrorBanner,
    items,
    onSortEnd,
    handleDeleteAPIResp,
    handleRefreshClick,
    doneEditing,
    handleEditButtonClick,
    toolTipDescription,
    isRetryClicked,
    deleteCustomerWidgetFn,
    showDeleteConfirmation,
    widgetDeletionInProgress,
    setShowDeleteConfirmation,
    getIconColor,
    widgetDeletionSuccess,
    handleDelIconClickFn,
  };
};

export default useCustomerWidgets;
