import { useCallback, ReactNode, KeyboardEvent } from 'react';
import * as S from './styled';

export type DropdownMenuItemProps<T> = {
  value: T;
  key: string;
  label: string;
};

type DropdownMenuProps<T> = {
  items: DropdownMenuItemProps<T>[];
  onSelected: (value: DropdownMenuItemProps<T>) => void;
  title?: string;
  selected?: DropdownMenuItemProps<T>;
};

type MenuItemProps<T> = {
  value: T;
  children: ReactNode;
  id: string;
  selected?: boolean;
  onClick: (value: T) => void;
};
const MenuItem = <T,>({
  onClick,
  children,
  value,
  id,
  selected = false
}: MenuItemProps<T>) => {
  const onClickHandler = useCallback(() => {
    onClick?.(value);
  }, [onClick, value]);

  const onKeyUpHandler = useCallback(
    (event: KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        onClick?.(value);
      }
    },
    [onClick, value]
  );
  return (
    <S.MenuLi key={id} $selected={selected}>
      <S.MenuSpan onClick={onClickHandler} onKeyUp={onKeyUpHandler}>
        {children}
      </S.MenuSpan>
    </S.MenuLi>
  );
};

const DropdownMenu = <T,>({
  items,
  onSelected,
  title = undefined,
  selected
}: DropdownMenuProps<T>) => {
  const onClickHandler = useCallback(
    (value: DropdownMenuItemProps<T>) => {
      onSelected(value);
    },
    [onSelected]
  );

  return (
    <S.MenuUl>
      {title && (
        <S.MenuLi key="title">
          <S.TitleSpan>{title}</S.TitleSpan>
        </S.MenuLi>
      )}
      {items.map((item) => (
        <MenuItem
          selected={item.key === selected?.key}
          onClick={onClickHandler}
          value={item}
          id={item.key}
          key={item.key}
        >
          {item.label}
        </MenuItem>
      ))}
    </S.MenuUl>
  );
};

export default DropdownMenu;
