import { device, headerHeight, paddings } from 'core/styles/variables';
import styled, { createGlobalStyle } from 'styled-components';

export const ListItemsDiv = styled.div`
  flex-grow: 1;
  padding-bottom: 80px;
  @media ${device.lg} {
    min-height: calc(100vh - ${() => headerHeight} - ${() => paddings.defaultPadding});
    padding-bottom: 0;
  }
`;

export const ListCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${() => paddings.defaultPadding};
`;

export const GlobalStyle = createGlobalStyle<{ $hideScroll: boolean }>`
  :root {
    overflow-y: ${({ $hideScroll }) => ($hideScroll ? 'hidden' : 'auto')};
  }
`;
