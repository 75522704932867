import { useGetSearchAvailableFiltersQuery } from 'feature/assets/api';
import { useAppSelector } from 'core/hooks/use-app-selector';
import {
  clearCompanyNameFilters,
  clearFilters,
  setFilterByCompaniesOnActiveSearch,
  setFilterByCompanyCountryOnActiveSearch,
  setFilterByModelsOnActiveSearch,
} from 'feature/assets/store';
import { Button, Loader, OptionType } from 'hudskit-framework-react';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useStatusFilter } from 'feature/assets/hooks/use-status-filter';
import { useGetCustomerWidgetsQuery } from 'feature/dashboard/api';
import { serviceContractsEnabled } from 'core/config';
import StatusFilter from '../StatusFilter';
import FilterCard from '../FilterCard';
import FilterSearch from '../FilterSearch';
import * as S from './styled';
import ServiceContractStatusFilter from '../ServiceContractStatusFilter';

const Filters = () => {
  const filters = useAppSelector((state) => state.assets.activeListFilters);
  const { data, isLoading } = useGetSearchAvailableFiltersQuery({ ...filters });
  const {
    secondaryStatusList,
    operationalStatusList,
    activeSecondaryStatusFilters,
    activeOperationalStatusFilters,
    secondaryStatusTagClickHandler,
    operationalStatusTagClickHandler,
  } = useStatusFilter();
  const selectedModels = useAppSelector((state) => state.assets.activeListFilters?.models);
  const selectedCountries = useAppSelector((state) => state.assets.activeListFilters?.companyCountries);
  const selectedCompanyIds = useAppSelector((state) => state.assets.activeListFilters?.diamGroupId);
  const { isSuccess, data: favoriteCompanies } = useGetCustomerWidgetsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });
  const dispatch = useAppDispatch();
  const onClearFiltersClick = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets, InternationalizationNameSpace.Dashboard]);

  const selectedOption = (selected?: string[]) => {
    const uniqueSelected = Array.from(new Set(selected));
    return uniqueSelected.map((optionValue) => ({
      label: optionValue,
      value: optionValue,
    }));
  };

  const companiesOptions = useMemo<OptionType[]>(
    () =>
      favoriteCompanies?.widgets.map((widget) => ({
        label: widget.companyName,
        value: widget.companyId,
      })) ?? [],
    [favoriteCompanies?.widgets]
  );

  return (
    <form>
      <S.ClearButtonWrapperDiv id="clearFilter">
        <Button onClick={onClearFiltersClick} type="button" variant="secondary" disabled={!filters}>
          {t('ASSETS:filters.buttons.clearFilters')}
        </Button>
      </S.ClearButtonWrapperDiv>
      <S.FiltersDiv>
        <StatusFilter
          label="operationalStatus"
          tagTitle="operationalStatus"
          statusList={operationalStatusList}
          activeStatus={activeOperationalStatusFilters}
          statusTagClick={operationalStatusTagClickHandler}
        />
        <StatusFilter
          label="activity"
          tagTitle="secondaryStatus"
          statusList={secondaryStatusList}
          activeStatus={activeSecondaryStatusFilters}
          statusTagClick={secondaryStatusTagClickHandler}
        />
        {serviceContractsEnabled && <ServiceContractStatusFilter />}
      </S.FiltersDiv>
      {isLoading && (
        <FilterCard title="">
          <Loader size="small" center />
        </FilterCard>
      )}

      {data?.models && (
        <FilterSearch
          title={t('ASSETS:labels.models')}
          id="companies-models"
          selectedFilters={selectedModels}
          filterAction={setFilterByModelsOnActiveSearch}
          options={selectedOption([...(data?.models ?? []), ...(filters?.models ?? [])])}
        />
      )}

      {isSuccess && favoriteCompanies?.widgets.length > 0 && (
        <S.CustomersInFocusFilterWrapperDiv $isLoading={isLoading}>
          <FilterSearch
            title={t('DASHBOARD:dashboard.customerWidgets.headerTitle')}
            id="favorite-companies"
            selectedFilters={selectedCompanyIds}
            filterAction={(diamGroupIds: string[]) => {
              dispatch(clearCompanyNameFilters());
              if (diamGroupIds?.length > 0) {
                dispatch(clearCompanyNameFilters());
                dispatch(
                  setFilterByCompaniesOnActiveSearch({
                    diamGroupIds,
                  })
                );
              }
            }}
            options={companiesOptions}
          />
        </S.CustomersInFocusFilterWrapperDiv>
      )}

      {data?.countries && data.countries.length > 1 && (
        <FilterSearch
          title={t('ASSETS:labels.countries')}
          id="companies-countries"
          selectedFilters={selectedCountries}
          filterAction={setFilterByCompanyCountryOnActiveSearch}
          options={selectedOption(data?.countries)}
        />
      )}
    </form>
  );
};

export default Filters;
