import { SelectField, OptionType } from 'hudskit-framework-react';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { setFilterByCompaniesOnActiveSearch, setSelectedAsset } from 'feature/assets/store';

import * as S from './styled';

type Props = {
  id: string;
  title: string;
  filterAction: any;
  options: OptionType[];
  selectedFilters?: string[];
};

const FilterSearch = ({
  id,
  title,
  filterAction,
  options,
  selectedFilters,
}: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  const isCustomersInFocus = id === 'favorite-companies';
  const selectOptions = useMemo<OptionType[]>(
    () =>
      options.map((v) => {
        const option: OptionType = {
          label: v.label,
          value: v.value,
        };
        return option;
      }),
    [options]
  );

  const toggleSectionHandler = useCallback(
    (filter: string) => {
      dispatch(setSelectedAsset(undefined));
      const currentfilters = selectedFilters ?? [];
      if (currentfilters.includes(filter)) {
        dispatch(filterAction(currentfilters.filter((s) => s !== filter)));
      } else {
        dispatch(filterAction([...currentfilters, filter]));
      }
    },
    [dispatch, filterAction, selectedFilters]
  );

  const toggleCustomersInFocusHandler = useCallback(
    (filter: string) => {
      dispatch(setSelectedAsset(undefined));
      const currentfilters = selectedFilters ?? [];
      if (currentfilters.includes(filter)) {
        dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: currentfilters.filter((s) => s !== filter) }));
      } else {
        dispatch(setFilterByCompaniesOnActiveSearch({ diamGroupIds: [...currentfilters, filter] }));
      }
    },
    [dispatch, selectedFilters]
  );

  return (
    <S.SelectFieldDiv $active={filterAction}>
      <SelectField
        enableSearch
        id={id}
        label={title}
        noMatchText={t('ASSETS:fieldLabels.noMatchText')}
        onSelectValue={isCustomersInFocus ? toggleCustomersInFocusHandler : toggleSectionHandler}
        multiple
        defaultValues={selectedFilters ?? []}
        options={selectOptions}
      />
    </S.SelectFieldDiv>
  );
};

export default FilterSearch;
