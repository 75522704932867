import { AssetDetailsResponse, AssetInfoItemsResponse } from '@uptime-bff/api-contract';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useCallback, useEffect } from 'react';
import { updateAssetInfoItemInList } from '../store';

export const useUpdateAssetListItem = (asset?: AssetDetailsResponse, assetInfoItem?: AssetInfoItemsResponse) => {
  const dispatch = useAppDispatch();

  const updateAssetListInfoItem = useCallback((assetDetail: AssetDetailsResponse, infoItem: AssetInfoItemsResponse) => {
    dispatch(updateAssetInfoItemInList({ asset: assetDetail, infoItem }));
  }, [dispatch]);

  useEffect(() => {
    if (asset && assetInfoItem) {
      updateAssetListInfoItem(asset, assetInfoItem);
    }
  }, [asset, assetInfoItem, updateAssetListInfoItem]);

  return { updateAssetListInfoItem };
};
