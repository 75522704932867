import { NavBottom } from 'hudskit-framework-react';
import { useMemo } from 'react';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import * as S from './styled';
import { getUptimeMenu } from '../NavMenu/menu';

const MobileNavMenu = () => {
  const navMenu = useMemo(() => getUptimeMenu(), []);

  const { activeMenuId, onClickHandler } = useUptimeNavigation(navMenu.menu);
  return (
    <S.MobileBottomDiv>
      <NavBottom
        list={navMenu.menu}
        onClick={onClickHandler}
        activeNavItemId={activeMenuId}
      />
    </S.MobileBottomDiv>
  );
};

export default MobileNavMenu;
