import { device, paddings } from 'core/styles/variables';
import { zModal } from 'core/styles/z-index';
import styled from 'styled-components';

type CompaniesFiltersProps = {
  $isOpen: boolean;
};

export const ListItemCenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${() => paddings.defaultPadding};
`;
export const CompanyContainerDiv = styled.div`
  display: flex;
`;

export const SortMenuSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CompaniesListSection = styled.section`
  display: flex;
  padding-bottom: 80px;
  
  @media ${device.lg} {
    padding-bottom: 0;
  }
`;

export const CompaniesListFilterDiv = styled.div<CompaniesFiltersProps>`
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  max-width: ${({ $isOpen }) => ($isOpen ? '200px' : 0)};
  min-width: ${({ $isOpen }) => ($isOpen ? '200px' : 0)};
  z-index: 0;
  transition: all 250ms ease-in-out;
`;

export const CompaniesListDiv = styled.div`
  width: 100%;
`;

export const BannerDiv = styled.div`
position: fixed;
bottom: ${() => paddings.smallPadding};
left: 50%;
width: 100%;
transform: translate(-50%, 0);
z-index: ${() => zModal};
`;
