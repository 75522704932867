import {
  AssetListInfoItems,
  InfoItemEnum,
  InfoItems,
  InfoItemsActions,
  InfoItemsAssetMetaData,
  MowerStatus,
  OperationalStatusEnum,
  SecondaryRoboticMowerSubStatusEnum,
  SecondaryStatusEnum,
} from '@uptime-bff/api-contract';
import {
  InternationalizationBackendNameSpace,
  InternationalizationNameSpace,
} from 'core/models/internationalization-namespace';
import { displayDateTimeFormat, FunctionalColor } from 'core/styles/variables';
import i18next from 'i18next';
import { DateTime } from 'luxon';

export interface ProbableCause {
  cause: string;
  action: string;
}

export type Severity = 50 | 40 | 30 | 20 | 10 | undefined;

export const getStatusColor = (status: MowerStatus): FunctionalColor => {
  switch (status) {
    case MowerStatus.Mowing:
      return 'success';
    case MowerStatus.Error:
      return 'error';
    case MowerStatus.Paused:
    case MowerStatus.Stopped:
      return 'caution';
    case MowerStatus.MissingArea:
    case MowerStatus.MissingInstallation:
    case MowerStatus.Unknown:
      return 'other';
    default:
      return 'progress';
  }
};

export const formatDate = (dateString: string | undefined) =>
  (dateString ? new Date(dateString).toISOString().split('T')[0] : undefined);

export const statusBadgeColor = (status?: OperationalStatusEnum) => {
  switch (status) {
    case OperationalStatusEnum.OK:
    case OperationalStatusEnum.OK_WITH_INFO:
      return 'success';
    case OperationalStatusEnum.WARNING:
      return 'caution';
    case OperationalStatusEnum.CRITICAL:
      return 'error';
    case OperationalStatusEnum.UNKNOWN:
      return 'other';
    default:
      return undefined;
  }
};

export const getSeverityIcon = (severity: Severity) => {
  switch (severity) {
    case 50:
      return 'notification.error_outlined';
    case 40:
    case 30:
      return 'other.warning';
    case 20:
    case 10:
      return 'notification.info_outlined';
    default:
      return 'none';
  }
};

export const shouldDisplayStatusIcon = (status?: OperationalStatusEnum) =>
  status && status !== OperationalStatusEnum.OK && status !== OperationalStatusEnum.OK_WITH_INFO;

export const shouldDisplayGreenDot = (status?: OperationalStatusEnum) =>
  status && (status === OperationalStatusEnum.OK || status === OperationalStatusEnum.OK_WITH_INFO);

export const getDate = (date?: Date): string => {
  if (!date) {
    return '';
  }

  return String(DateTime.fromISO(String(date)).toFormat(displayDateTimeFormat));
};

export const mapInfoItemTitleLanguageKey = (infoItemId?: InfoItemEnum) => {
  if (!infoItemId) {
    return '';
  }
  const textKey = `statusTranslation:status.infoItems.${infoItemId}.text`;
  const contentKey = `statusTranslation:status.infoItems.${infoItemId}.content`;
  const baseKey = `statusTranslation:status.infoItems.${infoItemId}`;

  return i18next.exists(textKey) && i18next.exists(contentKey) ? textKey : baseKey;
};

export const mapInfoItemMetaDate = (localTime?: Date, utcTime?: Date) => {
  if (localTime) {
    return DateTime.fromISO(String(localTime)).toFormat(displayDateTimeFormat);
  }
  if (utcTime) {
    return DateTime.fromISO(String(utcTime), { zone: 'utc' }).toFormat(displayDateTimeFormat);
  }
  return undefined;
};

const mapInfoItemErrorDescription = (action?: InfoItemsActions, errorCode?: number) => {
  const langKey = `errorCodes.${action?.productIdentification}.${errorCode}.name`;
  const translation = i18next.t(`${InternationalizationBackendNameSpace.ErrorCode}:${langKey}`);

  if (translation?.toString()?.indexOf(langKey) === -1) {
    return translation;
  }
  return i18next.t(
    `${InternationalizationBackendNameSpace.Status}:status.infoItems.placeholders.MOWER_ERROR_NO_DESCRIPTION`
  );
};

export const mapSecondaryStatus = (status: SecondaryStatusEnum): string =>
  i18next.t(`status.secondaryStatus.${status}`);

export const mapSecondarySubStatus = (substatus: SecondaryRoboticMowerSubStatusEnum): string =>
  i18next.t(`status.secondaryStatus.additionalStatus.${substatus}`);

export const mapLastReportedStatus = (
  lastSecondaryStatus?: SecondaryStatusEnum,
  lastSecondarySubStatus?: SecondaryRoboticMowerSubStatusEnum
): string => {
  if (!lastSecondaryStatus) {
    return '-';
  }
  return lastSecondarySubStatus
    ? `${mapSecondaryStatus(lastSecondaryStatus)} (${mapSecondarySubStatus(lastSecondarySubStatus)})`
    : mapSecondaryStatus(lastSecondaryStatus);
};

export const mapMowerChargingNextStartDateTitle = (
  baseLangKey: string,
  utcTimestampOn: Date,
  formattedDate?: string
) => {
  const minutes = Math.floor(DateTime.fromJSDate(utcTimestampOn).diffNow('minutes').minutes);
  if (minutes >= 0 && minutes < 60) {
    return i18next.t(`${baseLangKey}.inMinutes`, {
      minutes,
    });
  }

  return i18next.t(`${baseLangKey}.atDate`, {
    formattedDate: formattedDate ?? '-',
  });
};

export const mapMowerAbandonedAreaInfoItem = (missionId?: number, assetMeta?: InfoItemsAssetMetaData[]) => ({
  areaName: assetMeta?.find((area) => area.listItemId === missionId)?.name ?? '-',
});

export const mapInfoItemErrorAndDescription = (action?: InfoItemsActions, errorCode?: number, formattedDate?: string) =>
  (errorCode !== 0
    ? `[${errorCode}] ${mapInfoItemErrorDescription(action, errorCode)} ${formattedDate ?? ''}`
    : `${mapInfoItemErrorDescription(action, errorCode)}`);

// mapping infoItem titles

export const mapInfoItemTitle = (
  action?: InfoItemsActions,
  infoItem?: InfoItems | AssetListInfoItems,
  assetMeta?: InfoItemsAssetMetaData[]
) => {
  const langKey = mapInfoItemTitleLanguageKey(infoItem?.infoItemId);
  const erroCode = infoItem?.errorCode;
  const formattedDate = mapInfoItemMetaDate(infoItem?.localTimestampOn, infoItem?.utcTimestampOn);

  switch (infoItem?.infoItemId) {
    case InfoItemEnum.MAINTENANCE_REMINDER_DUE_ON_HOURS:
      return i18next.t(langKey, { reminderHours: infoItem.reminderHours });
    case InfoItemEnum.MAINTENANCE_REMINDER_DUE_ON_DATE:
      return i18next.t(langKey, { formattedDate: infoItem.reminderDate ? getDate(infoItem.reminderDate) : '-' });
    case InfoItemEnum.MOWER_ERROR_AUTO_RESOLVABLE:
    case InfoItemEnum.MOWER_ERROR_CONFIRMABLE:
    case InfoItemEnum.MOWER_FATAL_ERROR:
    case InfoItemEnum.MOWER_WARNING_BY_MESSAGE:
      return mapInfoItemErrorAndDescription(action, erroCode, formattedDate);
    case InfoItemEnum.MOWER_PARKED_NEXT_START_TIME:
    case InfoItemEnum.MOWER_MANUALLY_CONTROLLED_SINCE:
    case InfoItemEnum.MOWER_TURNED_OFF:
      return i18next.t(langKey, { formattedDate });
    case InfoItemEnum.MOWER_CHARGING_NEXT_START_TIME:
      return (
        infoItem?.utcTimestampOn && mapMowerChargingNextStartDateTitle(langKey, infoItem?.utcTimestampOn, formattedDate)
      );
    case InfoItemEnum.MOWER_DISCONNECTED_SINCE:
      return i18next.t(langKey, {
        formattedDate: infoItem?.utcTimestampOn ? mapInfoItemMetaDate(undefined, infoItem?.utcTimestampOn) : '-',
      });
    case InfoItemEnum.MOWER_DISCONNECTED_LAST_SECONDARY_STATUS:
      return i18next.t(langKey, { lastReportedStatus: infoItem?.lastSecondaryStatus });
    case InfoItemEnum.MOWER_MOW_OVERRIDE_END_TIME:
    case InfoItemEnum.MOWER_MOW_OVERRIDE_START_TIME:
      return i18next.t(langKey, {
        formattedDateOrMinus: mapInfoItemMetaDate(infoItem.localTimestampOn, infoItem.utcTimestampOn) ?? '-',
      });
    case InfoItemEnum.MOWER_ABANDONED_AREA:
      return i18next.t(langKey, mapMowerAbandonedAreaInfoItem(infoItem.missionId, assetMeta));
    default:
      break;
  }
  return i18next.t(langKey) === langKey ? langKey : i18next.t(langKey);
};

// mapping InfoItem content

export const getErrorProbableCauses = (
  ns: InternationalizationNameSpace,
  action?: InfoItemsActions,
  articleNumber?: string,
  errorCode?: number
) => {
  const identifier = action?.type ? `${action?.productIdentification}` : articleNumber;
  const probableCauses = i18next.t(
    `${ns}:errorCodes.${identifier}.${errorCode}.probableCause`
  ) as unknown as ProbableCause[];
  if (probableCauses instanceof Array && probableCauses.length > 0) {
    return probableCauses;
  }
  return [];
};

export const mapErrorCodeInfoItemContent = (
  ns: InternationalizationNameSpace,
  includeDescription: boolean,
  action?: InfoItemsActions,
  articleNumber?: string,
  errorCode?: number
) => {
  const identifier = action?.type ? `${action?.productIdentification}` : articleNumber;
  const lines: string[] = [];
  if (includeDescription) {
    const contentLangKey = `errorCodes.${identifier}.${errorCode}.description`;
    const translatedContent = i18next.t(`${ns}:${contentLangKey}`);
    if (translatedContent?.toString()?.indexOf(contentLangKey) === -1) {
      lines.push(translatedContent.toString());
    }
  }

  const probableCauses = getErrorProbableCauses(ns, action, articleNumber, errorCode);
  const addLinesWithPrefix = (text: string, prefix: string) => {
    const linesToAdd = text.split('\n');
    lines.push(`${i18next.t(prefix)}: ${linesToAdd.join(' ')}`);
  };
  if (probableCauses.length > 0) {
    probableCauses.forEach((item) => {
      if (lines.length > 0) {
        lines.push('');
      }
      addLinesWithPrefix(item.cause, 'ASSETS:details.infoItems.cause');
      addLinesWithPrefix(item.action, 'ASSETS:details.infoItems.action');
    });
  }
  return lines.length > 0 ? lines : undefined;
};

export const mapErrorContent = (action?: InfoItemsActions, errorCode?: number, articleNumber?: string) =>
  mapErrorCodeInfoItemContent(InternationalizationNameSpace.ErrorCode, false, action, articleNumber, errorCode);

export const mapInfoItemContentLangKey = (infoItemId: InfoItemEnum) =>
  `statusTranslation:status.infoItems.${infoItemId}.content`;

export const mapFotaVersionDependentContent = (id: InfoItemEnum, fotaVersion?: string) => {
  const base = `statusTranslation:status.infoItems.${id}.content`;
  const fotaVersionInfo = i18next.t(`statusTranslation:status.infoItems.${id}.content.versionInfo`, {
    version: fotaVersion,
  });
  const content = i18next.t(`${base}.text`);
  return !fotaVersion ? content : [content, fotaVersionInfo].join(' ');
};

export const getInfoItemContent = (infoItem: InfoItems, articleNumber?: string, action?: InfoItemsActions) => {
  const { infoItemId, fotaVersion, errorCode } = infoItem;
  switch (infoItemId) {
    case InfoItemEnum.MOWER_ERROR_AUTO_RESOLVABLE:
    case InfoItemEnum.MOWER_ERROR_CONFIRMABLE:
    case InfoItemEnum.MOWER_FATAL_ERROR:
    case InfoItemEnum.MOWER_WARNING_BY_MESSAGE:
      if (errorCode) {
        return mapErrorContent(action, errorCode, articleNumber);
      }
      break;
    case InfoItemEnum.HAPP_BLE_NEVER_CONNECTED:
    case InfoItemEnum.HAPP_DATA_TO_UPLOAD:
    case InfoItemEnum.MOWER_EPOS_WORK_AREA_NOT_IDENTIFIED:
    case InfoItemEnum.MOWER_ABANDONED_AREA:
    case InfoItemEnum.MOWER_ACTION_REQUIRED_BUTTON_RELEASE:
    case InfoItemEnum.MOWER_ACTION_REQUIRED_ENTER_PIN:
    case InfoItemEnum.MOWER_CHARGING_NEXT_START_TIME:
    case InfoItemEnum.MOWER_PARKED_NEXT_START_TIME:
    case InfoItemEnum.MOWER_MOW_OVERRIDE_END_TIME:
    case InfoItemEnum.MOWER_MOW_OVERRIDE_START_TIME:
    case InfoItemEnum.MOWER_GEOFENCE_DISABLED:
    case InfoItemEnum.MOWER_FOTA_FIRMWARE_UPDATE_REQUIRED:
    case InfoItemEnum.MOWER_EPOS_INSTALLATION_INCOMPLETE:
    case InfoItemEnum.MOWER_LONA_COLLECTING_MAP_DATA:
    case InfoItemEnum.MOWER_MODE_POI:
    case InfoItemEnum.MOWER_MODE_HOME:
      return i18next.t(mapInfoItemContentLangKey(infoItemId));
    case InfoItemEnum.MOWER_FOTA_APPROVAL_PENDING:
    case InfoItemEnum.MOWER_FOTA_INSTALLATION_PENDING:
    case InfoItemEnum.MOWER_FOTA_INSTALLING:
      return mapFotaVersionDependentContent(infoItemId, fotaVersion);
    default:
      break;
  }
  return null;
};
