import { AssetListStatus as AssetStatus } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { statusBadgeColor } from 'feature/assets/utils';
import * as S from './styled';

type Props = {
  status: AssetStatus;
  displaySuccess?: boolean;
};

const AssetListStatus = ({ status, displaySuccess = false }:Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Status);
  const getSecondaryStatusTranslation = (key: string) => t(`statusTranslation:status.secondaryStatus.${key}`);

  const { secondaryStatus, secondarySubStatus, operationalStatus } = status;
  const subStatusText = `additionalStatus.${secondarySubStatus}`;

  const translatedSecondaryStatus = secondaryStatus ? getSecondaryStatusTranslation(secondaryStatus) : '';
  const translatedSubStatus = secondarySubStatus ? ` | ${getSecondaryStatusTranslation(subStatusText)}` : '';

  return (
    <S.AssetListStatusDiv>
      {displaySuccess && (
        <S.StatusCircle data-testid="status-circle" $color={statusBadgeColor(operationalStatus)} />
      )}
      <S.StatusTextSpan>{`${translatedSecondaryStatus}${translatedSubStatus}`}</S.StatusTextSpan>
    </S.AssetListStatusDiv>
  );
};

export default AssetListStatus;
