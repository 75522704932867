import MapsMarker from 'core/components/MapsMarker';
import { OperationalStatusEnum } from '@uptime-bff/api-contract';
import { statusBadgeColor } from 'feature/assets/utils';
import * as S from './styled';
import AssetThumbnail from '../AssetThumbnail';

type Props = {
  map: google.maps.Map;
  location?: {
    lat: number;
    lng: number;
  };
  name: string;
  thumbnail: string;
  assetStatusSeverity?: OperationalStatusEnum;
};
const AssetListMarker = ({ map, location, name, thumbnail, assetStatusSeverity }: Props) => {
  const statusColor = statusBadgeColor(assetStatusSeverity) ?? 'other';

  return (
    // @ts-ignore
    <MapsMarker map={map} position={location}>
      {thumbnail && statusColor ? (
        <AssetThumbnail imageAlt={name} imageSrc={thumbnail} color={statusColor} marker />
      ) : (
        <S.StatusCircle color={statusColor} />
      )}
    </MapsMarker>
  );
};

export default AssetListMarker;
