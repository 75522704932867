import ErrorCard from 'core/components/ErrorCard';
import { useGetAssetByIdQuery } from 'feature/assets/api';
import { Loader, NavMenu as TabMenu } from 'hudskit-framework-react';
import { Outlet, useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { getAssetTabMenu } from 'core/layout/NavMenu/menu';
import { useUptimeNavigation } from 'core/hooks/use-uptime-navigation';
import { DateTime } from 'luxon';
import { displayDateTimeFormat } from 'core/styles/variables';
import * as S from './styled';
import SingleAssetMap from '../SingleAssetMap';
import AssetThumbnailInfo from '../AssetThumbnailInfo';
import AssetDetailsAddressline from '../AssetDetailsAddressline';
import AssetRefinedStatus from '../AssetRefinedStatus/AssetRefinedStatus';
import AssetInfoItem from '../AssetInfoItem/AssetInfoItem';

const AssetDetails = () => {
  const { id } = useParams();
  const { isError, isFetching, isSuccess, data: asset } = useGetAssetByIdQuery(id ?? '');
  const tabMenu = useMemo(() => getAssetTabMenu(asset?.hasServiceContract), [asset?.hasServiceContract]);
  const { activeTabId, onClickHandler } = useUptimeNavigation(tabMenu.menu);
  const assetUpdatedOn = asset?.assetPosition?.updatedOn
    && DateTime.fromISO(asset?.assetPosition?.updatedOn).toFormat(displayDateTimeFormat);
  const [hasAddress, setHasAddress] = useState(false);
  const setHasAddressHandler = useCallback((addressStatus: boolean): void => {
    setHasAddress(addressStatus);
  }, []);
  return (
    <>
      {isFetching && (
        <S.LoaderDiv>
          <Loader center size="large" />
        </S.LoaderDiv>
      )}
      {isError && <ErrorCard />}
      {!isFetching && isSuccess && !isError && asset && (
        <>
          {asset.status && asset.thumbnail && (
            <SingleAssetMap
              location={asset.assetPosition?.location}
              assetName={asset.assetName}
              assetThumbnail={asset.thumbnail}
              assetStatusSeverity={asset.status.operationalStatus}
            />
          )}
          <S.InfoDiv>
            <AssetDetailsAddressline assetPosition={asset.assetPosition} setHasAddress={setHasAddressHandler} />
            {assetUpdatedOn && hasAddress && <S.TimestampDiv>{assetUpdatedOn}</S.TimestampDiv>}
            <AssetThumbnailInfo asset={asset} />
            {asset.status?.secondaryStatusTranslationKey && (
              <AssetRefinedStatus assetStatus={asset.status} />
            )}
            <AssetInfoItem assetdetails={asset} />
            <S.TabMenuDiv>
              <TabMenu menu={tabMenu.menu} onClick={onClickHandler} activeMenuItemId={activeTabId} />
            </S.TabMenuDiv>
            <S.InfoCardsDiv>
              <Outlet />
            </S.InfoCardsDiv>
          </S.InfoDiv>
        </>
      )}
    </>
  );
};

export default AssetDetails;
