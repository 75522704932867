import { statusWidgetPollingInterval } from 'core/config/firebase/remote-config';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useGetCeoraCountQuery } from 'feature/dashboard/api';
import { useStatusChartData } from 'feature/dashboard/hooks/use-status.chart-data';
import { Card, Icon, Loader } from 'hudskit-framework-react';
import { useTheme } from 'styled-components';
import { RoutePaths } from 'core/models';
import { clearFilters, setFilterByModelsOnActiveSearch } from 'feature/assets/store';
import { useCallback } from 'react';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useNavigate } from 'react-router-dom';
import ErrorCard from 'core/components/ErrorCard';
import DoughtnutChart from '../DoughnutChart/DoughnutChart';
import LegendItem from '../LegendItem';
import * as S from './styled';

const CeoraStatusWidget = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Dashboard]);
  const { data, isSuccess, isLoading, isError, refetch: getCeoraCount } = useGetCeoraCountQuery(undefined, {
    pollingInterval: statusWidgetPollingInterval,
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { chartData, total } = useStatusChartData({ data, theme });

  const goToCeoras = useCallback(() => {
    dispatch(clearFilters());
    dispatch(setFilterByModelsOnActiveSearch(data?.model ?? []));
    navigate(RoutePaths.Assets);
  }, [data?.model, dispatch, navigate]);

  if (total === 0 || total === undefined) {
    return null;
  }

  return (
    <Card>
      <S.WidgetContentDiv>
        <S.HeaderDiv
          title={t('DASHBOARD:dashboard.redirectMessage')}
          onClick={goToCeoras}
          id="dashboardCardTitle"
          data-testid="ceoraHeaderTitle"
        >
          <S.HeadingDiv>
            <Icon type="product.ceora_top" />
            <S.HeadingText>
              {t('DASHBOARD:dashboard.ceora')}
              &#8482;
            </S.HeadingText>
          </S.HeadingDiv>
          <Icon type="action.arrow_right" />
        </S.HeaderDiv>
        {isError && <ErrorCard onRetryClick={getCeoraCount} />}
        {isLoading && <Loader size="medium" center padding />}
        {isSuccess && chartData && (
          <S.PieChartWithLegendDiv>
            <S.PieChartWithLegendColumnDiv>
              <DoughtnutChart model={data.model} total={total} chartData={chartData} goToAssetList={goToCeoras} />
            </S.PieChartWithLegendColumnDiv>
            <S.PieChartWithLegendColumnDiv>
              {chartData.map((value) => (
                <LegendItem
                  key={value.name}
                  keyName={value.key}
                  text={value.name}
                  num={value.value}
                  model={data.model}
                  color={value.color}
                />
              ))}
            </S.PieChartWithLegendColumnDiv>
          </S.PieChartWithLegendDiv>
        )}
      </S.WidgetContentDiv>
    </Card>
  );
};

export default CeoraStatusWidget;
