import Clarity from '@microsoft/clarity';
import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { setCookiesAccepted, setShowCookieBanner } from 'core/layout/reducer';
import { useAppDispatch } from './use-app-dispatch';

const useUptimeCookies = () => {

  const dispatch = useAppDispatch();

  const storeCookiesInLocalStorage = useCallback((storageKey: string, acceptedCookies: boolean) => {
    localStorage.setItem(
      storageKey,
      JSON.stringify({
        acceptedCookies,
        consentDate: DateTime.local().toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
      })
    );
  }, []);

  const onAcceptAllHandler = useCallback(() => {
    storeCookiesInLocalStorage('userCookiesConsent', true);
    Clarity.consent();
    dispatch(setCookiesAccepted(true));
    dispatch(setShowCookieBanner(false));

  }, [dispatch, storeCookiesInLocalStorage]);

  const onRejectAllHandler = useCallback(() => {
    storeCookiesInLocalStorage('userCookiesConsent', false);
    Clarity.consent(false);
    dispatch(setCookiesAccepted(false));
    dispatch(setShowCookieBanner(false));

  }, [dispatch, storeCookiesInLocalStorage]);

  return { onAcceptAllHandler, onRejectAllHandler };
};

export default useUptimeCookies;
