import { paddings } from 'core/styles';
import { typographyDesktop } from 'core/styles/variables';
import styled from 'styled-components';

export const InfoItemDescription = styled.div`
  padding: 0 ${paddings.largePadding} ${paddings.largePadding} 48px;
  font-size: ${typographyDesktop.size.tiny};
`;

export const AccordionSpan = styled.span`
  white-space: break-spaces;
  font-size: ${typographyDesktop.size.tiny};
  margin: 0;
  line-height: 1.3;
  color: ${({ theme }) => theme.colors.onSurface};
`;

export const Test = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${paddings.smallPadding};
`;
