import styled from 'styled-components';

export const RefinedStatusBadgeIcon = styled.div`
  position: relative;
  top: 3px;
`;

export const AdmirationMarkIconSpan = styled.span`
    display: inline-block;
    transform: rotate(180deg);
    position: relative;
    bottom: 5px;
`;
