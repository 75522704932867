import { paddings } from 'core/styles';
import { boxShadows } from 'hudskit-framework-react';
import styled, { css } from 'styled-components';

type Props = {
  $icon?: boolean;
  $copyIcon?: boolean;
  $serviceContract?: boolean;
};

const InfoCellCss = css<Props>`
  display: flex;
  align-items: ${({ $serviceContract }) => ($serviceContract ? 'end' : 'center')};
  gap: ${({ $serviceContract }) => ($serviceContract ? `${paddings.tinyPadding}` : `${paddings.smallPadding}`)};
  border-radius: 12px;
`;

export const InfoCellDiv = styled.div<Props>`
  ${InfoCellCss}
  padding: ${paddings.smallPadding} 0;
`;
export const InfoCellAnchor = styled.a<Props>`
  ${InfoCellCss}
  text-decoration: none;
  color: ${({ theme }) => theme.colors.onSurface};
  &:hover {
    box-shadow: ${() => boxShadows.light};
  }
`;
