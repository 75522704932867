import { ReactNode, useCallback, useRef, useState } from 'react';
import { Button } from 'hudskit-framework-react';
import Portal from 'core/components/Portal';
import { useAppDispatch } from 'core/hooks/use-app-dispatch';
import { useAppSelector } from 'core/hooks/use-app-selector';
import * as S from './styled';
import { MenuItemProps } from '../DrawerMenuItem/DrawerMenuItem';
import DrawerMenu from '../DrawerMenu';
import { setIsOpen } from '../reducer';

type Props = {
  children: ReactNode;
  absolute?: boolean;
  menu: MenuItemProps[];
};

export const DrawerWrapper = ({ children, portal }: { children: ReactNode; portal: boolean }) => {
  if (portal) {
    return <Portal id="drawer">{children}</Portal>;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
const Drawer = ({ children, absolute = false, menu }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.layout.isOpen);
  const contentRef = useRef<HTMLDivElement>(null);

  const [activeMenu, setActiveMenu] = useState<string | undefined>('1');
  const toggleDrawer = useCallback(
    (key: string) => {
      setActiveMenu(!isOpen ? key : undefined);
      dispatch(setIsOpen(!isOpen));
    },
    [isOpen, dispatch],
  );
  const onHideClickHandler = useCallback(() => {
    dispatch(setIsOpen(!isOpen));
  }, [isOpen, dispatch]);
  return (
    <DrawerWrapper portal={absolute}>
      <S.DrawerNav $open={isOpen} $absolute={absolute}>
        <S.DrawerDiv ref={contentRef}>
          {children}
        </S.DrawerDiv>
        {!absolute
        && (
        <S.MenuContainerAside>
          <DrawerMenu activeMenu={activeMenu} isOpen={isOpen} menu={menu} onToggleMenuClick={toggleDrawer} />
          <S.DrawerButtonDiv $open={isOpen}>
            <Button
              variant="transparent"
              icon={isOpen ? { position: 'right', type: 'action.sidebar_collapse' } : { position: 'right', type: 'action.sidebar_expand' }}
              onClick={onHideClickHandler}
            />
          </S.DrawerButtonDiv>
        </S.MenuContainerAside>
        )}
      </S.DrawerNav>
      {absolute && (
      <S.FixedMenuContainerAside $open={isOpen}>
        <DrawerMenu activeMenu={activeMenu} isOpen={isOpen} menu={menu} onToggleMenuClick={toggleDrawer} />
      </S.FixedMenuContainerAside>
      )}

    </DrawerWrapper>
  );
};

export default Drawer;
