import { RadioButton, SearchBar } from 'hudskit-framework-react';
import { AssetsSearchProps } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useCallback, ChangeEvent, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'core/hooks/use-app-selector';
import {
  clearFuzzySearchOnActiveSearch,
  setFilterByIdOnActiveSearch,
  setFuzzySearchField,
  setFuzzySearchQuery,
} from 'feature/assets/store';
import AssetSortMenu from '../AssetSortMenu';
import * as S from './styled';

const AssetSearch = () => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets]);
  const dispatch = useDispatch();
  const onSearchClearHandler = useCallback(() => {
    dispatch(clearFuzzySearchOnActiveSearch());
  }, [dispatch]);
  const searchQuery = useAppSelector((state) => state.assets.assetSearch?.query);
  const searchFields = useAppSelector((state) => state.assets.assetSearch?.fields);
  const onSearchHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // We check if we should filter by IPRID or by fuzzy text search
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      if (regex.test(event.currentTarget.value)) {
        dispatch(setFilterByIdOnActiveSearch(event.currentTarget.value));
        return;
      }
      dispatch(setFuzzySearchQuery(event.currentTarget.value.trim()));
    },
    [dispatch]
  );

  const onSearchFieldSelectedHandler = useCallback(
    (field: AssetsSearchProps) => (value: string, selected: boolean) => {
      dispatch(setFuzzySearchField({ field, selected, value }));
    },
    [dispatch]
  );

  const assetChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.AssetName), [searchFields]);
  const companyChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.Company), [searchFields]);
  const serialNumberChecked = useMemo(() => searchFields?.includes(AssetsSearchProps.SerialNumber), [searchFields]);
  return (
    <S.SearchDiv>
      <div id="searchBar">
        <SearchBar
          onChange={onSearchHandler}
          onClear={onSearchClearHandler}
          defaultValue={searchQuery}
          placeholder={t(`ASSETS:searched.placeholder.${searchFields?.[0]}`)}
        />
      </div>

      <S.ListOptionsDiv>
        <S.SearchOptionSpan>{t('ASSETS:labels.searchBy')}</S.SearchOptionSpan>
        <S.SearchOptionsDiv>
          <RadioButton
            label={t('ASSETS:fieldLabels.assetName')}
            id="assetName"
            name="searchBy"
            checked={assetChecked}
            value="assetName"
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.AssetName)}
          />
          <RadioButton
            label={t('ASSETS:fieldLabels.companyName')}
            id="companyName"
            name="searchBy"
            checked={companyChecked}
            value="companyName"
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.Company)}
          />
          <RadioButton
            label={t('ASSETS:fieldLabels.serialNumber')}
            id="serialNumber"
            name="searchBy"
            value="serialNumber"
            checked={serialNumberChecked}
            onChange={onSearchFieldSelectedHandler(AssetsSearchProps.SerialNumber)}
          />
        </S.SearchOptionsDiv>
        <S.SortOptionsDiv>
          <AssetSortMenu />
        </S.SortOptionsDiv>
      </S.ListOptionsDiv>
    </S.SearchDiv>
  );
};

export default AssetSearch;
