import { Address } from '@uptime-bff/api-contract';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { convertCountryCodeToCountryName } from 'feature/companies/components/CompaniesDetails/CompaniesDetails';

export const useAddressLine = (address: Address | undefined, addressLineStr?: string) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);

  if (address) {
    const countryName = convertCountryCodeToCountryName(address.countryCode);
    const addressParts = [
      address.street,
      address.streetNumber,
      address.postalCode,
      address.postalTown,
      countryName,
    ]
      .filter((part) => part && part !== 'unknown')
      .join(', ');

    return (addressParts || addressLineStr) ?? t('ASSETS:addressline.missingAddress');
  }

  return addressLineStr || t('ASSETS:addressline.missingAddress');
};
