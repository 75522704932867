import ErrorCard from 'core/components/ErrorCard';
import { Loader } from 'hudskit-framework-react';
import { useSearchableAssetList } from 'feature/assets/hooks/use-searchable-asset-list';
import type { AssetListItem as Asset } from '@uptime-bff/api-contract';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import useIntersectionObserver from 'core/hooks/use-intersection-observer';
import SearchNotFound from 'core/components/SearchNotFound/SearchNotFound';

import useAssetListHideScrollObserver from 'feature/assets/hooks/use-asset-list-hide-scroll-observer';
import AssetListItem from '../AssetListItem';
import * as S from './styled';

type Props = {
  onAssetItemClickHandler: (asset: Asset) => void;
  selectedAssetId?: string;
};

const AssetList = ({ onAssetItemClickHandler, selectedAssetId = undefined }: Props) => {
  const { t } = useTranslationWrapper([InternationalizationNameSpace.Assets, InternationalizationNameSpace.Core]);
  const observerTargetRef = useRef<HTMLDivElement | null>(null);
  const { assets, isError, isFetching, hasMoreAssets, getNextPage, loadItems } = useSearchableAssetList({
    pageSize: 25,
  });

  const entry = useIntersectionObserver(observerTargetRef, {});
  const isLastItemVisible = !!entry?.isIntersecting;
  const { listItemsDivRef, hideScroll } = useAssetListHideScrollObserver(assets);
  useEffect(() => {
    (async () => {
      if (isLastItemVisible && hasMoreAssets && !isFetching && !isError) {
        await getNextPage();
      }
    })();
  }, [getNextPage, hasMoreAssets, isFetching, isLastItemVisible, isError]);

  const onRetryClickHandler = useCallback(() => {
    (async () => {
      await loadItems();
    })();
  }, [loadItems]);

  // handled for corner case when scroll is not visible for items less than 10
  useEffect(() => {
    if (assets.length <= 10) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [assets.length]);

  return (
    <>
      <S.GlobalStyle $hideScroll={(hideScroll && isLastItemVisible) || assets.length === 0} />
      <S.ListItemsDiv>
        {isError && (
          <ErrorCard onRetryClick={onRetryClickHandler}>
            <>{t('ASSETS:list.errorLoadingAssets')}</>
          </ErrorCard>
        )}
        {!isLastItemVisible && isFetching && <Loader size="small" center />}
        {assets.length === 0 && <SearchNotFound />}
        {assets.length > 0 && (
          <div ref={listItemsDivRef}>
            {assets.map((asset) => (
              <AssetListItem
                key={asset.id}
                active={!!(selectedAssetId && selectedAssetId === asset.id)}
                asset={asset}
                onClick={onAssetItemClickHandler}
              />
            ))}
          </div>
        )}
        {isFetching && hasMoreAssets && <Loader size="small" center />}
        {assets.length > 0 && <div ref={observerTargetRef} />}
        {assets.length > 0 && !hasMoreAssets && !isFetching && (
          <S.ListCenteredDiv>{t('ASSETS:list.noMoreAssetsToLoad')}</S.ListCenteredDiv>
        )}
      </S.ListItemsDiv>
    </>
  );
};

export default AssetList;
