import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import * as S from '../StrictlyNeededCookiesTable/styled';

const webStorageCookieHeaders = [
  { key: 'fileName', translation: 'COOKIE_POLICY:tableTwo.tableTwoHeaders.fileName' },
  { key: 'lifespan', translation: 'COOKIE_POLICY:tableTwo.tableTwoHeaders.lifespan' },
  { key: 'category', translation: 'COOKIE_POLICY:tableOne.tableOneHeaders.category' },
  { key: 'purpose', translation: 'COOKIE_POLICY:tableOne.tableOneHeaders.purpose' },
];

const webStorageCookieData = [
  {
    fileName: 'Persist:root',
    lifespan: 'COOKIE_POLICY:tableTwo.secondTableWithData.session',
    category: 'COOKIE_POLICY:tableOne.firstTableWithData.strictlyNecessary',
    purpose: 'COOKIE_POLICY:tableTwo.secondTableWithData.persistRootPurpose'
  },
  {
    fileName: '_cltk',
    lifespan: 'COOKIE_POLICY:tableTwo.secondTableWithData.session',
    category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
    purpose: 'COOKIE_POLICY:tableTwo.secondTableWithData.cltkPurpose'
  },
  {
    fileName: 'sentryReplaySession',
    lifespan: 'COOKIE_POLICY:tableTwo.secondTableWithData.session',
    category: 'COOKIE_POLICY:tableOne.firstTableWithData.performance',
    purpose: 'COOKIE_POLICY:tableTwo.secondTableWithData.sentryReplaySessionPurpose'
  }
];

const WebStorageCookiesTable = () => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.CookiePolicy);
  return (
    <S.CookiePolicyTable data-testid="tableTwo">
      <S.CookiePolicyTableHead>
        <S.CookiePolicyTableHeadRow>
          {webStorageCookieHeaders.map(({ key, translation }) => (
            <S.CookiePolicyTableHeadCell key={key}>
              {t(translation)}
            </S.CookiePolicyTableHeadCell>
          ))}
        </S.CookiePolicyTableHeadRow>
      </S.CookiePolicyTableHead>
      <tbody>
        {webStorageCookieData.map((row) => (
          <tr key={row.fileName}>
            <S.CookiePolicyTableData>{row.fileName}</S.CookiePolicyTableData>
            <S.CookiePolicyTableData>{t(row.lifespan)}</S.CookiePolicyTableData>
            <S.CookiePolicyTableData>{t(row.category)}</S.CookiePolicyTableData>
            <S.CookiePolicyTableData>{t(row.purpose)}</S.CookiePolicyTableData>
          </tr>
        ))}
      </tbody>
    </S.CookiePolicyTable>
  );
};

export default WebStorageCookiesTable;
