import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import { darkTheme } from 'hudskit-framework-react';
import styled from 'styled-components';

export const CookiePolicySection = styled.section`
  margin: 0 auto;
  max-width: 1000px;
  padding: ${paddings.hugePadding} ${paddings.largePadding}; 

  @media ${device.md} {
    padding: ${paddings.hugePadding} ${paddings.largePadding};
  }
`;

export const ContainerSection = styled.section`
  padding-bottom: ${paddings.hugePadding};
`;

export const CookiePolicyTitleH1 = styled.h1`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.white : theme.colors.brandAlt)};
  padding-bottom: ${paddings.hugePadding};
`;

export const CookiePolicyTitleH2 = styled.h2`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.white : theme.colors.brandAlt)};
`;

export const CookieCatagorizationAnchor = styled.a`
  color: ${({ theme }) => (theme === darkTheme ? theme.colors.white : theme.colors.brandAlt)};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.brandLight};
  }
`;

export const OurCookiesSection = styled.section`
  padding-top: ${paddings.mediumPadding};`;

export const CookiePolicyTable = styled.table`
  border: solid 1px ${({ theme }) => theme.colors.gray300};
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${paddings.largePadding};
`;

export const CookiePolicyTableHead = styled.thead`
  color: ${({ theme }) => theme.colors.brandAlt};
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const CookiePolicyTableHeadRow = styled.tr`
  display: table-row;
`;

export const CookiePolicyTableHeadCell = styled.th`
  width: 200px;
  border: solid 1px ${({ theme }) => theme.colors.gray300};
  vertical-align: middle;
  padding: ${paddings.mediumSmallPadding} 0;
`;

export const CookiePolicyTableData = styled.td`
  border: solid 1px ${({ theme }) => theme.colors.gray300};
  padding: ${paddings.mediumSmallPadding} ${paddings.smallPadding};
`;
