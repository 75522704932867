import { Icon, IconTypeKey } from 'hudskit-framework-react';
import { useCallback, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import * as S from './styled';

export type ContentProps = {
  label: string;
  value: string;
  icon?: IconTypeKey;
  copyIcon?: IconTypeKey;
  serviceContract?: boolean;
};

const AssetInfoCellContent = ({
  label,
  value,
  icon = '',
  copyIcon = undefined,
  serviceContract = undefined,
}: ContentProps) => {
  const [copied, setCopied] = useState(false);
  const iconType = copied ? 'action.success' : copyIcon;

  const onCopyText = useCallback(() => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }, []);

  const assetInfo = (
    <>
      <S.IconContainerDiv $icon={!!icon} data-testid={icon}>
        <Icon type={icon} size="medium" />
      </S.IconContainerDiv>
      <S.ContentDiv $serviceContract={serviceContract}>
        <S.LabelSpan>{`${label}${serviceContract ? '' : ':'} `}</S.LabelSpan>
        <S.ValueSpan>{value}</S.ValueSpan>
      </S.ContentDiv>
      {copyIcon && (
        <CopyToClipboard text={value} onCopy={onCopyText}>
          <S.IconCopyAbleDiv data-testid={iconType} $serviceContract={serviceContract}>
            <Icon
              type={iconType}
              color={copied ? 'success500' : 'onSurface'}
              size="small"
            />
          </S.IconCopyAbleDiv>
        </CopyToClipboard>
      )}
    </>
  );

  return assetInfo;
};

export default AssetInfoCellContent;
