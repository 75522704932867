import { useMemo } from 'react';
import { Analytics, getAnalytics } from 'firebase/analytics';
import { app } from 'core/config';
import { useAppSelector } from './use-app-selector';

const useAnalytics = () => {
  const cookiesAccepted = useAppSelector((state) => state.layout.cookiesAccepted);
  const analytics = useMemo<Analytics | undefined>(() => {
    if (cookiesAccepted) {
      return getAnalytics(app);
    }
    return undefined;
  }, [cookiesAccepted]);
  return { analytics };
};
export { useAnalytics };
