import {
  DashboardCardResponse,
  SecondaryStatusRoboticMowerEnum,
  StatusCountResponse,
  WidgetAssetListResponse,
  DeleteCustomerWidgetsRequest
} from '@uptime-bff/api-contract';
import { TagTypes, uptimeApi } from 'core/api';

const dashboardApi = uptimeApi.injectEndpoints({
  endpoints: (build) => ({
    getAssetsCount: build.query<StatusCountResponse<SecondaryStatusRoboticMowerEnum>, void>({
      query: () => ({
        url: 'dashboard/status-count',
        method: 'GET',
      }),
    }),
    getCeoraCount: build.query<StatusCountResponse<SecondaryStatusRoboticMowerEnum>, void>({
      query: () => ({
        url: 'dashboard/ceora-status-count',
        method: 'GET',
      }),
    }),
    getCustomerWidgets: build.query<DashboardCardResponse, void>({
      query: () => ({
        url: 'dashboard/widgets/cards',
        method: 'GET',
      }),
      providesTags: [TagTypes.CustomerWidgets],
    }),
    getWidgetAssetsList: build.query<WidgetAssetListResponse, { companyId: string }>({
      query: ({ companyId }) => ({
        url: `dashboard/widgets/card/${companyId}`,
        method: 'GET',
      }),
      providesTags: (result, isError) => (!isError ? [TagTypes.CustomerWidgets] : []),
    }),
    deleteCustomerWidgets: build.mutation<void, DeleteCustomerWidgetsRequest>({
      query: ({ companyIds }) => ({
        url: 'users/dashboard/company-widgets/delete',
        method: 'POST',
        body: { companyIds },
      }),
      invalidatesTags: (result, isError) => (!isError ? [TagTypes.CustomerWidgets] : []),
    }),
  }),
});

export const {
  useGetAssetsCountQuery,
  useGetCeoraCountQuery,
  useGetCustomerWidgetsQuery,
  useDeleteCustomerWidgetsMutation,
  useGetWidgetAssetsListQuery,
} = dashboardApi;
