import styled, { css } from 'styled-components';
import { ErrorIcon, WarningIcon, SuccessIcon, OutlinedWarningIcon } from './Icon';

type Props = {
  height?: string;
  width?: string;
  color?: string;
};

const svgStyles = ({ height, width, color }: Props) => css`
  height: ${height ?? '24px'};
  width: ${width ?? '24px'};  
  fill: ${color ?? 'black'};
`;

export const StyledSuccessIcon = styled(SuccessIcon)<Props>`
  ${(props: Props) => svgStyles(props)};
`;

export const StyledWarningIcon = styled(WarningIcon)<Props>`
  ${(props: Props) => svgStyles(props)};
`;

export const StyledErrorIcon = styled(ErrorIcon)<Props>`
  ${(props: Props) => svgStyles(props)};
`;

export const StyledOutlinedWarningIcon = styled(OutlinedWarningIcon)<Props>`
  ${(props: Props) => svgStyles(props)};
`;
