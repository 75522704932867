import { CalculatedContractStatusEnum } from '@uptime-bff/api-contract';
import { InternationalizationNameSpace } from 'core/models/internationalization-namespace';
import { useTranslationWrapper } from 'core/hooks/use-translation-wrapper';
import * as S from './styled';

interface Props {
  status?: CalculatedContractStatusEnum
}

export const mapServiceContractStatus = (t: (key: string) => string, status?: CalculatedContractStatusEnum) => {
  switch (status) {
    case CalculatedContractStatusEnum.ACTIVE:
      return (
        <S.StatusSpan $statusColor="success">{t('ASSETS:details.serviceContract.status.active')}</S.StatusSpan>
      );
    case CalculatedContractStatusEnum.ABOUT_TO_EXPIRE_90:
    case CalculatedContractStatusEnum.ABOUT_TO_EXPIRE_60:
    case CalculatedContractStatusEnum.ABOUT_TO_EXPIRE_30:
      return (
        <S.StatusSpan $statusColor="caution">
          {t('ASSETS:details.serviceContract.status.nearExpiry')}
        </S.StatusSpan>
      );
    case CalculatedContractStatusEnum.EXPIRED:
    case CalculatedContractStatusEnum.RECENTLY_EXPIRED:
      return (
        <S.StatusSpan $statusColor="error">{t('ASSETS:details.serviceContract.status.expired')}</S.StatusSpan>
      );
    default:
      return null;
  }
};

const AssetDetailsServiceContractStatus = ({ status }: Props) => {
  const { t } = useTranslationWrapper(InternationalizationNameSpace.Assets);
  return (
    <>{mapServiceContractStatus(t, status)}</>
  );
};

export default AssetDetailsServiceContractStatus;
