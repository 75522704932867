import { paddings } from 'core/styles';
import { styled } from 'styled-components';

export const ClearButtonWrapperDiv = styled.div`
  padding-bottom: ${paddings.mediumSmallPadding};
  
  > button {
    width: 100%;
  }
`;

export const FiltersDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${paddings.largePadding};
  padding-bottom: ${paddings.largePadding};
`;

export const CustomersInFocusFilterWrapperDiv = styled.div<{ $isLoading: boolean }>`
  padding-top: ${({ $isLoading }) => $isLoading && `${paddings.defaultPadding}`};
`;
