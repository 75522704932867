import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import styled from 'styled-components';

export const SearchDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${() => paddings.smallPadding};
`;
export const SearchOptionSpan = styled.span`
  display: none;
  @media ${() => device.xl}{
    display: flex;
  }
`;

export const SearchOptionsDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${() => paddings.largePadding};;
  label > span {
    padding: 0;
  }
`;

export const ListOptionsDiv = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 40px;
  padding-top: ${() => paddings.smallPadding};

  @media ${() => device.lg}{
    grid-template-columns: 1fr 140px;
  }
  @media ${() => device.xl}{
    grid-template-columns: 70px 1fr 140px;
  }
`;

export const SortOptionsDiv = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
`;
