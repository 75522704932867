import { paddings } from 'core/styles';
import { device } from 'core/styles/variables';
import styled from 'styled-components';

export const CookiePolicyTable = styled.table`
  border: solid 1px ${({ theme }) => theme.colors.gray300};
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${paddings.largePadding};
`;

export const CookiePolicyTableHead = styled.thead`
  color: ${({ theme }) => theme.colors.brandAlt};
  background-color: ${({ theme }) => theme.colors.gray200};
`;

export const CookiePolicyTableHeadRow = styled.tr`
  display: table-row;
`;

export const CookiePolicyTableHeadCell = styled.th`
  width: 200px;
  border: solid 1px ${({ theme }) => theme.colors.gray300};
  vertical-align: middle;
  padding: ${paddings.mediumSmallPadding} ${paddings.smallPadding};
`;

export const CookiePolicyTableData = styled.td`
  border: solid 1px ${({ theme }) => theme.colors.gray300};

  @media ${device.md} {
    padding: ${paddings.mediumSmallPadding} ${paddings.smallPadding};
  }
  
`;
